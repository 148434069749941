import { createTheme } from '@mui/material/styles';
import { dialogStyles } from './componentStyling/dialogStyles.js';
import {
  h2Styles,
  primaryColor,
  qualitatioBlack,
  qualitatioGray,
  qualitatioWhite,
} from './styleConstants.js';

//notes: calc font sizes based on min and + vw or vh

const muiTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#0B2034',
    },
    primaryShiny: {
      main: '#6ff7f5',
    },
    background: {
      default: qualitatioWhite,
    },
    error: {
      main: '#BA1A1A',
      secondary: '#FFDAD6',
    },
    success: {
      main: '#1FBEBC',
      secondary: '#cce8e7',
    },
    warning: {
      main: '#FFF5D6',
      contrastText: '#112A46',
      secondary: '#ffe28a',
    },
    info: {
      main: '#1FBEBC',
      secondary: '#cce8e7',
    },
    gray: {
      main: qualitatioGray,
    },
    white: {
      main: qualitatioWhite,
    },
    nightsky: {
      main: '#0b2034',
    },
    gold: {
      main: '#FFD700',
    },
    silver: {
      main: '#C0C0C0',
    },
    bronze: {
      main: '#CD7F32',
    },
    // ... You can add more colors or configurations here.
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontFamily: 'Roboto-Bold',
      fontSize: 'calc(24px + 3vw)',
      fontWeight: 400,
      lineHeight: '1.2',
      color: 'gray.700',
    },
    h2: {
      fontFamily: 'Roboto-Regular',
      fontSize: 'calc(18px + 0.9vw)',
      fontWeight: 400,
      color: qualitatioBlack,
    },
    h3: {
      fontFamily: 'Roboto-Bold',
      fontSize: 'calc(12px + 0.75vw)',
      fontWeight: 400,
      color: qualitatioBlack,
    },
    h4: {
      fontFamily: 'Roboto-Bold',
      fontSize: 'calc(10px + 0.5vw)',
      fontWeight: 400,
      color: qualitatioBlack,
    },
    body1: {
      fontFamily: 'Roboto-Regular',
      fontSize: 'calc(10px + 0.5vw)',
    },
    body2: {
      fontFamily: 'Roboto-Regular',
      fontSize: 'calc(8px + 0.5vw)',
    },
    subtitle1: {
      fontFamily: 'Roboto-Regular',
      fontSize: 'calc(6px + 0.5vw)',
    },
    subtitle2: {
      fontFamily: 'Roboto-Regular',
      fontSize: 'clamp(10px, 2px + 0.5vw, 12px)',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          ...h2Styles,
          fontSize: 'calc(24px + 3vw)',
          fontWeight: 400,
          lineHeight: '1.2',
          color: 'gray.700',
        },
        h2: {
          ...h2Styles,
          fontSize: 'calc(18px + 1vw)',
          fontWeight: 400,
          color: qualitatioBlack,
        },
        h3: {
          ...h2Styles,
          fontSize: 'calc(12px + 0.75vw)',
          fontWeight: 400,
          color: qualitatioBlack,
        },
        h4: {
          ...h2Styles,
          fontSize: 'calc(10px + 0.5vw)',
          fontWeight: 400,
          color: qualitatioBlack,
        },
        body1: {
          fontFamily: 'Roboto-Regular',
          fontSize: 'calc(10px + 0.5vw)',
        },
        body2: {
          fontFamily: 'Roboto-Regular',
          fontSize: 'calc(5px + 0.5vw)',
        },
        subtitle1: {
          fontFamily: 'Roboto-Regular',
          fontSize: 'calc(6px + 0.5vw)',
        },
        subtitle2: {
          fontFamily: 'Roboto-Regular',
          fontSize: 'clamp(10px, 2px + 0.5vw, 12px)',
        },
      },
    },
    MuiSwitch: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            width: 39,
            height: 24,
            padding: 0,
            display: 'flex',
            '&:active': {
              '& .MuiSwitch-thumb': {
                width: 23,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(13px)',
              },
            },
            '& .MuiSwitch-switchBase': {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(15px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                },
              },
              '&.Mui-disabled': {
                '& + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
              width: 19,
              height: 19,
              borderRadius: 9.5,
              transition: 'width 200ms',
            },
            '& .MuiSwitch-track': {
              borderRadius: 12,
              opacity: 1,
              backgroundColor: qualitatioGray,
              boxSizing: 'border-box',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'qualitatio', color: 'primary' },
          style: {
            textTransform: 'none',
            height: '46px',
            transition: 'all .3s',
            backgroundColor: primaryColor,
            color: qualitatioWhite,
            borderColor: primaryColor,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: primaryColor,
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
              transform: 'scale(1.03)',
            },
            // Adjusting position for icons.
            '& .MuiButton-startIcon': {
              position: 'absolute',
              left: '7%',
            },
            '& .MuiButton-endIcon': {
              position: 'absolute',
              right: '7%',
            },
            // Animation
            '@keyframes pulsate': {
              '0%': { transform: 'scale(1)' },
              '25%': { transform: 'scale(1.05)' },
              '50%': { transform: 'scale(1)' },
              '75%': { transform: 'scale(1.05)' },
              '100%': { transform: 'scale(1)' },
            },
            '&.animate': {
              animation: 'pulsate 2s',
              transition: 'all .5s',
            },
            // Icon adjustments
            '& img': {
              width: '30px',
              height: '30px',
              objectFit: 'cover',
              '&.start-icon-rounded': {
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              },
            },
            // Other style configurations
            minWidth: 'auto',
            fontSize: '1rem',
            '@media (max-width: 600px)': {
              width: '100%',
              minWidth: '100%',
              fontSize: '1rem',
            },
          },
        },
        {
          props: { variant: 'qualitatio', color: 'secondary' },
          style: {
            textTransform: 'none',
            height: '46px',
            border: '1px solid',
            borderColor: primaryColor, // Your secondary color variable
            borderRadius: '8px',
            color: primaryColor, // Use secondary color for text
            transition: 'all .3s',
            '&:hover': {
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
              backgroundColor: 'rgba(31, 190, 189, 0.1)',
              transform: 'scale(1.03)',
            },
            '& .MuiButton-startIcon': {
              position: 'absolute',
              left: '7%',
            },
            '& .MuiButton-endIcon': {
              position: 'absolute',
              right: '7%',
            },
            '@keyframes pulsate': {
              '0%': { transform: 'scale(1)' },
              '25%': { transform: 'scale(1.05)' },
              '50%': { transform: 'scale(1)' },
              '75%': { transform: 'scale(1.05)' },
              '100%': { transform: 'scale(1)' },
            },
            '&.animate': {
              animation: 'pulsate 2s',
              transition: 'all .5s',
            },
            '& img': {
              width: '30px',
              height: '30px',
              objectFit: 'cover',
              '&.start-icon-rounded': {
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              },
            },
            minWidth: 'auto',
            fontSize: '1rem',
            '@media (max-width: 480px)': {
              width: '100%',
              minWidth: '100%',
              fontSize: '1rem',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            color: qualitatioWhite,
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            transition: 'all .3s',
            height: '46px',
            width: '46px',
            '&:hover': {
              backgroundColor: primaryColor,
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
              transform: 'scale(1.03)',
            },
            '@keyframes pulsate': {
              '0%': { transform: 'scale(1)' },
              '25%': { transform: 'scale(1.05)' },
              '50%': { transform: 'scale(1)' },
              '75%': { transform: 'scale(1.05)' },
              '100%': { transform: 'scale(1)' },
            },
            '&.animate': {
              animation: 'pulsate 2s',
              transition: 'all .5s',
            },
            '& img': {
              width: '30px',
              height: '30px',
              objectFit: 'cover',
              '&.start-icon-rounded': {
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              },
            },
          },
        },
        {
          props: { variant: 'qualitatio', squared: true },
          style: {
            width: '30px',
            height: '30px',
            borderRadius: '20%',
            boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.2s ease-in-out',
            '&.Mui-disabled': {
              // corresponds to .qualitatio-icon-button.disabled
              boxShadow: 'none',
              opacity: 0.5,
              cursor: 'not-allowed',
            },
            '&:hover': {
              // corresponds to .qualitatio-icon-button:hover
              boxShadow:
                '0px 1px 5px 1px rgba(0, 0, 0, 0.32), 0px 4px 10px 3px rgba(0, 0, 0, 0.17)',
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '100px',
          '& .MuiInputBase-input::placeholder': {
            // Placeholder text color
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1, // For Firefox compatibility
          },
          '& .MuiInputBase-input:focus::placeholder': {
            // Placeholder text color when focused
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '& .MuiInputLabel-outlined.Mui-focused': {
            // Label (when outlined and focused)
            color: primaryColor,
            fontWeight: 'bold', // Making text bold
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: primaryColor,
            },
            '&:hover fieldset': {
              borderColor: primaryColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: primaryColor,
            },
          },
          '& .MuiInputBase-input': {
            color: qualitatioBlack,
          },
          '@media (max-width: 767px)': {
            // Or whichever value is suitable to switch to mobile view
            width: '100%', // Use full width on screens smaller than 'md' (768px)
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxHeight: '80vh',
          overflowY: 'auto',
          '@media (max-width: 600px)': {
            // when the viewport width is 600px or less
            maxHeight: '100vh',
            overflowY: 'auto',
          },
          padding: '1%',
        },
      },
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            ...dialogStyles,
            '& .MuiDialogActions-root': {
              flexDirection: 'column',
              '& .MuiButton-root': {
                width: '100%', // Set button width to 100%
                marginTop: '8px', // Optional: Add some vertical spacing between buttons
                marginBottom: '8px', // Optional: Add some vertical spacing between buttons
                marginLeft: '0px',
              },
            },
          },
        },
        {
          props: { variant: 'qualitatio_dialog' },
          style: {
            ...dialogStyles,
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
            backgroundColor: qualitatioWhite,
            padding: '1%',
            '& .MuiCardHeader-root': {
              padding: '0',
            },
            '& .MuiCardContent-root': {
              padding: '0',
            },
          },
        },
        {
          props: { variant: 'configurator' },
          style: {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
            backgroundColor: qualitatioWhite,
            padding: '2% 5%',
            margin: '1%',
            height: '73vh',
            overflowY: 'auto',
            '& .MuiCardContent-root': {
              padding: '0',
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            width: '100%',
            minWidth: '100%',
            '.MuiInputBase-input': {
              fontSize: '1rem',
            },
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // or any desired font size
        },
      },
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            '& .MuiButtonBase-root.MuiCheckbox-root': {
              color: primaryColor,
            },
            '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked, .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate':
              {
                color: primaryColor,
              },
            '& .MuiDataGrid-sortIcon, .MuiDataGrid-filterIcon': {
              color: primaryColor,
            },
            '& .MuiButtonBase-root.MuiButton-root': {
              width: '10%',
              color: primaryColor,
            },
            '& .MuiInputBase-root.MuiInput-root': {
              color: primaryColor,
            },
            '& .MuiDataGrid-toolbarContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'rgba(31, 190, 188, 0.2)',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'rgba(31, 190, 188, 0.4)',
            },

            // Row hover styles
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(31, 190, 188, 0.1)',
            },

            // Cell focus styles
            '& .MuiDataGrid-cell:focus': {
              outline: `solid ${primaryColor} 1px`,
            },
          },
        },
      ],
    },
    MuiDataGridPro: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // or any desired font size
        },
      },
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            '& .MuiButtonBase-root.MuiCheckbox-root': {
              color: primaryColor,
            },
            '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked, .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate':
              {
                color: primaryColor,
              },
            '& .MuiDataGrid-sortIcon, .MuiDataGrid-filterIcon': {
              color: primaryColor,
            },
            '& .MuiButtonBase-root.MuiButton-root': {
              width: '10%',
              color: primaryColor,
            },
            '& .MuiInputBase-root.MuiInput-root': {
              color: primaryColor,
            },
            '& .MuiDataGrid-toolbarContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'rgba(31, 190, 188, 0.2)',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: 'rgba(31, 190, 188, 0.4)',
            },

            // Row hover styles
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(31, 190, 188, 0.1)',
            },

            // Cell focus styles
            '& .MuiDataGrid-cell:focus': {
              outline: `solid ${primaryColor} 1px`,
            },
          },
        },
      ],
    },
    MuiAccordion: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            /*             '& .MuiAccordion-root': {
                        },
                        '& .MuiAccordionSummary-root': {
                          '& .MuiAccordionSummary-content': {
                          },
                        },
                        '& .MuiAccordionDetails-root': {
                          '& .MuiAccordionDetails-root': {
                          },
                        }, */
          },
        },
      ],
    },
    MuiCheckbox: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            color: primaryColor,
            '&.Mui-checked': {
              color: primaryColor,
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
            '&.MuiCheckbox-indeterminate': {
              color: primaryColor,
            },
            '&:hover': {
              '&.Mui-checked': {
                backgroundColor: 'rgba(31, 190, 188, 0.1)',
              },
            },
          },
        },
      ],
    },
    MuiRating: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            '& .MuiRating-iconEmpty': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
            '& .MuiRating-iconFilled': {
              color: primaryColor,
            },
            '& .MuiRating-iconHover': {
              color: primaryColor,
            },
          },
        },
      ],
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'secondary' }, // Custom variant name
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              // Target the badge element
              backgroundColor: theme.palette.secondary.main, // Use the primary color from the theme
              color: primaryColor,
            },
          }),
        },
        {
          props: { variant: 'error' }, // Custom variant name
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              // Target the badge element
              backgroundColor: theme.palette.error.main, // Use the error color from the theme
              color: 'white',
            },
          }),
        },
        {
          props: { variant: 'success' }, // Another custom variant
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor: theme.palette.success.main, // Use the success color from the theme
              color: 'white', // Use the contrast text color from the theme
            },
          }),
        },
        {
          props: { variant: 'warning' }, // Another custom variant
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor: theme.palette.warning.secondary, // Use the warning color from the theme
              color: theme.palette.warning.contrastText, // Use the contrast text color from the theme
            },
          }),
        },
        {
          props: { variant: 'gray' }, // Another custom variant
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor: theme.palette.gray.main, // Use the info color from the theme
              color: 'white', // Use the contrast text color from the theme
            },
          }),
        },
        // You can add more custom variants here
      ],
    },
    MuiToggleButton: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            color: primaryColor,
            borderColor: primaryColor, // Ensure borders also match the primary color
            '&.Mui-selected': {
              color: primaryColor,
              backgroundColor: 'rgba(31, 190, 188, 0.2)', // matching the hover style
              '&:hover': {
                backgroundColor: 'rgba(31, 190, 188, 0.3)', // dimmed primary color on hover when selected
              },
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)',
              borderColor: 'rgba(0, 0, 0, 0.38)', // disabled border color
            },
            '&:hover': {
              backgroundColor: 'rgba(31, 190, 188, 0.1)',
            },
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { variant: 'qualitatio' },
          style: {
            borderColor: primaryColor, // Matching border color for the group
            '& .MuiToggleButtonGroup-grouped': {
              borderColor: primaryColor, // Matching border color for grouped buttons
              '&:not(:last-of-type)': {
                borderColor: primaryColor,
              },
              '&:not(:first-of-type)': {
                borderColor: primaryColor,
              },
            },
          },
        },
      ],
    },
    //... Extend styles for other components as needed.
  },
  breakpoints: {
    values: {
      xs: 0, // Phones, portrait
      sm: 600, // Phones, landscape
      md: 768, // Tablets, portrait  (Custom breakpoint at 768px)
      lg: 1024, // Tablets, landscape and laptops
      xl: 1200, // Large devices, wide screens
    },
  },
});

export default muiTheme;
