import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

import axiosClient from '../../../../api/axiosClient';
import { liveStreamDataEndpoint } from '../../../../api/endpoints';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDropdown from '../../../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from '../../../../components/QualitatioInput/QualitatioInput';

const EditRule = ({
  rule,
  index,
  rules,
  setRules,
  ruleTests,
  setRuleTests,
  currentIndex,
  ruleEditOpen,
  setRuleEditOpen,
  handleSave,
  handleEditRule,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [fields, setFields] = useState([]);
  // Cache for fieldTwoValues. e.g. { 'streamName > fieldName': [value1, value2, ...] }
  const [fieldTwoValuesMappingCache, setFieldTwoValuesMappingCache] = useState({});
  const previousFieldOnesRef = useRef([]);

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      const newFields = response.data.liveStreamData.map((el) => {
        el.fieldNames = el.fieldNames.map((fieldName) => {
          return {
            name: fieldName,
            type: el.fieldTypes[el.fieldNames.indexOf(fieldName)],
          };
        });
        return el;
      });
      setFields(newFields);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getLiveStreamFieldValues = async (fieldOneString) => {
    try {
      const [streamName, field] = fieldOneString.split(' > ');
      if (!field || !streamName) {
        return;
      }
      const response = await axiosClient.get(
        liveStreamDataEndpoint + `/${streamName}/fieldValues/${field}`
      );
      setFieldTwoValuesMappingCache((prevState) => ({
        ...prevState,
        [fieldOneString]: response.data.fieldValues,
      }));
    } catch (error) {
      console.error('Error fetching fieldOneValues:', error);
    }
  };

  useEffect(() => {
    getLiveStreamData();
  }, []);

  // ---- Edit Rule form options ----
  const logicalOperatorOptions = [
    { value: 'AND', label: t('AND') },
    { value: 'OR', label: t('OR') },
  ];

  const field1Options = useMemo(() => {
    return fields.flatMap(
      (stream) =>
        stream.fieldNames?.map((field) => ({
          label: field.name,
          value: `${stream.streamName} > ${field.name}`,
          group: stream.streamName,
        })) || []
    );
  }, [fields]);

  const field1Values = useMemo(() => {
    const logic = rules[currentIndex]?.logic || [];
    return logic.map((item) => {
      const fieldOneString = `${item.fieldOneStreamName} > ${item.fieldOneFieldName}`;
      const foundOption = field1Options.find((opt) => opt.value === fieldOneString);
      return foundOption || { value: '', label: '' };
    });
  }, [rules, currentIndex, field1Options]);

  const operatorOptions = useMemo(() => {
    return (
      fields
        .flatMap((stream) => stream.fieldNames)
        .reduce((acc, field) => {
          const type = field.type;

          let options;
          if (type === 'number') {
            options = [
              { label: '<', value: '<' },
              { label: '>', value: '>' },
              { label: '=', value: '=' },
              { label: '!=', value: '!=' },
            ];
          } else {
            options = [
              { value: 'startsWith', label: t('startsWith') },
              { value: 'equals', label: t('equals') },
              { value: 'endsWith', label: t('endsWith') },
              { value: 'includes', label: t('includes') },
              // { label: 'matches (Regex)', value: 'matches' }
            ];
          }

          acc[field.name] = options;
          return acc;
        }, {}) || []
    );
  }, [fields, ruleTests, rules]);

  const optionsValue = useMemo(() => {
    const logic = rules[currentIndex]?.logic || [];
    return logic.map((item) => {
      const foundOption = operatorOptions[item.fieldOneFieldName]?.find(
        (opt) => opt.value === item.operator
      );
      return foundOption || { value: '', label: '' };
    });
  }, [rules, currentIndex, field1Options, ruleTests]);

  useEffect(() => {
    const previousFieldOnes = previousFieldOnesRef.current;
    // Get the new selected field ones
    const newFieldOnes = field1Values.filter(
      (field) => !previousFieldOnes.some((prevField) => prevField.value === field.value)
    );
    // Fetch field values data only for newly added fields
    if (newFieldOnes.length > 0) {
      try {
        for (let field of newFieldOnes) {
          getLiveStreamFieldValues(field.value);
        }
      } catch (error) {
        console.error('Error fetching fieldOneValues:', error);
      }
    }
    // Update the ref with the current selectedFieldOnes
    previousFieldOnesRef.current = field1Values;
  }, [field1Values]);

  // ---- Edit Rule form setters ----
  const setOperator = (newOperator, id) => {
    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[currentIndex])
          ? {
              ...card,
              rule: {
                ...card.rule,
                logic: card.rule.logic.map((item, logicIndex) =>
                  logicIndex === id ? { ...item, operator: newOperator } : item
                ),
              },
            }
          : card
      )
    );

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[currentIndex])
          ? {
              ...rule,
              logic: rule.logic.map((item, logicIndex) =>
                logicIndex === id ? { ...item, operator: newOperator } : item
              ),
            }
          : rule
      )
    );
  };

  const setFieldOne = (newFieldOneString, id) => {
    let [streamName, fieldName] = newFieldOneString.split(' > ');
    if (!fieldName) {
      fieldName = '';
    }
    const dataType = fields
      .find((stream) => stream.streamName === streamName)
      ?.fieldNames.find((field) => field.name === fieldName)?.type;

    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[currentIndex])
          ? {
              ...card,
              rule: {
                ...card.rule,
                logic: card.rule.logic.map((item, logicIndex) =>
                  logicIndex === id
                    ? {
                        ...item,
                        fieldOneFieldName: fieldName,
                        fieldOneStreamName: streamName,
                        fieldOneDataType: dataType,
                        operator: dataType === item.fieldOneDataType ? item.operator : '',
                        fieldTwoValue: fieldName ? item.fieldTwoValue : '',
                      }
                    : item
                ),
              },
            }
          : card
      )
    );

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[currentIndex])
          ? {
              ...rule,
              logic: rule.logic.map((item, logicIndex) =>
                logicIndex === id
                  ? {
                      ...item,
                      fieldOneFieldName: fieldName,
                      fieldOneStreamName: streamName,
                      fieldOneDataType: dataType,
                      operator: dataType === item.fieldOneDataType ? item.operator : '',
                      fieldTwoValue: fieldName ? item.fieldTwoValue : '',
                    }
                  : item
              ),
            }
          : rule
      )
    );
  };

  const setFieldTwoValue = (newFieldTwoValue, id) => {
    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[currentIndex])
          ? {
              ...card,
              rule: {
                ...card.rule,
                logic: card.rule.logic.map((item, logicIndex) =>
                  logicIndex === id ? { ...item, fieldTwoValue: newFieldTwoValue } : item
                ),
              },
            }
          : card
      )
    );

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[currentIndex])
          ? {
              ...rule,
              logic: rule.logic.map((item, logicIndex) =>
                logicIndex === id ? { ...item, fieldTwoValue: newFieldTwoValue } : item
              ),
            }
          : rule
      )
    );
  };

  const setConnector = (newConnector, id) => {
    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[currentIndex])
          ? {
              ...card,
              rule: {
                ...card.rule,
                logic: card.rule.logic.map((item, logicIndex) =>
                  logicIndex === id
                    ? { ...item, connector: newConnector === '' ? 'NONE' : newConnector }
                    : item
                ),
              },
            }
          : card
      )
    );

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[currentIndex])
          ? {
              ...rule,
              logic: rule.logic.map((item, logicIndex) =>
                logicIndex === id
                  ? { ...item, connector: newConnector === '' ? 'NONE' : newConnector }
                  : item
              ),
            }
          : rule
      )
    );
  };

  const setRuleName = (ruleIndex, newRuleName) => {
    setRules((prevState) =>
      prevState.map((rule, index) =>
        index === ruleIndex ? { ...rule, ruleName: newRuleName } : rule
      )
    );

    setRuleTests((prevState) =>
      prevState.map((card) =>
        card.rule && _.isEqual(card.rule, rules[currentIndex])
          ? {
              ...card,
              rule: { ...card.rule, ruleName: newRuleName },
              explanation: newRuleName,
            }
          : card
      )
    );
  };

  const addLogic = (ruleIndex) => {
    const newLogicObject = {
      orderNumber: rules[ruleIndex].logic.length + 1 || 1,
      fieldOneStreamName: '',
      fieldOneFieldName: '',
      fieldOneDataType: '',
      operator: '',
      fieldTwoValue: '',
      connector: 'NONE',
    };

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[ruleIndex])
          ? { ...rule, logic: [...rule.logic, newLogicObject] }
          : rule
      )
    );

    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[ruleIndex])
          ? {
              ...card,
              rule: {
                ...card.rule,
                logic: [...card.rule.logic, newLogicObject],
              },
            }
          : card
      )
    );
  };

  const deleteLogic = (ruleIndex, logicDeletionIndex) => {
    const updatedLogic = rules
      .find((rule) => _.isEqual(rule, rules[ruleIndex]))
      .logic.filter((_, index) => index !== logicDeletionIndex);

    setRules((prevState) =>
      prevState.map((rule) =>
        _.isEqual(rule, rules[ruleIndex]) ? { ...rule, logic: updatedLogic } : rule
      )
    );

    setRuleTests((prevState) =>
      prevState.map((card) =>
        _.isEqual(card.rule, rules[ruleIndex])
          ? { ...card, rule: { ...card.rule, logic: updatedLogic } }
          : card
      )
    );
  };

  const closeRuleEdit = (index) => {
    setRuleEditOpen((prev) => {
      prev[index] = false;
      return [...prev];
    });
  };

  return (
    <>
      <IconButton
        variant="qualitatio"
        squared={true}
        onClick={() => {
          handleEditRule(index);
        }}
        style={{
          backgroundColor: theme.palette.success.secondary,
          width: '36px',
          height: '36px',
        }}
      >
        <EditRoundedIcon color="primary" />
      </IconButton>
      <QualitatioDialog
        title={t('edit') + ' ' + rule.ruleName}
        open={ruleEditOpen[index]}
        onClose={() => closeRuleEdit(index)}
        actions={[
          {
            label: t('save'),
            onClick: () => {
              handleSave();
              closeRuleEdit(index);
            },
            disabled:
              rule?.logic.some((el) => el.fieldOneFieldName?.length === 0) ||
              rule?.logic.some((el) => el.operator?.length === 0) ||
              rule?.logic.some((el) => el.fieldTwoValue?.length === 0) ||
              rule?.logic.filter((el) => el.connector === 'NONE')?.length > 1,
            order: 'primary',
          },
          {
            label: t('cancel'),
            onClick: () => {
              closeRuleEdit(index);
            },
            order: 'secondary',
          },
        ]}
      >
        <div className="rule-name-change-wrapper">
          <QualitatioInput
            label={t('ruleName')}
            value={rule?.ruleName}
            type="text"
            compact={true}
            onChange={(e) => {
              setRuleName(index, e.target.value);
            }}
          />
        </div>
        <div className="rule-logic-wrapper">
          {rule?.logic.map((e, i) => (
            <div key={i} className="logic-wrapper">
              {i === 0 && <div className="if-text">{t('if')}</div>}
              <QualitatioDropdown
                options={field1Options || []}
                groupBy={(option) => option.group}
                label={t('field')}
                value={field1Values[i]}
                onChange={(event, option) =>
                  option ? setFieldOne(option.value, i) : setFieldOne('', i)
                }
                placeholder={t('field')}
              />
              {e.fieldOneFieldName && (
                <QualitatioDropdown
                  options={operatorOptions[e.fieldOneFieldName] || []}
                  label={t('operator')}
                  value={optionsValue[i]}
                  onChange={(event, option) => option && setOperator(option.value, i)}
                  placeholder={t('operator')}
                />
              )}
              {e.fieldOneFieldName && (
                <QualitatioDropdown
                  options={
                    fieldTwoValuesMappingCache[
                      e.fieldOneStreamName + ' > ' + e.fieldOneFieldName
                    ] || []
                  }
                  value={e.fieldTwoValue || ''}
                  getOptionLabel={(option) => (option ? option : '')}
                  label={t('value')}
                  onChange={(event, option) => {
                    setFieldTwoValue(option, i);
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue !== t('selectOrTypeValue')) {
                      setFieldTwoValue(newInputValue, i);
                    }
                  }}
                  placeholder={t('selectOrTypeValue')}
                  freeSolo // Allows parts of the text input to be any value, not just from the dropdown options
                />
              )}
              {e.fieldOneFieldName && i !== rule?.logic.length - 1 && (
                <QualitatioDropdown
                  key={i}
                  options={logicalOperatorOptions}
                  label={t('logicalOperator')}
                  className="logical-operator"
                  value={logicalOperatorOptions.find((opt) => opt.value === e.connector)}
                  onChange={(event, option) => setConnector(option ? option.value : '', i)}
                  placeholder={t('logicalOperator')}
                />
              )}
              <IconButton
                className="delete-button"
                variant="qualitatio"
                squared={true}
                disabled={rule?.logic.length === 1}
                onClick={() => {
                  deleteLogic(index, i);
                }}
                style={{
                  backgroundColor: theme.palette.error.main,
                  width: '36px',
                  height: '36px',
                }}
              >
                <DeleteForeverRoundedIcon color="white" />
              </IconButton>
              <div>
                {i === rule?.logic.length - 1 && (
                  <IconButton
                    variant="qualitatio"
                    squared={true}
                    onClick={() => addLogic(index)}
                    style={{
                      backgroundColor: theme.palette.success.secondary,
                      width: '36px',
                      height: '36px',
                    }}
                  >
                    <AddRoundedIcon color="primary" />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
          {rule?.logic.length === 0 && (
            <IconButton
              variant="qualitatio"
              squared={true}
              onClick={() => addLogic(index)}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <AddRoundedIcon color="primary" />
            </IconButton>
          )}
        </div>
      </QualitatioDialog>
    </>
  );
};

EditRule.propTypes = {
  rule: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  rules: PropTypes.array.isRequired,
  setRules: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  ruleEditOpen: PropTypes.array.isRequired,
  setRuleEditOpen: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleEditRule: PropTypes.func.isRequired,
};
export default EditRule;
