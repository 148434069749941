import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { PresentationControls, Stage } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import axiosClient from '../../api/axiosClient';
import {
  getAllCardNamesEndpoint,
  getAllEventsFromSplunkEndpoint,
  liveStreamDataEndpoint,
  productModel3DEndpoint,
  qualityStationEndpoint,
  restoreChangeEndpoint,
  saveQualityStationImageEndpoint,
  selectedLiveStreamDataEndpoint,
  staticCardEndpoint,
} from '../../api/endpoints';
import { QualitatioDropzone } from '../../components';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDropdown from '../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioEmptyDialog from '../../components/QualitatioEmptyDialog/QualitatioEmptyDialog';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioMobileStepper from '../../components/QualitatioMobileStepper/QualitatioMobileStepper';
import QualitatioProTable from '../../components/QualitatioProTable/QualitatioProTable';
import QualitatioProgressBar from '../../components/QualitatioProgressBar/QualitatioProgressBar';
import { useAuthStore } from '../../store/auth.store';
import './ProductionConfigurator.css';
import AITests from './testOptions/AITests';
import BasisTests from './testOptions/BasisTests';
import Copilot from './testOptions/Copilot';
import OverviewTests from './testOptions/OverviewTests';
import RuleTests from './testOptions/RuleTests';
import LiveStreamDataSelectionModal from './testOptions/components/LiveStreamDataSelectionModal';
import EventTests from './testOptions/eventTests/EventTests';
import {
  isEqualWithoutMetaFields,
  loadAllEventsFromSplunk,
  loadDefectMapping,
  loadQualityStationAICards,
  loadQualityStationDynamicCards,
  loadQualityStationRulesCards,
  loadQualityStationStaticCards,
} from './utils';

const ModelComponent = ({ modelFileURL, ...props }) => {
  const model = useLoader(GLTFLoader, modelFileURL);
  return <primitive object={model.scene} {...props} />;
};

export default function ProductionConfigurator({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('productionConfigurator'));
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const [selectedQualityStation, setSelectedQualityStation] = useState();
  const [selectedQualityStationImage, setSelectedQualityStationImage] = useState();
  const [selectedTestLogicIndex, setSelectedTestLogicIndex] = useState(-1);

  // Always have a copy to overwrite the server state
  const [staticTests, setStaticTests] = useState([]);
  const [staticTestsCopy, setStaticTestsCopy] = useState([]);
  const [ruleTests, setRuleTests] = useState([]);
  const [ruleTestsCopy, setRuleTestsCopy] = useState([]);
  const [eventTests, setEventTests] = useState([]);
  const [eventTestsCopy, setEventTestsCopy] = useState([]);
  const [aiTests, setAITests] = useState([]);
  const [aiTestsCopy, setAITestsCopy] = useState([]);

  const [defectMapping, setDefectMapping] = useState([]);

  const [qualityStationStats, setQualityStationStats] = useState([]);
  const [events, setEvents] = useState([]);
  // const [orderIdentifierMapping, setOrderIdentifierMapping] = useState({});

  const [addNewQualityStationOpen, setAddNewQualityStationOpen] = useState(false);
  const [newQualityStation, setNewQualityStation] = useState({ predecessor: -1 });

  const [editQualityStationOpen, setEditQualityStationOpen] = useState(false);
  const [editQualityStation, setEditQualityStation] = useState({});

  const [startTime, setStartTime] = useState(moment().subtract(2, 'week').format().split('+')[0]);
  const [endTime, setEndTime] = useState(moment().format().split('+')[0]);

  const testLogic = [
    t('staticTestProposal'),
    t('ruleBasedTestProposal'),
    t('eventBasedTestProposal'),
    t('aiBasedTestProposal'),
    t('copilotTestProposal'),
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [infoOpen, setInfoOpen] = useState([]);
  const nextDisabled =
    (activeStep === 1 && !selectedQualityStation) ||
    (activeStep === 2 && selectedTestLogicIndex === -1) ||
    activeStep === 4;
  const [discardWarningOpen, setDiscardWarningOpen] = useState(false);
  const [discardWarningNextOrBack, setDiscardWarningNextOrBack] = useState('');

  const [threeDProductModels, setThreeDProductModels] = useState([]);
  const [currentModelIndex, setCurrentModelIndex] = useState(0);
  const currentModelFileURL = threeDProductModels[currentModelIndex]?.modelFileURL;

  const get3DProductModels = async () => {
    try {
      const response = await axiosClient.get(productModel3DEndpoint);
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    get3DProductModels();
  }, []);

  const handlePrev = () => {
    setCurrentModelIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : threeDProductModels.length - 1
    );
  };

  const handleNext = () => {
    setCurrentModelIndex((prevIndex) =>
      prevIndex < threeDProductModels.length - 1 ? prevIndex + 1 : 0
    );
  };

  const orderStations = (stations) => {
    // Create a mapping from predecessor ID to station for quick lookup
    const predecessorMap = new Map(stations.map((s) => [s.predecessor, s]));

    // Start with the station that has no predecessor (-1 or similar marker)
    let currentStation = predecessorMap.get('-1'); // Assuming -1 is used for no predecessor
    const sortedStations = [];

    while (currentStation) {
      sortedStations.push(currentStation);
      // Move to the next station whose predecessor is the current station's ID
      currentStation = predecessorMap.get(currentStation.id.toString());
    }

    return sortedStations;
  };

  const orderedQualityStationStats = useMemo(() => {
    console.log('Ordering stations:', JSON.stringify(qualityStationStats));
    return orderStations(qualityStationStats);
  }, [qualityStationStats]);

  const getStaticTests = async (qualityStation) => {
    const staticTests = await loadQualityStationStaticCards(
      qualityStation || selectedQualityStation
    );
    setStaticTests(staticTests);
    setStaticTestsCopy(JSON.parse(JSON.stringify(staticTests)));
  };

  const getRuleTests = async (qualityStation) => {
    const ruleTests = await loadQualityStationRulesCards(qualityStation || selectedQualityStation);
    setRuleTests(ruleTests);
    setRuleTestsCopy(JSON.parse(JSON.stringify(ruleTests)));
  };

  const getEventTests = async (qualityStation) => {
    const eventTests = await loadQualityStationDynamicCards(
      qualityStation || selectedQualityStation
    );
    const newEvents = await loadAllEventsFromSplunk(selectedLiveStreamData, startTime, endTime);
    setEventTests(eventTests);
    setEventTestsCopy(JSON.parse(JSON.stringify(eventTests)));
    setEvents(newEvents);
  };

  const getAITests = async (qualityStation) => {
    const aiTests = await loadQualityStationAICards(qualityStation || selectedQualityStation);
    setAITests(aiTests);
    setAITestsCopy(JSON.parse(JSON.stringify(aiTests)));
  };

  const getDefectMapping = async (qualityStation) => {
    const defectMapping = await loadDefectMapping(qualityStation || selectedQualityStation);
    setDefectMapping(defectMapping);
  };

  useEffect(() => {
    loadQualityStationStats();
    // loadOrderIdentifierMapping();
  }, [ruleTests, aiTests, eventTests, staticTests]);

  const loadQualityStationStats = async () => {
    try {
      const response = await axiosClient.get(qualityStationEndpoint);
      setQualityStationStats(response.data.qualityStationStats);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const testLogicSelected = async (index) => {
    setSelectedTestLogicIndex(index);
    setActiveStep(activeStep + 1);
    await getCardsBasedOnTestLogic(index);
  };

  const getCardsBasedOnTestLogic = async (index, qualityStation = selectedQualityStation) => {
    if (index === 0) {
      await getStaticTests(qualityStation);
    } else if (index === 1) {
      await getRuleTests(qualityStation);
    } else if (index === 2) {
      await getEventTests(qualityStation);
    } else if (index === 3) {
      await getAITests(qualityStation);
    } else if (index === 4) {
      await getDefectMapping(qualityStation);
    }
  };

  const openInfoDialog = (index) => {
    setInfoOpen((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  const closeInfoDialog = (index) => {
    setInfoOpen((prev) => {
      prev[index] = false;
      return [...prev];
    });
  };

  const uploadQualityStationImageToServer = async (file, qs, setImageURL) => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('file', file);
    formData.append('qualityStationName', qs.name);

    // Save File to Server
    try {
      const response = await axiosClient.post(saveQualityStationImageEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImageURL({ ...qs, image: response.data.qualityStationImageURL });
      enqueueSnackbar(t('qualityStationImageSuccessfullyUpdated'), { variant: 'success' });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [contactError, setContactError] = useState('');

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const setNewQualityStationContact = (contact, qualityStation, call) => {
    const isValidEmail = validateEmail(contact);
    if (isValidEmail) {
      setContactError(''); // Clear any previous validation error
    } else {
      setContactError(t('invalidEmailAddress'));
    }
    call({ ...qualityStation, contact: contact });
  };

  const qualitySaveButtonIsDisabled =
    newQualityStation.name?.length < 2 ||
    newQualityStation.responsible?.length < 3 ||
    !validateEmail(newQualityStation.contact) ||
    !newQualityStation.image ||
    (orderedQualityStationStats.length !== 0 &&
      (newQualityStation.predecessor == undefined ||
        newQualityStation.predecessor == null ||
        newQualityStation.predecessor == '')) ||
    newQualityStation.maxNumberSuggestions === undefined ||
    newQualityStation.maxNumberSuggestions === null ||
    newQualityStation.maxNumberSuggestions === '' ||
    newQualityStation.maxNumberOfTestsPerOrderIdentifier === undefined ||
    newQualityStation.maxNumberOfTestsPerOrderIdentifier === null ||
    newQualityStation.maxNumberOfTestsPerOrderIdentifier === '' ||
    newQualityStation.orderIdentifierMapping === undefined ||
    newQualityStation.orderIdentifierMapping === null ||
    newQualityStation.orderIdentifierMapping.length === 0;

  const handleQualitySaveButton = async () => {
    try {
      const response = await axiosClient.post(qualityStationEndpoint, {
        qualityStation: {
          ...newQualityStation,
          maxNumberSuggestions: parseInt(newQualityStation.maxNumberSuggestions),
          maxNumberOfTestsPerOrderIdentifier: parseInt(
            newQualityStation.maxNumberOfTestsPerOrderIdentifier
          ),
        },
      });
      setQualityStationStats(response.data.qualityStationStats);
      enqueueSnackbar(t('qualityStationSuccessfullyCreated'), { variant: 'success' });
      setNewQualityStation({});
      setBucketRows([]);
      setFileInputKey(fileInputKey + 2);
      handleCloseDialog();
    } catch (error) {
      enqueueSnackbar(t('errorDuringCreationOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const qualityEditButtonIsDisabled =
    editQualityStation.name?.length < 2 ||
    editQualityStation.responsible?.length < 3 ||
    !validateEmail(editQualityStation.contact) ||
    !editQualityStation.image ||
    editQualityStation.predecessor == undefined ||
    editQualityStation.predecessor == null ||
    editQualityStation.predecessor == '' ||
    editQualityStation.maxNumberSuggestions === undefined ||
    editQualityStation.maxNumberSuggestions === null ||
    editQualityStation.maxNumberSuggestions === '' ||
    editQualityStation.maxNumberOfTestsPerOrderIdentifier === undefined ||
    editQualityStation.maxNumberOfTestsPerOrderIdentifier === null ||
    editQualityStation.maxNumberOfTestsPerOrderIdentifier === '' ||
    editQualityStation.orderIdentifierMapping === '' ||
    editQualityStation.orderIdentifierMapping === undefined ||
    editQualityStation.orderIdentifierMapping === null ||
    editQualityStation.orderIdentifierMapping.length === 0;

  const handleQualityEditButton = async () => {
    try {
      const response = await axiosClient.patch(qualityStationEndpoint, {
        qualityStation: {
          ...editQualityStation,
          maxNumberSuggestions: parseInt(editQualityStation.maxNumberSuggestions),
          maxNumberOfTestsPerOrderIdentifier: parseInt(
            editQualityStation.maxNumberOfTestsPerOrderIdentifier
          ),
        },
      });
      setQualityStationStats(response.data.qualityStationStats);
      enqueueSnackbar(t('qualityStationSuccessfullyEdited'), { variant: 'success' });
      setEditQualityStation({});
      handleCloseDialog();
    } catch (error) {
      enqueueSnackbar(t('errorDuringEditingOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState([]);
  const handleQualityDeleteButton = async (qualityStationIndex) => {
    const qualityStationID = orderedQualityStationStats[qualityStationIndex].id;
    try {
      const response = await axiosClient.delete(qualityStationEndpoint, {
        data: {
          qualityStationID: qualityStationID,
        },
      });
      setQualityStationStats(response.data.qualityStationStats);
      enqueueSnackbar(t('qualityStationSuccessfullyDeleted'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorDuringDeletionOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  /* STREAM FIELD CONFIGURATION */
  const [streamFieldModalOpen, setStreamFieldModalOpen] = useState(false);
  const [liveStreamData, setLiveStreamData] = useState([]);
  const [selectedLiveStreamData, setSelectedLiveStreamData] = useState([]);

  useEffect(() => {
    if (selectedQualityStation && selectedTestLogicIndex === 2) {
      getLiveStreamData();
      getSelectedLiveStreamData();
    }
  }, [selectedQualityStation, selectedTestLogicIndex]);

  // not used at the moment
  /*     const loadDefectDescriptionFields = async () => {
            try {
                const response = await axiosClient.get(getDefectDescriptionFieldsEndpoint, {
                    qualityStationName: selectedLiveStreamData
                });
                setDefectDescriptionFields(response.data.defectDescriptionFields);
            } catch (error) {
                console.error('Error:', error);
            }
        } */

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getSelectedLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(selectedLiveStreamDataEndpoint, {
        headers: {
          qualityStationName: selectedQualityStation,
        },
      });
      if (response.data.selectedLiveStreamData) {
        const selectedData = response.data.selectedLiveStreamData;

        setSelectedLiveStreamData(selectedData);

        if (selectedData && startTime && endTime && moment(startTime).isBefore(endTime)) {
          fetchEventsFromSplunk(selectedData, startTime, endTime);
        } else {
          enqueueSnackbar(t('invalidStartOrEndTime'), { variant: 'error' });
        }
      } else {
        setSelectedLiveStreamData([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const saveSelectedLiveStreamData = async (selectedLiveStreamData) => {
    try {
      const response = await axiosClient.post(selectedLiveStreamDataEndpoint, {
        qualityStationName: selectedQualityStation,
        selectedLiveStreamData: selectedLiveStreamData,
      });

      const selectedData = response.data.selectedLiveStreamData;

      setSelectedLiveStreamData(selectedData);

      if (selectedData && startTime && endTime && moment(startTime).isBefore(endTime)) {
        fetchEventsFromSplunk(selectedData, startTime, endTime);
      } else {
        enqueueSnackbar(t('invalidStartOrEndTime'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchEventsFromSplunk = async (selectedData, startTime, endTime) => {
    try {
      const response = await axiosClient.post(getAllEventsFromSplunkEndpoint, {
        streams: selectedData,
        startTime: startTime,
        endTime: endTime,
      });
      setEvents(response.data.events);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const checkIfOrderidentifierIsUsed = (id = null) => {
    if (!id) {
      return false;
    }
    const fittingRow = bucketRows.find((row) => row.rowID === id);

    if (!fittingRow) {
      return false;
    }

    const orderIdentifier = fittingRow.orderIdentifier;
    return allCards.some((card) => card.orderIdentifier === orderIdentifier);
  };

  const [allCards, setAllCards] = useState([]);

  const loadAllRelatedCards = async (qualityStation) => {
    try {
      const loadedStaticCards = await loadQualityStationStaticCards(qualityStation);
      const loadedRuleCards = await loadQualityStationRulesCards(qualityStation);
      const loadedEventCards = await loadQualityStationDynamicCards(qualityStation);
      const loadedAICards = await loadQualityStationAICards(qualityStation);
      return [...loadedStaticCards, ...loadedRuleCards, ...loadedEventCards, ...loadedAICards];
    } catch (error) {
      console.error('Error loading cards:', error);
      return [];
    }
  };

  const handleOpenEditDialog = async (qualityStation) => {
    const cards = await loadAllRelatedCards(qualityStation.name); // Ensure all data is loaded
    setAllCards(cards); // Update state with all loaded cards
    setSelectedQualityStation(qualityStation.name);
    setEditQualityStation(qualityStation);
    setEditQualityStationOpen(true); // Open dialog after data is loaded
  };

  const bucketColumns = [
    {
      field: 'orderIdentifier',
      headerName: t('orderIdentifier'),
      width: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => params.row.id,
    },
    {
      field: 'notation',
      headerName: t('notation'),
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: 'delete',
      headerName: t('delete'),
      width: 20,
      editable: false,
      flex: 0.3,
      renderCell: (params) => {
        const isOrderIdentifierUsed = checkIfOrderidentifierIsUsed(params.row.rowID);
        console.log('isOrderIdentifierUsed', isOrderIdentifierUsed);
        return (
          <IconButton
            // variant="qualitatio"
            squared={true}
            onClick={() => {
              setBucketRows((prevState) => {
                const filteredRows = prevState.filter((row) => row.id !== params.row.id);
                const newRows = filteredRows.map((row, index) => {
                  if (row.id > params.row.id) {
                    row.id = index;
                  }
                  return row;
                });
                return newRows;
              });
            }}
            style={{
              backgroundColor: isOrderIdentifierUsed
                ? theme.palette.error.secondary
                : theme.palette.error.main,
              width: '36px',
              height: '36px',
            }}
            disabled={isOrderIdentifierUsed}
          >
            <DeleteForeverRoundedIcon color="white" />
          </IconButton>
        );
      },
    },
  ];

  const [bucketRows, setBucketRows] = useState(
    newQualityStation?.orderIdentifierMapping?.map((row, index) => ({
      id: index,
      notation: row.notation,
      orderIdentifier: row.orderIdentifier,
      rowID: row._id,
    }))
  );

  const convertOIMappingCSVToObject = async (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: false, // Do not treat the first row as headers
        skipEmptyLines: true,
        complete: (results) => {
          const rows = results.data;
          let errors = new Set();

          if (rows.length < 2) {
            // Ensure there is more than just the header row
            errors.add('CSV is empty');
            reject('CSV is empty');
            return;
          }

          const data = rows.slice(1); // Skip header row for processing
          const validData = [];

          for (const row of data) {
            // Directly access columns by index
            const notation = row[0]?.trim();
            const orderIdentifier = row[1]?.trim();

            if (row.length !== 2) {
              errors.add('invalidColumnNumber');
            }

            if (typeof notation !== 'string' || isNaN(Number(orderIdentifier))) {
              errors.add('invalidDataTypes');
            }

            validData.push({
              notation,
              orderIdentifier: Number(orderIdentifier),
            });
          }

          if (errors.size > 0) {
            if (errors.has('invalidColumnNumber')) {
              enqueueSnackbar(t('invalidColumnNumber'), { variant: 'error' });
            } else if (errors.has('invalidDataTypes')) {
              enqueueSnackbar(t('invalidDataTypes'), { variant: 'error' });
            }
            resolve({ data: validData, isValid: false });
          } else {
            resolve({ data: validData, isValid: true });
          }
        },
        error: (error) => reject(error),
      });
    });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // handle csv file upload
  const handleCSVFileUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (evt) => {
      try {
        const csv = evt.target.result;
        const { data: csvObject, isValid } = await convertOIMappingCSVToObject(csv);

        if (!isValid) {
          console.error('Invalid CSV data. No new entries were added.');
          return; // Exit if data is invalid
        }

        const orderIdentifierMapping = csvObject.map((row) => ({
          notation: row.notation,
          orderIdentifier: row.orderIdentifier,
        }));

        setNewQualityStation((prev) => ({
          ...prev,
          orderIdentifierMapping,
        }));

        const newBucketRows = orderIdentifierMapping.map((row, index) => ({
          id: index,
          notation: row.notation,
          orderIdentifier: row.orderIdentifier,
        }));

        setBucketRows(newBucketRows);
      } catch (error) {
        console.error('Error parsing CSV:', error);
        enqueueSnackbar('Error parsing CSV', { variant: 'error' });
      }
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    if (addNewQualityStationOpen) {
      setNewQualityStation({
        ...newQualityStation,
        orderIdentifierMapping: bucketRows?.map((row, index) => ({
          notation: row.notation,
          orderIdentifier: row.id,
        })),
      });
    } else {
      setEditQualityStation({
        ...editQualityStation,
        orderIdentifierMapping: bucketRows?.map((row, index) => ({
          _id: row.rowID,
          notation: row.notation,
          orderIdentifier: row.id,
        })),
      });
    }
  }, [bucketRows]);

  useEffect(() => {
    setBucketRows(
      editQualityStation?.orderIdentifierMapping?.map((row, index) => ({
        id: index,
        notation: row.notation,
        orderIdentifier: row.orderIdentifier,
        rowID: row._id,
      }))
    );
  }, [editQualityStationOpen]);

  const [fileInputKey, setFileInputKey] = useState(0);

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setAddNewQualityStationOpen(false);
    setEditQualityStationOpen(false);
    setConfirmationDialogOpen([]);
    setDiscardWarningOpen(false);
  };

  const convertStaticTestsCSVToObject = async (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: false, // Do not treat the first row as headers
        skipEmptyLines: true,
        complete: async (results) => {
          const rows = results.data;
          let errors = new Set();
          if (rows.length < 2) {
            // Ensure there is more than just the header row
            errors.add('CSV is empty');
            reject('CSV is empty');
            return;
          }

          const data = rows.slice(1); // Skip header row for processing
          const uniqueOrderIdentifiers = new Set();
          const validData = [];

          for (const row of data) {
            // Directly access columns by index
            const title = row[0]?.trim();
            const testObject = row[1]?.trim();
            const testLocation = row[2]?.trim();
            const testMethod = row[3]?.trim();
            const testComment = row[4]?.trim(); // Assuming comment is now the fifth column
            const orderIdentifier = row[5]?.trim();

            const requiredValues = [title, testObject, testLocation, testMethod, orderIdentifier];
            const isRowValid = requiredValues.every((value) => value && value !== '');

            if (!isRowValid) {
              errors.add('missingFields');
            }

            if (orderIdentifier) {
              uniqueOrderIdentifiers.add(orderIdentifier);
            }

            validData.push({
              title,
              testObject,
              testLocation,
              testMethod,
              testComment,
              orderIdentifier,
            });
          }

          if (errors.size > 0) {
            if (errors.has('missingFields')) {
              enqueueSnackbar(t('missingFieldsInCSV'), { variant: 'error' });
            }
            resolve({ data: validData, isValid: false });
          } else {
            // If no errors, resolve valid data
            const updatedOrderIdentifiers = await updateOrderIdentifiers(
              Array.from(uniqueOrderIdentifiers)
            );
            validData.forEach((row) => {
              row.orderIdentifier = updatedOrderIdentifiers[row.orderIdentifier];
            });
            resolve({ data: validData, isValid: true });
          }
        },
        error: (error) => reject(error),
      });
    });
  };

  // read out csv file containing static tests and write them to the database
  const handleStaticTestsCSVUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (evt) => {
      try {
        const csv = evt.target.result;
        const { data: csvObject, isValid } = await convertStaticTestsCSVToObject(csv);
        if (!isValid) {
          console.error('Invalid CSV data. No new entries were added.');
          return; // Exit if data is invalid
        }

        const newStaticTests = csvObject.map((row) => ({
          qualityStation: selectedQualityStation,
          type: 'Basis',
          title: row.title,
          images: [],
          testObject: row.testObject,
          testLocation: row.testLocation,
          testMethod: row.testMethod,
          testComment: row.testComment,
          orderIdentifier: row.orderIdentifier,
          positionIdentifier: 1000, // logic for assigning this
          explanation: t('fromStaticTestPlan'),
          creator: {
            creatorId: user.id,
            name: user.name,
            profileImage: user.profileImage,
          },
          creationDate: Date.now(),
          editors: [
            {
              editorId: user.id,
              name: user.name,
              profileImage: user.profileImage,
            },
          ],
        }));
        // Save each card to the server and collect the returned data
        const savedCards = await Promise.all(
          newStaticTests.map(async (card) => {
            try {
              const response = await axiosClient.post(staticCardEndpoint, {
                qualityStation: selectedQualityStation,
                card: card,
                userID: user.id,
              });
              return response.data.card; // Assuming the API returns the saved card data
            } catch (error) {
              console.error('Error saving card:', error);
              return null;
            }
          })
        );

        if (savedCards.some((card) => card === null)) {
          enqueueSnackbar(t('errorSavingStaticTests'), { variant: 'error' });
          return;
        }

        // Update local state with all successfully saved cards
        enqueueSnackbar(t('staticTestProposalSavedSuccessfully'), { variant: 'success' });
        setStaticTestsCopy((currentTests) => [...currentTests, ...savedCards]);
        setStaticTests((currentTests) => [...currentTests, ...savedCards]);
      } catch (error) {
        console.error('Error parsing CSV:', error);
      }
    };
    reader.readAsText(file);
  };

  const formatNotation = (notation) => {
    return notation
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const updateOrderIdentifiers = async (orderIdentifiers) => {
    const qualityStationStatsAtSelectedQualityStation = qualityStationStats?.find(
      (item) => item.name === selectedQualityStation
    );

    const existingMappings =
      qualityStationStatsAtSelectedQualityStation?.orderIdentifierMapping || [];
    const newMappings = [];
    let maxOrderIdentifier = existingMappings.reduce(
      (max, item) => Math.max(max, item.orderIdentifier),
      0
    );

    // Create a map to store the updated order identifiers
    const updatedOrderIdentifiers = {};

    // Check for existing and new order identifiers
    for (const notation of orderIdentifiers) {
      const formattedNotation = formatNotation(notation);
      const existingItem = existingMappings.find(
        (item) => item.notation.toLowerCase() === formattedNotation.toLowerCase()
      );

      if (existingItem) {
        updatedOrderIdentifiers[notation] = existingItem.orderIdentifier;
      } else {
        maxOrderIdentifier += 1;
        newMappings.push({ notation: formattedNotation, orderIdentifier: maxOrderIdentifier });
        updatedOrderIdentifiers[notation] = maxOrderIdentifier;
      }
    }

    if (newMappings.length > 0) {
      const newOrderIdentifierMapping = [...existingMappings, ...newMappings];
      try {
        const response = await axiosClient.patch(qualityStationEndpoint, {
          qualityStation: {
            ...qualityStationStatsAtSelectedQualityStation,
            orderIdentifierMapping: newOrderIdentifierMapping,
          },
        });
        setQualityStationStats(response.data.qualityStationStats);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    return updatedOrderIdentifiers;
  };

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCardIndex, setCurrentCardIndex] = useState(-1);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    setCurrentCardIndex(0);
  }, [currentIndex]);

  useEffect(() => {
    const stepParam = searchParams.get('step');
    const qualityStationParam = searchParams.get('qualityStation');
    const testLogicParam = searchParams.get('testLogic');
    const currentIndexParam = searchParams.get('currentIndex');
    const currentCardIndexParam = searchParams.get('currentCardIndex');

    // Update your component state based on URL parameters
    if (stepParam) {
      setActiveStep(parseInt(stepParam, 10));
    }
    if (qualityStationParam) {
      setSelectedQualityStation(qualityStationParam);
    }
    if (testLogicParam && qualityStationParam) {
      getCardsBasedOnTestLogic(parseInt(testLogicParam, 10), qualityStationParam);
      setSelectedTestLogicIndex(parseInt(testLogicParam, 10));
    }
    if (currentIndexParam) {
      setCurrentIndex(parseInt(currentIndexParam, 10));
    }
    if (currentCardIndexParam) {
      setCurrentCardIndex(parseInt(currentCardIndexParam, 10));
    }
    setInitDone(true);
  }, []);

  useEffect(() => {
    if (initDone) {
      searchParams.set('step', activeStep);
      if (!selectedQualityStationImage) {
        setSelectedQualityStationImage(
          qualityStationStats.find((qs) => qs.name === selectedQualityStation)?.image
        );
      }
      if (activeStep === 0) {
        searchParams.delete('qualityStation');
        searchParams.delete('testLogic');
      }
      if (activeStep === 2) {
        if (searchParams.get('qualityStation') !== selectedQualityStation) {
          searchParams.set('qualityStation', selectedQualityStation);
        }
        searchParams.delete('testLogic');
      }
      if (activeStep === 3) {
        if (searchParams.get('testLogic') !== selectedTestLogicIndex) {
          searchParams.set('testLogic', selectedTestLogicIndex);
        }
        if (searchParams.get('currentIndex') !== currentIndex) {
          searchParams.set('currentIndex', currentIndex);
        }
        if (searchParams.get('currentCardIndex') !== currentCardIndex) {
          searchParams.set('currentCardIndex', currentCardIndex);
        }
      }
      if (activeStep < 3) {
        setCurrentIndex(0);
        setCurrentCardIndex(0);
        searchParams.delete('currentIndex');
        searchParams.delete('currentCardIndex');
      }
      if (currentIndex === -1) {
        setCurrentIndex(0);
      }
      if (currentCardIndex === -1) {
        setCurrentCardIndex(0);
      }

      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }, [activeStep, selectedQualityStation, selectedTestLogicIndex, currentIndex, currentCardIndex]);

  const [allCardNames, setAllCardNames] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const loadCards = async () => {
    if (!isDataLoaded) {
      try {
        const response = await axiosClient.get(getAllCardNamesEndpoint);
        if (response.data.cards) {
          setAllCardNames(response.data.cards);
          setIsDataLoaded(true);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const searchOptions = useMemo(() => {
    return allCardNames?.map((card) => ({ value: card._id, label: card.title }));
  }, [allCardNames]);

  const handleDropdownChange = (e, option) => {
    setSelectedCard(option);
    if (option) {
      const card = allCardNames.find((card) => card.title === option.label);
      const testLogic =
        card.type === 'Basis' ? 0 : card.type === 'Rule' ? 1 : card.type === 'Dynamic' ? 2 : 3;
      const qualityStation = card.qualityStation;

      // Update states based on the selection
      setActiveStep(3);
      setSelectedQualityStation(qualityStation);
      getCardsBasedOnTestLogic(testLogic, qualityStation);
      setSelectedTestLogicIndex(testLogic);
      searchParams.set('id', option.value);
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  };

  const [rotation, setRotation] = useState([0, Math.PI / 4, 0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotation((prevRotation) => [prevRotation[0], prevRotation[1] + 0.01, prevRotation[2]]);
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  const restoreChange = async (id, changeId, onClose) => {
    try {
      const response = await axiosClient.post(restoreChangeEndpoint, {
        id: id,
        creatorId: user.id,
        changeId: changeId,
      });
      if (response.data.card) {
        getCardsBasedOnTestLogic(selectedTestLogicIndex, selectedQualityStation);
        onClose();
        enqueueSnackbar(t('successfullyRestoredChange'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenRestoringChange'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const editPredecessorDropdownValue = useMemo(
    () => ({
      value: editQualityStation.predecessor || -1,
      label:
        qualityStationStats.find(
          (qualityStation) => qualityStation.id === editQualityStation.predecessor
        )?.name || t('noPredecessor'),
    }),
    [editQualityStation.predecessor, qualityStationStats]
  );

  const editPredecessorDropdownOptions = useMemo(
    () => [
      { label: t('noPredecessor'), value: -1 },
      ...qualityStationStats
        .filter((qualityStation) => qualityStation.id !== editQualityStation.id)
        .map((qualityStation) => ({
          label: qualityStation.name,
          value: qualityStation.id,
        })),
    ],
    [qualityStationStats, editQualityStation.id]
  );

  const handleEditPredecessorDropdownChange = (e, option) => {
    if (option) {
      setEditQualityStation((prev) => ({
        ...prev,
        predecessor: option.value,
      }));
    }
  };

  const newPredecessorDropdownValue = useMemo(
    () => ({
      value: newQualityStation.predecessor || -1,
      label:
        qualityStationStats.find(
          (qualityStation) => qualityStation.id === newQualityStation.predecessor
        )?.name || t('noPredecessor'),
    }),
    [newQualityStation.predecessor, qualityStationStats]
  );

  const newPredecessorDropdownOptions = useMemo(
    () => [
      { label: t('noPredecessor'), value: -1 },
      ...qualityStationStats.map((qualityStation) => ({
        label: qualityStation.name,
        value: qualityStation.id,
      })),
    ],
    [qualityStationStats]
  );

  const handleNewPredecessorDropdownChange = (e, option) => {
    if (option) {
      setNewQualityStation((prev) => ({
        ...prev,
        predecessor: option.value,
      }));
    }
  };

  return (
    <PageFrame title={t('productionConfigurator')} Icon={AccountTreeRoundedIcon}>
      <div className="prod-config">
        {activeStep === 0 ? (
          <Grid container display="flex" direction="column" marginTop="2vh">
            <Grid item xs={12} md={6} display="flex" justifyContent="right">
              <QualitatioDropdown
                label={t('searchCard')}
                options={searchOptions}
                value={selectedCard}
                onChange={handleDropdownChange}
                onFocus={loadCards}
                width={'30%'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="step-0">
                {currentModelFileURL ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {threeDProductModels.length > 1 && <Button onClick={handlePrev}>{'<'}</Button>}
                    <Box style={{ display: 'inline-block', margin: '10px' }} maxHeight={'150px'}>
                      <Canvas
                        dpr={[1, 2]}
                        camera={{ fov: 45 }}
                        style={{ width: '100%', height: '100%', borderRadius: '5px' }}
                      >
                        <color attach="background" args={['#ffffff']} />
                        <PresentationControls speed={1.5} global zoom={0.75}>
                          <Stage environment={'sunset'}>
                            <ModelComponent
                              modelFileURL={currentModelFileURL}
                              position={[0, 0, 0]}
                              rotation={rotation}
                              scale={[
                                threeDProductModels[currentModelIndex].modelZoom || 0.75,
                                threeDProductModels[currentModelIndex].modelZoom || 0.75,
                                threeDProductModels[currentModelIndex].modelZoom || 0.75,
                              ]}
                            />
                          </Stage>
                        </PresentationControls>
                      </Canvas>
                    </Box>
                    {threeDProductModels.length > 1 && <Button onClick={handleNext}>{'>'}</Button>}
                  </div>
                ) : (
                  <div style={{ margin: '15%' }} />
                )}
                <div className="welcome-slogan">{t('welcomeToTestPlaning')}</div>
                <div className="configurator-description">
                  {t('theProcessOfTestPlaningConsistsOfTheFollowingFourSubsteps')}
                </div>
                <div className="configurator-steps">
                  <QualitatioProgressBar
                    steps={[
                      t('selectQualityStation'),
                      t('selectTestLogic'),
                      t('createTestCard'),
                      t('checkTestCards'),
                    ]}
                    nonLinear={false}
                    alternativeLabel={true}
                    activeStep={3}
                    setActiveStep={setActiveStep}
                  />
                </div>
                <div className="start-button">
                  <div className="start-button-wrapper">
                    <QualitatioButton
                      text={t('start')}
                      width={'100%'}
                      onClick={() => {
                        setActiveStep(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div className="all-steps">
            <div className="container-back">
              <div className="buttons-wrapper">
                {selectedTestLogicIndex === 2 && activeStep === 3 && (
                  <div className="upper-icon">
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      onClick={() => {
                        setStreamFieldModalOpen(true);
                      }}
                      style={{
                        backgroundColor: theme.palette.success.secondary,
                        width: '36px',
                        height: '36px',
                      }}
                    >
                      <img className="configSettingsImage" src="/images/settingsIcon.svg" />
                    </IconButton>
                    <LiveStreamDataSelectionModal
                      streamFieldModalOpen={streamFieldModalOpen}
                      setStreamFieldModalOpen={setStreamFieldModalOpen}
                      liveStreamData={liveStreamData}
                      selectedLiveStreamData={selectedLiveStreamData}
                      saveSelectedLiveStreamData={saveSelectedLiveStreamData}
                    />
                  </div>
                )}
              </div>
              <IconButton
                variant="qualitatio"
                squared={true}
                onClick={() => {
                  if (activeStep === 4) {
                    getCardsBasedOnTestLogic(selectedTestLogicIndex);
                  }
                  if (
                    activeStep !== 3 ||
                    selectedTestLogicIndex === -1 ||
                    selectedTestLogicIndex === 4 ||
                    (selectedTestLogicIndex === 0 &&
                      isEqualWithoutMetaFields(staticTests, staticTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 1 &&
                      isEqualWithoutMetaFields(ruleTests, ruleTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 2 &&
                      isEqualWithoutMetaFields(eventTests, eventTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 3 &&
                      isEqualWithoutMetaFields(aiTests, aiTestsCopy, ['changeEvents', 'editors']))
                  ) {
                    setActiveStep(activeStep - 1);
                  } else {
                    setDiscardWarningOpen(true);
                  }
                  setDiscardWarningNextOrBack('back');
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '48px',
                  height: '48px',
                }}
              >
                <ArrowBackIosNewRoundedIcon color="primary" />
              </IconButton>
            </div>
            <div className="container-card">
              <div className="steps-overview">
                {activeStep} {t('/of4Steps')}
              </div>
              <div className="mobile-stepper-container">
                <QualitatioMobileStepper
                  totalSteps={5}
                  activeStep={activeStep}
                  controllable={false}
                />
              </div>
              {activeStep === 1 && (
                <div className="step-2">
                  <div className="choose-quality-station">{t('selectAQualityStation')}</div>
                  <div className="quality-station-list">
                    {orderedQualityStationStats.map((qualityStation, index) => {
                      return (
                        <div className="quality-station" key={index}>
                          <div className="quality-station-name-wrapper">
                            <QualitatioButton
                              text={qualityStation.name}
                              startIcon={<img src={qualityStation.image} />}
                              startIconIsImage={true}
                              startIconRounded={true}
                              endIcon={<ArrowForwardIosRoundedIcon />}
                              width={'100%'}
                              onClick={() => {
                                setSelectedQualityStation(qualityStation.name);
                                setSelectedQualityStationImage(qualityStation.image);
                                setActiveStep(activeStep + 1);
                              }}
                            />
                          </div>
                          <div className="quality-station-edit-wrapper">
                            <IconButton
                              variant="qualitatio"
                              squared={true}
                              onClick={() => handleOpenEditDialog(qualityStation)}
                              style={{
                                backgroundColor: theme.palette.success.secondary,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <EditRoundedIcon color="primary" />
                            </IconButton>
                            <QualitatioEmptyDialog
                              open={editQualityStationOpen}
                              onClose={handleCloseDialog}
                            >
                              <div className="add-quality-station-modal">
                                <div className="mobile-stepper-container-add-QS">
                                  <QualitatioMobileStepper
                                    totalSteps={activeStep}
                                    activeStep={activeStep}
                                    controllable={false}
                                  />
                                </div>

                                <div className="add-quality-station-title">
                                  {t('editQualityStation')}
                                </div>
                                <div className="add-quality-station-form">
                                  <QualitatioInput
                                    label={t('qualityStationName')}
                                    value={editQualityStation.name}
                                    type="text"
                                    placeholder={t('qualityStationPlaceholder')}
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        name: e.currentTarget.value,
                                      })
                                    }
                                  />
                                  <QualitatioInput
                                    label={t('responsible')}
                                    value={editQualityStation.responsible}
                                    type="text"
                                    placeholder={t('defaultName')}
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        responsible: e.currentTarget.value,
                                      })
                                    }
                                  />
                                  <QualitatioInput
                                    label={t('contact')}
                                    value={editQualityStation.contact}
                                    type="text"
                                    placeholder="max.mustermann@mail.com"
                                    width={'100%'}
                                    onChange={(e) =>
                                      setNewQualityStationContact(
                                        e.currentTarget.value,
                                        editQualityStation,
                                        setEditQualityStation
                                      )
                                    }
                                  />
                                  {contactError && (
                                    <div style={{ color: 'red' }} className="error">
                                      {contactError}
                                    </div>
                                  )}
                                  <QualitatioInput
                                    label={t('qualityStationImage')}
                                    key={fileInputKey}
                                    type="file"
                                    width={'100%'}
                                    onChange={(e) =>
                                      uploadQualityStationImageToServer(
                                        e.target.files[0],
                                        editQualityStation,
                                        setEditQualityStation
                                      )
                                    }
                                    inputProps={{ accept: 'image/*' }}
                                  />
                                  <QualitatioDropdown
                                    label={t('previousQualityStation')}
                                    value={editPredecessorDropdownValue}
                                    options={editPredecessorDropdownOptions}
                                    width={'100%'}
                                    onChange={handleEditPredecessorDropdownChange}
                                  />
                                  <QualitatioInput
                                    label={t('maxNumberOfTestSuggestions')}
                                    value={editQualityStation.maxNumberSuggestions}
                                    type="number"
                                    placeholder="10"
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        maxNumberSuggestions: e.currentTarget.value,
                                      })
                                    }
                                  />
                                  <QualitatioInput
                                    label={t('maxNumberOfTestsPerOrderIdentifier')}
                                    value={editQualityStation.maxNumberOfTestsPerOrderIdentifier}
                                    type="number"
                                    placeholder="3"
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        maxNumberOfTestsPerOrderIdentifier: e.currentTarget.value,
                                      })
                                    }
                                  />

                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <QualitatioInput
                                        key={fileInputKey + 1}
                                        label={t('orderIdentifierMapping')}
                                        type="file"
                                        width={'100%'}
                                        inputProps={{ accept: '.csv' }}
                                        onChange={(e) => {
                                          handleCSVFileUpload(e.target.files[0]);
                                        }}
                                        disabled={true}
                                      />
                                    </Grid>
                                    {bucketRows?.length > 0 && (
                                      <Grid item xs={12}>
                                        <QualitatioProTable
                                          columns={bucketColumns}
                                          rows={bucketRows}
                                          height={400}
                                          width={'100%'}
                                          setRows={setBucketRows}
                                          positionIdentiferAsIndex={false}
                                          isAddPossible={true}
                                          checkboxSelection={false}
                                          slots={{
                                            toolbar: CustomToolbar,
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>

                                  <div className="add-quality-station-buttons">
                                    <QualitatioButton
                                      text={t('save')}
                                      order="primary"
                                      disabled={qualityEditButtonIsDisabled}
                                      onClick={async () => {
                                        await handleQualityEditButton();
                                      }}
                                      width={'100%'}
                                      height={'5vh'}
                                      fontSize={'calc(12px + 0.25vw)'}
                                      endIcon={<ArrowForwardIosRoundedIcon />}
                                    />
                                    <QualitatioButton
                                      text={t('cancel')}
                                      order="secondary"
                                      onClick={() => {
                                        setEditQualityStation({});
                                        handleCloseDialog();
                                      }}
                                      width={'100%'}
                                      height={'5vh'}
                                      fontSize={'calc(12px + 0.25vw)'}
                                      startIcon={<ArrowBackIosNewRoundedIcon />}
                                    />
                                  </div>
                                </div>
                              </div>
                            </QualitatioEmptyDialog>
                          </div>
                          <div className="quality-station-delete-wrapper">
                            <IconButton
                              className="delete-button"
                              variant="qualitatio"
                              squared={true}
                              onClick={() => {
                                let newConfirmationDialogOpen = [...confirmationDialogOpen];
                                newConfirmationDialogOpen[index] = true;
                                setConfirmationDialogOpen(newConfirmationDialogOpen);
                              }}
                              style={{
                                backgroundColor: theme.palette.error.main,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <DeleteForeverRoundedIcon color="white" />
                            </IconButton>
                            <QualitatioDialog
                              title={t('deleteQualityStation')}
                              description={t('doYouReallyWantToDeleteThisQualityStation')}
                              open={confirmationDialogOpen[index]}
                              onClose={handleCloseDialog}
                              maxWidth="sm"
                              actions={[
                                {
                                  label: t('cancel'),
                                  onClick: () => handleCloseDialog(),
                                  order: 'secondary',
                                },
                                {
                                  label: t('delete'),
                                  onClick: () => {
                                    handleQualityDeleteButton(index);
                                    handleCloseDialog();
                                  },
                                  order: 'primary',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="add-quality-station">
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      onClick={() => setAddNewQualityStationOpen(true)}
                      style={{
                        backgroundColor: theme.palette.success.secondary,
                        width: '36px',
                        height: '36px',
                      }}
                    >
                      <AddRoundedIcon color="primary" />
                    </IconButton>
                    <QualitatioEmptyDialog
                      open={addNewQualityStationOpen}
                      onClose={handleCloseDialog}
                    >
                      <div className="add-quality-station-modal">
                        <div className="steps-overview-add-QS">
                          {activeStep} {t('/of4Steps')}
                        </div>
                        <div className="mobile-stepper-container-add-QS">
                          <QualitatioMobileStepper
                            totalSteps={5}
                            activeStep={activeStep}
                            controllable={false}
                          />
                        </div>

                        <div className="add-quality-station-title">{t('addNewQualityStation')}</div>
                        <div className="add-quality-station-form">
                          <QualitatioInput
                            label={t('qualityStationName')}
                            type="text"
                            placeholder="Prüfstation 1"
                            width={'100%'}
                            value={newQualityStation.name || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                name: e.currentTarget.value,
                              })
                            }
                          />
                          <QualitatioInput
                            label={t('responsible')}
                            type="text"
                            placeholder="Max Mustermann"
                            width={'100%'}
                            value={newQualityStation.responsible || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                responsible: e.currentTarget.value,
                              })
                            }
                          />
                          <QualitatioInput
                            label={t('contact')}
                            type="text"
                            placeholder="max.mustermann@mail.com"
                            width={'100%'}
                            value={newQualityStation.contact || ''}
                            onChange={(e) =>
                              setNewQualityStationContact(
                                e.currentTarget.value,
                                newQualityStation,
                                setNewQualityStation
                              )
                            }
                          />
                          {contactError && (
                            <div style={{ color: 'red' }} className="error">
                              {contactError}
                            </div>
                          )}
                          <QualitatioInput
                            key={fileInputKey}
                            label={t('qualityStationImage')}
                            type="file"
                            width={'100%'}
                            inputProps={{ accept: 'image/*' }}
                            onChange={(e) => {
                              uploadQualityStationImageToServer(
                                e.target.files[0],
                                newQualityStation,
                                setNewQualityStation
                              );
                            }}
                          />
                          <QualitatioDropdown
                            label={t('previousQualityStation')}
                            options={newPredecessorDropdownOptions}
                            width={'100%'}
                            value={newPredecessorDropdownValue}
                            onChange={handleNewPredecessorDropdownChange}
                          />
                          <QualitatioInput
                            label={t('maxNumberOfTestSuggestions')}
                            type="number"
                            placeholder="10"
                            width={'100%'}
                            value={newQualityStation.maxNumberSuggestions || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                maxNumberSuggestions: e.currentTarget.value,
                              })
                            }
                          />
                          <QualitatioInput
                            label={t('maxNumberOfTestPerOrderIdentifier')}
                            type="number"
                            placeholder="3"
                            width={'100%'}
                            value={newQualityStation.maxNumberOfTestsPerOrderIdentifier || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                maxNumberOfTestsPerOrderIdentifier: e.currentTarget.value,
                              })
                            }
                          />

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <QualitatioInput
                                key={fileInputKey + 1}
                                label={t('orderIdentifierMapping')}
                                type="file"
                                width={'100%'}
                                inputProps={{ accept: '.csv' }}
                                onChange={(e) => {
                                  handleCSVFileUpload(e.target.files[0]);
                                }}
                              />
                            </Grid>
                            {bucketRows?.length > 0 && (
                              <Grid item xs={12}>
                                <QualitatioProTable
                                  columns={bucketColumns}
                                  rows={bucketRows}
                                  height={400}
                                  width={'100%'}
                                  setRows={setBucketRows}
                                  isAddPossible={true}
                                  checkboxSelection={false}
                                  slots={{
                                    toolbar: CustomToolbar,
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                          <div className="add-quality-station-buttons">
                            <QualitatioButton
                              text={t('save')}
                              order="primary"
                              disabled={qualitySaveButtonIsDisabled}
                              onClick={handleQualitySaveButton}
                              width={'100%'}
                              height={'5vh'}
                              fontSize={'calc(12px + 0.25vw)'}
                              endIcon={<ArrowForwardIosRoundedIcon />}
                            />
                            <QualitatioButton
                              text={t('cancel')}
                              order="secondary"
                              onClick={() => {
                                setNewQualityStation({});
                                handleCloseDialog();
                                setBucketRows([]);
                                setFileInputKey(fileInputKey + 2);
                              }}
                              width={'100%'}
                              height={'5vh'}
                              fontSize={'calc(12px + 0.25vw)'}
                              startIcon={<ArrowBackIosNewRoundedIcon />}
                            />
                          </div>
                        </div>
                      </div>
                    </QualitatioEmptyDialog>
                  </div>
                </div>
              )}

              {activeStep === 2 && (
                <div className="step-2">
                  <div className="choose-test-logic">{t('selectATestLogic')}</div>
                  <div className="test-logic-list">
                    <div className="test-logic">
                      <div className="test-logic-name-wrapper">
                        <QualitatioButton
                          text={selectedQualityStation}
                          disabled={true}
                          startIcon={<img src={selectedQualityStationImage} alt="qs" />}
                          startIconIsImage={true}
                          width={'100%'}
                          startIconRounded={true}
                        />
                      </div>
                    </div>
                    {testLogic.map((logicName, index) => {
                      return (
                        <div className="test-logic" key={index}>
                          <div className="test-logic-name-wrapper">
                            <QualitatioButton
                              text={logicName}
                              width={'100%'}
                              onClick={async () => await testLogicSelected(index)}
                              endIcon={<ArrowForwardIosRoundedIcon />}
                              startIcon={
                                <img
                                  src={
                                    '/images/' +
                                    (index === 0
                                      ? 'Basis'
                                      : index === 1
                                        ? 'Rules'
                                        : index === 2
                                          ? 'Events'
                                          : index === 3
                                            ? 'AI'
                                            : 'Copilot') +
                                    '.png'
                                  }
                                  alt={'icon'}
                                />
                              }
                              startIconIsImage={true}
                              startIconRounded={false}
                            />
                          </div>
                          <div className="test-logic-info-wrapper">
                            <IconButton
                              variant="qualitatio"
                              squared={true}
                              onClick={() => openInfoDialog(index)}
                              style={{
                                backgroundColor: theme.palette.success.secondary,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <InfoRoundedIcon color="primary" />
                            </IconButton>
                            <QualitatioDialog
                              title={
                                index === 0
                                  ? t('staticTests')
                                  : index === 1
                                    ? t('ruleBasedTests')
                                    : index === 2
                                      ? t('eventBasedTests')
                                      : index === 3
                                        ? t('aiBasedTests')
                                        : t('copilotTests')
                              }
                              description={
                                index === 0
                                  ? t('basisTestInfoText')
                                  : index === 1
                                    ? t('ruleTestInfoText')
                                    : index === 2
                                      ? t('eventTestInfoText')
                                      : index === 3
                                        ? t('aiTestInfoText')
                                        : t('copilotInfoText')
                              }
                              open={infoOpen[index]}
                              onClose={() => closeInfoDialog(index)}
                              maxWidth="sm"
                              actions={[
                                {
                                  label: t('alright'),
                                  onClick: () => {
                                    closeInfoDialog(index);
                                  },
                                  order: 'primary',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {activeStep === 3 && (
                <div className="step-3">
                  <div className="add-test-card">
                    <div>
                      {selectedTestLogicIndex !== 4 ? (
                        <>
                          <span>{t('createA') + ' '}</span>
                          <span className="highlighted-text">
                            {selectedTestLogicIndex === 0
                              ? t('static')
                              : selectedTestLogicIndex === 1
                                ? t('ruleBased')
                                : selectedTestLogicIndex === 2
                                  ? t('eventBased')
                                  : t('aiBased')}
                          </span>
                          <span>{' ' + t('testCard')}</span>
                        </>
                      ) : (
                        <>
                          <span>{t('mapEvery') + ' '}</span>
                          <span className="highlighted-text">{t('defect')}</span>
                          <span>{' ' + t('withTestKnowledge')}</span>
                        </>
                      )}
                    </div>
                    {selectedTestLogicIndex === 0 && (
                      <QualitatioDropzone
                        enablePreview={false}
                        acceptedFileTypes={{ 'text/csv': ['.csv'] }}
                        onDrop={handleStaticTestsCSVUpload}
                      >
                        <Typography>{<FaFileCsv />}</Typography>
                        <Typography>{t('uploadStaticTests')}</Typography>
                        <Typography>{t('orClickToUpload')}</Typography>
                      </QualitatioDropzone>
                    )}
                  </div>
                  {selectedTestLogicIndex === 0 && (
                    <BasisTests
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      staticTestsGroundTruth={staticTests}
                      setStaticTestsGroundTruth={setStaticTests}
                      staticTests={staticTestsCopy}
                      setStaticTests={setStaticTestsCopy}
                      qualityStation={selectedQualityStation}
                      setQualityStationStats={setQualityStationStats}
                      orderIdentifierMapping={
                        qualityStationStats.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.orderIdentifierMapping
                      }
                      restoreChange={restoreChange}
                    />
                  )}
                  {selectedTestLogicIndex === 1 && (
                    <RuleTests
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      currentCardIndex={currentCardIndex}
                      setCurrentCardIndex={setCurrentCardIndex}
                      ruleTestsGroundTruth={ruleTests}
                      setRuleTestsGroundTruth={setRuleTests}
                      ruleTests={ruleTestsCopy}
                      setRuleTests={setRuleTestsCopy}
                      qualityStation={selectedQualityStation}
                      setQualityStationStats={setQualityStationStats}
                      orderIdentifierMapping={
                        qualityStationStats.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.orderIdentifierMapping
                      }
                      restoreChange={restoreChange}
                    />
                  )}
                  {selectedTestLogicIndex === 2 && (
                    <EventTests
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      currentCardIndex={currentCardIndex}
                      setCurrentCardIndex={setCurrentCardIndex}
                      setEventTestsGroundTruth={setEventTests}
                      eventTestsGroundTruth={eventTests}
                      eventTests={eventTestsCopy}
                      setEventTests={setEventTestsCopy}
                      qualityStation={selectedQualityStation}
                      setQualityStationStats={setQualityStationStats}
                      orderIdentifierMapping={
                        qualityStationStats.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.orderIdentifierMapping
                      }
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                      getEventTests={getEventTests}
                      selectedLiveStreamData={selectedLiveStreamData}
                      events={events}
                      setEvents={setEvents}
                      restoreChange={restoreChange}
                    />
                  )}
                  {selectedTestLogicIndex === 3 && (
                    <AITests
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      currentCardIndex={currentCardIndex}
                      setCurrentCardIndex={setCurrentCardIndex}
                      aiTestsGroundTruth={aiTests}
                      setAITestsGroundTruth={setAITests}
                      aiTests={aiTestsCopy}
                      setAITests={setAITestsCopy}
                      qualityStation={selectedQualityStation}
                      setQualityStationStats={setQualityStationStats}
                      orderIdentifierMapping={
                        qualityStationStats.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.orderIdentifierMapping
                      }
                      restoreChange={restoreChange}
                    />
                  )}
                  {selectedTestLogicIndex === 4 && (
                    <Copilot
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      currentCardIndex={currentCardIndex}
                      setCurrentCardIndex={setCurrentCardIndex}
                      setTestLogicIndex={setSelectedTestLogicIndex}
                      defectMapping={defectMapping}
                      setDefectMapping={setDefectMapping}
                      qualityStation={selectedQualityStation}
                      orderIdentifierMapping={
                        qualityStationStats.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.orderIdentifierMapping
                      }
                    />
                  )}
                </div>
              )}

              {activeStep === 4 && (
                <div className="step-4">
                  <div className="overview-about-test-cards">
                    {t('testCardsOverview')}
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      onClick={() => {
                        setInfoDialogOpen(true);
                      }}
                      style={{
                        backgroundColor: theme.palette.success.secondary,
                        width: '36px',
                        height: '36px',
                      }}
                    >
                      <InfoRoundedIcon color="primary" />
                    </IconButton>
                    <QualitatioDialog
                      title={t('testCardsOverview')}
                      description={t('testCardsOverviewInfoText')}
                      open={infoDialogOpen}
                      onClose={() => setInfoDialogOpen(false)}
                      maxWidth="sm"
                      actions={[
                        {
                          label: t('alright'),
                          onClick: () => {
                            setInfoDialogOpen(false);
                          },
                          order: 'primary',
                        },
                      ]}
                    />
                  </div>
                  <OverviewTests
                    qualityStationStats={qualityStationStats}
                    qualityStation={selectedQualityStation}
                    setQualityStation={setSelectedQualityStation}
                    selectedQualityStationImage={selectedQualityStationImage}
                    setQualityStationImage={setSelectedQualityStationImage}
                    selectedTestLogicIndex={selectedTestLogicIndex}
                    setTestLogicIndex={setSelectedTestLogicIndex}
                    orderIdentifierMapping={
                      qualityStationStats.find(
                        (qualityStation) => qualityStation.name === selectedQualityStation
                      )?.orderIdentifierMapping
                    }
                  />
                </div>
              )}
            </div>
            <div className="container-next">
              <IconButton
                variant="qualitatio"
                squared={true}
                disabled={nextDisabled}
                onClick={async () => {
                  if (activeStep === 2) {
                    await getCardsBasedOnTestLogic(selectedTestLogicIndex);
                  }
                  if (
                    selectedTestLogicIndex === 4 ||
                    activeStep !== 3 ||
                    (selectedTestLogicIndex === 0 &&
                      isEqualWithoutMetaFields(staticTests, staticTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 1 &&
                      isEqualWithoutMetaFields(ruleTests, ruleTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 2 &&
                      isEqualWithoutMetaFields(eventTests, eventTestsCopy, [
                        'changeEvents',
                        'editors',
                      ])) ||
                    (selectedTestLogicIndex === 3 &&
                      isEqualWithoutMetaFields(aiTests, aiTestsCopy, ['changeEvents', 'editors']))
                  ) {
                    setActiveStep(activeStep + 1);
                  } else {
                    setDiscardWarningOpen(true);
                    setDiscardWarningNextOrBack('next');
                  }
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '48px',
                  height: '48px',
                }}
              >
                <ArrowForwardIosRoundedIcon color="primary" />
              </IconButton>
              <QualitatioDialog
                title={t('unsavedChanges')}
                description={t(
                  'youHaveUnsavedChangesWhichCanBeLostIfYouProceedNowAreYouSureYouWantToProceed'
                )}
                open={discardWarningOpen}
                onClose={() => {
                  handleCloseDialog();
                  setDiscardWarningNextOrBack('');
                }}
                maxWidth="sm"
                actions={[
                  {
                    label: t('continue'),
                    onClick: () => {
                      handleCloseDialog();
                      if (discardWarningNextOrBack === 'next') {
                        setActiveStep(activeStep + 1);
                      } else {
                        setActiveStep(activeStep - 1);
                      }
                      setDiscardWarningNextOrBack('');
                    },
                    order: 'secondary',
                  },
                  {
                    label: t('cancel'),
                    onClick: () => {
                      handleCloseDialog();
                      setDiscardWarningNextOrBack('');
                    },
                    order: 'primary',
                  },
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </PageFrame>
  );
}
