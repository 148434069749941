import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';

import EditRule from './EditRule';
import DeleteRule from './DeleteRule';
import ViewRule from './ViewRule';

const RulesOverview = ({
  rules,
  setRules,
  ruleTests,
  setRuleTests,
  currentIndex,
  ruleEditOpen,
  setRuleEditOpen,
  handleViewRule,
  handleAddRule,
  handleSave,
  handleEditRule,
  handleDeleteRule,
  deleteRule,
}) => {
  const theme = useTheme();
  return (
    <>
      {rules?.map((rule, index) => {
        return (
          <div className="rule-test" key={index}>
            <div className="rule-test-name-wrapper">
              <ViewRule
                rule={rule}
                index={index}
                currentIndex={currentIndex}
                handleViewRule={handleViewRule}
              />
            </div>
            <div className="rule-test-edit-wrapper">
              <EditRule
                rule={rule}
                index={index}
                rules={rules}
                setRules={setRules}
                ruleTests={ruleTests}
                setRuleTests={setRuleTests}
                currentIndex={currentIndex}
                ruleEditOpen={ruleEditOpen}
                setRuleEditOpen={setRuleEditOpen}
                handleSave={handleSave}
                handleEditRule={handleEditRule}
              />
            </div>
            <div className="rule-test-delete-wrapper">
              <DeleteRule
                index={index}
                handleDeleteRule={handleDeleteRule}
                deleteRule={deleteRule}
              />
            </div>
          </div>
        );
      })}
      <div className="add-rule">
        <IconButton
          variant="qualitatio"
          squared={true}
          onClick={() => {
            handleAddRule();
          }}
          style={{
            backgroundColor: theme.palette.success.secondary,
            width: '36px',
            height: '36px',
          }}
        >
          <AddRoundedIcon color="primary" />
        </IconButton>
      </div>
    </>
  );
};

RulesOverview.propTypes = {
  rules: PropTypes.array.isRequired,
  setRules: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  ruleEditOpen: PropTypes.bool.isRequired,
  setRuleEditOpen: PropTypes.func.isRequired,
  handleViewRule: PropTypes.func.isRequired,
  handleAddRule: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleEditRule: PropTypes.func.isRequired,
  handleDeleteRule: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
};

export default RulesOverview;
