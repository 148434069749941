import axios from 'axios';
import { isProtectedRoute } from '../config/enums';
import { useAuthStore } from '../store/auth.store';

// Determine the base URL based on the environment
let baseURL =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_URL : '%REACT_APP_URL%';

// Create an Axios instance with default configurations
const axiosClient = axios.create({
  baseURL,
  withCredentials: true, // Include credentials with requests
});

let isRefreshing = false; // Flag to indicate if a token refresh is in progress
let refreshSubscribers = []; // List of callbacks to be executed after token refresh

/**
 * Subscribe a callback to be called once the token refresh is complete.
 * @param {Function} cb - The callback function to execute after the token is refreshed.
 */
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

/**
 * Execute all subscribed callbacks once the token refresh is complete.
 */
const onRefreshed = () => {
  refreshSubscribers.forEach((cb) => cb());
  refreshSubscribers = []; // Clear the subscribers list after execution
};

// Request interceptor to add user ID to headers if available
axiosClient.interceptors.request.use(
  async (config) => {
    const user = useAuthStore.getState().user; // Retrieve the current user from the auth store
    if (user?.id) {
      config.headers['X-UserID'] = user.id; // Attach user ID to the request headers
    }

    // Add X-App-Platform header for all requests
    config.headers['X-App-Platform'] = 'web'; // Set the platform header to 'web'
    return config;
  },
  (error) => {
    return Promise.reject(error); // Forward any request errors
  }
);

// Response interceptor to handle 401 Unauthorized errors and token refresh
axiosClient.interceptors.response.use(
  (response) => {
    return response; // Return the response if no errors
  },
  async (error) => {
    const originalRequest = error.config; // Preserve the original request
    const authStore = useAuthStore.getState(); // Get the current auth store state

    // Check if the error is a 401 Unauthorized and if the request has not been retried
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // If a token refresh is already in progress, queue the current request
        return new Promise((resolve) => {
          subscribeTokenRefresh(() => {
            resolve(axiosClient(originalRequest)); // Retry the request after refresh
          });
        });
      }

      originalRequest._retry = true; // Mark the request as retried
      isRefreshing = true; // Set the refreshing flag

      try {
        await authStore.refreshToken(); // Attempt to refresh the token
        isRefreshing = false; // Reset the refreshing flag
        onRefreshed(); // Notify subscribers that the token has been refreshed
        return axiosClient(originalRequest); // Retry the original request
      } catch (refreshError) {
        isRefreshing = false; // Reset the refreshing flag on error
        if (isProtectedRoute(window.location.pathname)) {
          authStore.logout(); // Log out the user if the route is protected
          window.location.href = '/login'; // Redirect to login page
        }
        return Promise.reject(refreshError); // Forward the refresh error
      }
    }

    // If 401 occurs on a protected route, log out the user
    if (error.response?.status === 401 && isProtectedRoute(window.location.pathname)) {
      authStore.logout(); // Log out the user
      window.location.href = '/login'; // Redirect to login page
    }

    return Promise.reject(error); // Forward any other errors
  }
);

export default axiosClient;
