/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import InputRoundedIcon from '@mui/icons-material/InputRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ShutterSpeedRoundedIcon from '@mui/icons-material/ShutterSpeedRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import SystemSecurityUpdateGoodRoundedIcon from '@mui/icons-material/SystemSecurityUpdateGoodRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MinMenuIcon from '../../assets/icons/MinMenuIcon';
import ROLES from '../../authentication/roleConst';
import { useAuthStore } from '../../store/auth.store';
import { getRedirectPath } from '../../utils/accessUtils';
import './style.css';

export const Navigationbaropen = ({ className, setNavigationBarOpen, allowedModules }) => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState('');
  const navigate = useNavigate();
  const currentPage = window.location.pathname;
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  const user = useAuthStore((state) => state.user);

  const menuClicked = (id) => {
    if (openDropdown === id) {
      setOpenDropdown('');
    } else {
      setOpenDropdown(id);
    }
  };

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <>
      {user &&
        !currentPage.startsWith('/intelligent_testing/recommender') &&
        !currentPage.startsWith('/efficient_testing/recommender') &&
        !currentPage.startsWith('/setup') && (
          <>
            <div className={`navigationbaropen ${className}`}>
              <div className="link">
                <Link to={getRedirectPath(user)}>
                  <HomeRoundedIcon color={currentPage === '/home' ? 'primaryShiny' : 'white'} />
                </Link>
                <div
                  className="label"
                  style={{
                    color:
                      currentPage === '/home'
                        ? 'var(--m-3refprimaryprimary-90)'
                        : 'var(--m-3white)',
                  }}
                  onClick={() => navigateTo('/home')}
                >
                  {t('home')}
                </div>
                <div onClick={() => setNavigationBarOpen(false)}>
                  <MinMenuIcon className="icons-einklappen-instance" fill={'var(--m-3white)'} />
                </div>
              </div>
              {user?.role === ROLES.ADMIN && (
                <div className="dropdown">
                  <div className="div" onClick={() => menuClicked('Systemadministration')}>
                    <HandymanRoundedIcon
                      color={currentPage === '/basis' ? 'primaryShiny' : 'white'}
                    />
                    <div
                      className="label"
                      style={{
                        color:
                          currentPage === '/basis'
                            ? 'var(--m-3refprimaryprimary-90)'
                            : 'var(--m-3white)',
                      }}
                    >
                      {t('systemAdministration')}
                    </div>
                    <ArrowForwardIosRoundedIcon
                      className="img"
                      color={currentPage === '/basis' ? 'primaryShiny' : 'white'}
                      style={{
                        transform:
                          openDropdown === 'Systemadministration'
                            ? 'rotate(90deg)'
                            : 'rotate(0deg)',
                      }}
                    />
                  </div>
                  <div
                    className="links-container"
                    style={{ display: openDropdown === 'Systemadministration' ? 'block' : 'none' }}
                  >
                    <Link to="/basis/user_management">
                      <div className="link-2">
                        <PersonAddAltRoundedIcon
                          color={
                            currentPage === '/basis/user_management' ? 'primaryShiny' : 'white'
                          }
                        />
                        <div
                          className="text-wrapper"
                          style={{
                            color:
                              currentPage === '/basis/user_management'
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                          }}
                        >
                          {t('userManagement')}
                        </div>
                      </div>
                    </Link>
                    <Link to="/basis/input_configurator">
                      <div className="link-2">
                        <InputRoundedIcon
                          color={
                            currentPage === '/basis/input_configurator' ? 'primaryShiny' : 'white'
                          }
                        />
                        <div
                          className="text-wrapper"
                          style={{
                            color:
                              currentPage === '/basis/input_configurator'
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                          }}
                        >
                          {t('inputConfiguration')}
                        </div>
                      </div>
                    </Link>
                    <Link to="/basis/consumer_configurator">
                      <div className="link-2">
                        <OutputRoundedIcon
                          color={
                            currentPage === '/basis/consumer_configurator'
                              ? 'primaryShiny'
                              : 'white'
                          }
                        />
                        <div
                          className="text-wrapper"
                          style={{
                            color:
                              currentPage === '/basis/consumer_configurator'
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                          }}
                        >
                          {t('consumerConfiguration')}
                        </div>
                      </div>
                    </Link>
                    <Link to="/basis/system_configurator">
                      <div className="link-2">
                        <SettingsRoundedIcon
                          color={
                            currentPage === '/basis/system_configurator' ? 'primaryShiny' : 'white'
                          }
                        />
                        <div
                          className="text-wrapper"
                          style={{
                            color:
                              currentPage === '/basis/system_configurator'
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                          }}
                        >
                          {t('systemConfiguration')}
                        </div>
                      </div>
                    </Link>
                    <div className="line" />
                  </div>
                </div>
              )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.AI_ENGINEER) &&
                allowedModules.includes('Artificial Intelligence') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Künstliche Intelligenz')}>
                      <ScatterPlotRoundedIcon
                        color={
                          currentPage === '/artificial_intelligence' ? 'primaryShiny' : 'white'
                        }
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/artificial_intelligence'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('artificialIntelligence')}
                      </div>{' '}
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={
                          currentPage === '/artificial_intelligence' ? 'primaryShiny' : 'white'
                        }
                        style={{
                          transform:
                            openDropdown === 'Künstliche Intelligenz'
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{
                        display: openDropdown === 'Künstliche Intelligenz' ? 'block' : 'none',
                      }}
                    >
                      <Link to="/artificial_intelligence/configurator">
                        <div className="link-2">
                          <SsidChartRoundedIcon
                            color={
                              currentPage === '/artificial_intelligence/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/artificial_intelligence/configurator'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('aiTraining')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/artificial_intelligence/optimizer">
                        <div className="link-2">
                          <TuneRoundedIcon
                            color={
                              currentPage === '/artificial_intelligence/optimizer'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/artificial_intelligence/optimizer'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('aiOptimization')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.IT_MANAGER) &&
                allowedModules.includes('Intelligent Testing') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Intelligentes Testen')}>
                      <VerifiedRoundedIcon
                        color={currentPage === '/intelligent_testing' ? 'primaryShiny' : 'white'}
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/intelligent_testing'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('intelligentTesting')}
                      </div>
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={currentPage === '/intelligent_testing' ? 'primaryShiny' : 'white'}
                        style={{
                          transform:
                            openDropdown === 'Intelligentes Testen'
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{
                        display: openDropdown === 'Intelligentes Testen' ? 'block' : 'none',
                      }}
                    >
                      <Link to="/intelligent_testing/configurator">
                        <div className="link-2">
                          <AccountTreeRoundedIcon
                            color={
                              currentPage === '/intelligent_testing/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/intelligent_testing/configurator'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('productionConfigurator')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/intelligent_testing/recommender/select">
                        <div className="link-2">
                          <SystemSecurityUpdateGoodRoundedIcon
                            color={
                              currentPage.startsWith('/intelligent_testing/recommender/')
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color: currentPage.startsWith('/intelligent_testing/recommender/')
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                            }}
                          >
                            {t('aiBasedTesting')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/intelligent_testing/knowledge">
                        <div className="link-2">
                          <MenuBookRoundedIcon
                            color={
                              currentPage === '/intelligent_testing/knowledge'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color: currentPage.startsWith('/intelligent_testing/knowledge')
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                            }}
                          >
                            {t('testKnowledge')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.ET_MANAGER) &&
                allowedModules.includes('Efficient Testing') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Efficient Testing')}>
                      <ShutterSpeedRoundedIcon
                        color={currentPage === '/efficient_testing' ? 'primaryShiny' : 'white'}
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/efficient_testing'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('efficientTesting')}
                      </div>
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={currentPage === '/efficient_testing' ? 'primaryShiny' : 'white'}
                        style={{
                          transform:
                            openDropdown === 'Efficient Testing' ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{ display: openDropdown === 'Efficient Testing' ? 'block' : 'none' }}
                    >
                      <Link to="/efficient_testing/configurator">
                        <div className="link-2">
                          <AccountTreeRoundedIcon
                            color={
                              currentPage === '/efficient_testing/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/efficient_testing/configurator'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('productionConfigurator')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/efficient_testing/recommender/select">
                        <div className="link-2">
                          <AltRouteRoundedIcon
                            color={
                              currentPage.startsWith('/efficient_testing/recommender/')
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color: currentPage.startsWith('/efficient_testing/recommender/')
                                ? 'var(--m-3refprimaryprimary-90)'
                                : 'var(--m-3white)',
                            }}
                          >
                            {t('efficientTesting')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.EWS_MANAGER) &&
                allowedModules.includes('Early Warning System') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Early Warning System')}>
                      <AddAlertRoundedIcon
                        color={currentPage === '/early_warning_system' ? 'primaryShiny' : 'white'}
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/early_warning_system'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('ews.name')}
                      </div>
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={currentPage === '/early_warning_system' ? 'primaryShiny' : 'white'}
                        style={{
                          transform:
                            openDropdown === 'Early Warning System'
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{
                        display: openDropdown === 'Early Warning System' ? 'block' : 'none',
                      }}
                    >
                      <Link to="/early_warning_system/monitor">
                        <div className="link-2">
                          <BarChartRoundedIcon
                            color={
                              currentPage === '/early_warning_system/monitor'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/early_warning_system/monitor'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('monitoring')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.BUSINESS_ANALYST) &&
                allowedModules.includes('Business Insights') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Business Insights')}>
                      <InsightsRoundedIcon
                        color={currentPage === '/business_insights' ? 'primaryShiny' : 'white'}
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/business_insights'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('businessInsights')}
                      </div>
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={currentPage === '/business_insights' ? 'primaryShiny' : 'white'}
                        style={{
                          transform:
                            openDropdown === 'Business Insights' ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{ display: openDropdown === 'Business Insights' ? 'block' : 'none' }}
                    >
                      <Link to="/business_insights/monitor">
                        <div className="link-2">
                          <BarChartRoundedIcon
                            color={
                              currentPage === '/business_insights/monitor'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/business_insights/monitor'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('monitoring')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/business_insights/reporter">
                        <div className="link-2">
                          <DescriptionRoundedIcon
                            color={
                              currentPage === '/business_insights/reporter'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/business_insights/reporter'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('reporting')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.BUSINESS_ANALYST) &&
                allowedModules.includes('Extended Insights') && (
                  <div className="dropdown">
                    <div className="div" onClick={() => menuClicked('Extended Insights')}>
                      <QueryStatsIcon
                        color={currentPage === '/extended_insights' ? 'primaryShiny' : 'white'}
                      />
                      <div
                        className="label"
                        style={{
                          color:
                            currentPage === '/extended_insights'
                              ? 'var(--m-3refprimaryprimary-90)'
                              : 'var(--m-3white)',
                        }}
                      >
                        {t('extendedInsights')}
                      </div>
                      <ArrowForwardIosRoundedIcon
                        className="img"
                        color={currentPage === '/extended_insights' ? 'primaryShiny' : 'white'}
                        style={{
                          transform:
                            openDropdown === 'Extended Insights' ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className="links-container"
                      style={{ display: openDropdown === 'Extended Insights' ? 'block' : 'none' }}
                    >
                      <Link to="/extended_insights/dashboard">
                        <div className="link-2">
                          <BarChartRoundedIcon
                            color={
                              currentPage === '/extended_insights/dashboard'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/extended_insights/dashboard'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('ei.dashboard')}
                          </div>
                        </div>
                      </Link>
                      <Link to="/extended_insights/settings">
                        <div className="link-2">
                          <SettingsRoundedIcon
                            color={
                              currentPage === '/extended_insights/settings'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                          <div
                            className="text-wrapper"
                            style={{
                              color:
                                currentPage === '/extended_insights/settings'
                                  ? 'var(--m-3refprimaryprimary-90)'
                                  : 'var(--m-3white)',
                            }}
                          >
                            {t('ei.settings')}
                          </div>
                        </div>
                      </Link>
                      <div className="line" />
                    </div>
                  </div>
                )}
              <div
                className="link-3"
                onClick={() => navigate(`/profile?lastPath=${encodeURIComponent(currentPath)}`)}
              >
                <PersonRoundedIcon color={currentPage === '/profile' ? 'primaryShiny' : 'white'} />
                <div
                  className="label"
                  style={{
                    color:
                      currentPage === '/profile'
                        ? 'var(--m-3refprimaryprimary-90)'
                        : 'var(--m-3white)',
                  }}
                >
                  {t('account')}
                </div>
              </div>
              <div className="link-3" onClick={() => navigate('/support')}>
                <InfoRoundedIcon color={currentPage === '/support' ? 'primaryShiny' : 'white'} />
                <div
                  className="label"
                  style={{
                    color:
                      currentPage === '/support'
                        ? 'var(--m-3refprimaryprimary-90)'
                        : 'var(--m-3white)',
                  }}
                >
                  {t('support')}
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};
