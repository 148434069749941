/*-------Dev Root-------*/
// will be replaced by the actual URL during the build process through the entrypoint.sh script
const root =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : '%REACT_APP_API_URL%';

const apiRoot = root + 'v1/';
export const filesRoot = root + 'files/';

/*-------Authentication-------*/

const authEndpoint = apiRoot + 'auth/';

export const loginEndpoint = authEndpoint + 'login';
export const loginLdapEndpoint = authEndpoint + 'login-ldap';
export const loginOauthEndpoint = authEndpoint + 'login-oauth';
export const registerEndpoint = authEndpoint + 'register';
export const logoutEndpoint = authEndpoint + 'logout';
export const refreshTokenEndpoint = authEndpoint + 'refresh-tokens';
export const forgotPasswordEndpoint = authEndpoint + 'forgot-password';
export const resetPasswordEndpoint = authEndpoint + 'reset-password';
export const sendVerificationEmailEndpoint = authEndpoint + 'send-verification-email';
export const verifyEmailEndpoint = authEndpoint + 'verify-email';
export const verifySessionEndpoint = authEndpoint + 'status';
// Additionaly, there are routes for managing api tokens
export const apiTokenEndpoint = authEndpoint + 'api-token';

/*-------Authentication System-------*/
const authSystemEndpoint = apiRoot + 'auth-systems/';
export const getSupportedAuthenticationSystemsEndpoint =
  authSystemEndpoint + 'supported-auth-systems';
export const activeAuthenticationSystemsEndpoint = authSystemEndpoint + 'active-auth-systems';
export const ldapSettingsEndpoint = authSystemEndpoint + 'ldap-settings';
export const activeOAuthProvidersEndpoint = authSystemEndpoint + 'oauth-providers';
export const oauthSettingsEndpoint = authSystemEndpoint + 'oauth-settings';
export const connectGroupsEndpoint = authSystemEndpoint + 'connect-groups';
export const getLDAPGroupsForRoleEndpoint = authSystemEndpoint + 'ldap-groups';
export const toggleAuthSystemActivationEndpoint = authSystemEndpoint + 'toggle-activation';

/*-------Users-------*/
// Here Post, get for create user and get all users
// get, patch, delete for get, update and delete user by id
export const usersEndpoint = apiRoot + 'users';
export const getManageUsersEndpoint = () => {
  // Retrieve the user object from local storage
  // Retrieve the object from local storage
  const storedData = localStorage.getItem('auth-storage');

  // Parse the JSON string into an object
  const authData = storedData ? JSON.parse(storedData) : null;

  // Extract the user object from the state
  const user = authData?.state?.user || null;

  if (user) {
    const userId = JSON.parse(user).id;
    return `${usersEndpoint}/${userId}`;
  }
  // Return a default or error endpoint or null, based on how you want to handle this
  return null;
};
export const saveUserProfileImageEndpoint = usersEndpoint + '/saveUserProfileImage';
export const verifyUserEndpoint = usersEndpoint + '/verify';

/*-------Artificial Intelligence-------*/
const artificialIntelligenceEndpoint = apiRoot + 'artificial_intelligence/';

const aiConfiguratorEndpoint = artificialIntelligenceEndpoint + 'configurator/';
export const mlModelEndpoint = aiConfiguratorEndpoint + 'mlModel';
export const activateModelEndpoint = aiConfiguratorEndpoint + 'activateModel';
export const deactivateModelEndpoint = aiConfiguratorEndpoint + 'deactivateModel';
export const checkModelDeletionEndpoint = aiConfiguratorEndpoint + 'checkModelDeletion';
export const saveCSVFileTrainingEndpoint = aiConfiguratorEndpoint + 'saveCSVFile';
export const getLiveTrainingMetaDataEndpoint = aiConfiguratorEndpoint + 'getLiveTrainingMetaData';
export const getDefectClassNamesEndpoint = aiConfiguratorEndpoint + 'getDefectClassNames';
export const retrainModelEndpoint = aiConfiguratorEndpoint + 'retrainModel';
export const getStreamTrainingDataAsCSVEndpoint =
  aiConfiguratorEndpoint + 'getStreamTrainingDataAsCSV';
export const getMLModelNamesEndpoint = aiConfiguratorEndpoint + 'getMLModelNames';
export const getMLModelsWithPredictedClassesEndpoint =
  aiConfiguratorEndpoint + 'getMLModelsWithPredictedClasses';
export const getETModelsEndpoint = aiConfiguratorEndpoint + 'getETModels';

export const optimizerEndpoint = artificialIntelligenceEndpoint + 'optimizer/';
export const optimizationDataEndpoint = optimizerEndpoint + 'optimizationData';
export const saveCSVFileOptimizationEndpoint = optimizerEndpoint + 'saveCSVFile';
export const activateOptimizationDataSourceEndpoint =
  optimizerEndpoint + 'activateOptimizationDataSource';
export const deactivateOptimizationDataSourceEndpoint =
  optimizerEndpoint + 'deactivateOptimizationDataSource';
export const generateCSVOptimizationFileFromStreamEndpoint =
  optimizerEndpoint + 'generateCSVOptimizationFileFromStream';

/* -------Basis------- */
const basisEndpoint = apiRoot + 'basis/';
const systemConfiguratorEndpoint = basisEndpoint + 'system_configurator/';
export const saveLogoFileEndpoint = systemConfiguratorEndpoint + 'saveLogoFile';

// Order Identifier Mapping
export const orderIdentifierMappingFileEndpoint =
  systemConfiguratorEndpoint + 'OrderIdentifierMappingFile';
export const orderIdentifierMappingEndpoint = systemConfiguratorEndpoint + 'OrderIdentifierMapping';

// Defect Description
export const defectDescriptionHierarchieEndpoint =
  systemConfiguratorEndpoint + 'DefectDescriptionHierarchie';
export const defectDescriptionFieldsEndpoint =
  systemConfiguratorEndpoint + 'DefectDescriptionFields';
export const defectDescriptionFieldsAndLevelsEndpoint =
  systemConfiguratorEndpoint + 'DefectDescriptionFieldsAndLevels';
export const defectDescriptionHierarchieFileEndpoint =
  systemConfiguratorEndpoint + 'DefectDescriptionHierarchieFile';

// Defect Documentation
export const defectDocumentationLevelsEndpoint =
  systemConfiguratorEndpoint + 'defectDocumentationLevels';

export const defaultLanguageEndpoint = systemConfiguratorEndpoint + 'defaultLanguage';
export const cardLayoutEndpoint = systemConfiguratorEndpoint + 'cardLayout';
export const qrCodeSettingsEndpoint = systemConfiguratorEndpoint + 'QRCodeSettings';
export const allowedModulesEndpoint = systemConfiguratorEndpoint + 'allowedModule'; // #documentation: This route is undocumented
export const licenseEndpoint = systemConfiguratorEndpoint + 'license'; // #documentation: This route is undocumented
export const productModel3DFileEndpoint = systemConfiguratorEndpoint + '3DProductModelFile'; // #documentation: This route is undocumented
export const productModel3DEndpoint = systemConfiguratorEndpoint + '3DProductModel'; // #documentation: This route is undocumented
export const externalAPIUsageEndpoint = systemConfiguratorEndpoint + 'externalAPIUsage'; // #documentation: This route is undocumented

// Stream Endpoints - #documentation: All of these routes are undocumented
const inputConfiguratorEndpoint = basisEndpoint + 'input_configurator/';
export const inputStreamsEndpoint = inputConfiguratorEndpoint + 'inputStreams';
export const liveStreamDataEndpoint = inputConfiguratorEndpoint + 'liveStreamData';
export const testConnectionEndpoint = inputConfiguratorEndpoint + 'testConnection';
export const activateInputStreamEndpoint = inputConfiguratorEndpoint + 'activateInputStream';
export const deactivateInputStreamEndpoint = inputConfiguratorEndpoint + 'deactivateInputStream';

// Output Endpoints - #documentation: All of these routes are undocumented
const outputConfiguratorEndpoint = basisEndpoint + 'output_configurator/';
export const availableOutputStreamsEndpoint = outputConfiguratorEndpoint + 'availableOutputStreams';
export const outputStreamSubscriptionEndpoint =
  outputConfiguratorEndpoint + 'outputStreamSubscription';

/* -------Business Insights------- */
const businessInsightsEndpoint = apiRoot + 'business_insights/';
const monitorEndpoint = businessInsightsEndpoint + 'monitor/';
export const getStatsOfQualityStationEndpoint = monitorEndpoint + 'getStatsOfQualityStation';
export const getQualityStationsEndpoint = monitorEndpoint + 'getQualityStations';
export const getQualityStationMonitoringDataEndpoint =
  monitorEndpoint + 'getQualityStationMonitoringData';

const reporterEndpoint = businessInsightsEndpoint + 'reporter/';
export const getProductIDsEndpoint = reporterEndpoint + 'getProductIDs';
export const getQualityStationReportingDataEndpoint =
  reporterEndpoint + 'getQualityStationReportingData';
export const getQualityStationImagesEndpoint = reporterEndpoint + 'getQualityStationImages';

/* -------Intelligent Testings------- */
const intelligentTestingEndpoint = apiRoot + 'intelligent_testing/';
const itConfiguratorEndpoint = intelligentTestingEndpoint + 'configurator/';
export const qualityStationStaticCardsEndpoint =
  itConfiguratorEndpoint + 'qualityStationStaticCards';
export const qualityStationRuleCardsEndpoint = itConfiguratorEndpoint + 'qualityStationRuleCards';
export const qualityStationEventCardsEndpoint = itConfiguratorEndpoint + 'qualityStationEventCards';
export const qualityStationAICardsEndpoint = itConfiguratorEndpoint + 'qualityStationAICards';
export const selectedLiveStreamDataEndpoint = itConfiguratorEndpoint + 'selectedLiveStreamData';
export const defectMappingEndpoint = itConfiguratorEndpoint + 'defectMapping';
export const generateCardEndpoint = itConfiguratorEndpoint + 'generateCard';
export const generateCardImageEndpoint = itConfiguratorEndpoint + 'generateCardImage';
export const saveQualityStationImageEndpoint = itConfiguratorEndpoint + 'saveQualityStationImage';
export const qualityStationEndpoint = itConfiguratorEndpoint + 'qualityStation';
export const saveTestCardImageEndpoint = itConfiguratorEndpoint + 'saveTestCardImage';
export const getTestCardPerformanceEndpoint = itConfiguratorEndpoint + 'getTestCardPerformance';
export const getTestCardFeedbackEndpoint = itConfiguratorEndpoint + 'getTestCardFeedback';
export const overviewTestsEndpoint = itConfiguratorEndpoint + 'overviewTests';
export const getAllEventsFromSplunkEndpoint = itConfiguratorEndpoint + 'getAllEventsFromSplunk';
export const getAvailableAIClassesEndpoint = itConfiguratorEndpoint + 'getAvailableAIClasses';
export const getQualityStationAllRulesEndpoint =
  itConfiguratorEndpoint + 'getQualityStationAllRules';
export const getNumberOfCommentsAndToDosEndpoint =
  itConfiguratorEndpoint + 'getNumberOfCommentsAndToDos';
export const getChangeEventsEndpoint = itConfiguratorEndpoint + 'getChangeEvents';
export const processCommentEndpoint = itConfiguratorEndpoint + 'processComment';
export const staticCardEndpoint = itConfiguratorEndpoint + 'staticCard';
export const ruleCardEndpoint = itConfiguratorEndpoint + 'ruleCard';
export const eventCardEndpoint = itConfiguratorEndpoint + 'eventCard';
export const aiCardEndpoint = itConfiguratorEndpoint + 'aiCard';
export const getCardsByIDsEndpoint = itConfiguratorEndpoint + 'getCardsByIDs';
export const cardCommentEndpoint = itConfiguratorEndpoint + 'cardComment';
export const restoreChangeEndpoint = itConfiguratorEndpoint + 'restoreChange';
export const toDoEndpoint = itConfiguratorEndpoint + 'toDo';

const recommenderEndpoint = intelligentTestingEndpoint + 'recommender/';
export const getCardsEndpoint = recommenderEndpoint + 'getCards';
export const getDefectDescriptionHierarchieAtDocumentationLevelsEndpoint =
  recommenderEndpoint + 'getDefectDescriptionHierarchieAtDocumentationLevels';
export const getUserStatsEndpoint = recommenderEndpoint + 'getUserStats';
export const testFinishedEndpoint = recommenderEndpoint + 'testFinished';
export const saveDefectImageEndpoint = recommenderEndpoint + 'saveDefectImage';
export const getQualityStationsWithImagesEndpoint =
  recommenderEndpoint + 'getQualityStationsWithImages';
export const getQualityStationImageEndpoint = recommenderEndpoint + 'getQualityStationImage';
export const sendFeedbackEndpoint = recommenderEndpoint + 'sendFeedback';

const knowledgeEndpoint = intelligentTestingEndpoint + 'knowledge/';
export const getAllCardsKnowledgeDataEndpoint = knowledgeEndpoint + 'getAllCardsKnowledgeData';
export const getAllCardsFromMongoEndpoint = knowledgeEndpoint + 'getAllCardsFromMongo';
export const getAllCardNamesEndpoint = knowledgeEndpoint + 'getAllCardNames';

/* -------Efficient Testings------- */
const efficientTestingEndpoint = apiRoot + 'efficient_testing/';
const etConfiguratorEndpoint = efficientTestingEndpoint + 'configurator/';
export const getETQualityStationsEndpoint = etConfiguratorEndpoint + 'getQualityStations';
export const addETQualityStationEndpoint = etConfiguratorEndpoint + 'addQualityStation';
export const updateETQualityStationEndpoint = etConfiguratorEndpoint + 'updateQualityStation';
export const deleteETQualityStationEndpoint = etConfiguratorEndpoint + 'deleteQualityStation';
export const saveETQualityStationImageEndpoint = etConfiguratorEndpoint + 'saveQualityStationImage';
export const updateETQualityStationSelectedModelEndpoint =
  etConfiguratorEndpoint + 'updateQualityStationSelectedModel';
export const updateETQualityStationSensitivityEndpoint =
  etConfiguratorEndpoint + 'updateQualityStationSensitivity';

const etRecommenderEndpoint = efficientTestingEndpoint + 'recommender/';
export const getTestRecommendationEndpoint = etRecommenderEndpoint + 'getTestRecommendation';
export const saveTestRecommendationGroundTruthEndpoint =
  etRecommenderEndpoint + 'saveTestRecommendationGroundTruth';

/* -------Warning System------- */
const warningSystemEndpoint = apiRoot + 'ews/';
export const monitoredSystemsEndpoint = warningSystemEndpoint + 'monitoredSystems';

/* -------Intelligent Documentation------- */
export const aiProcessingInputEndpoint = apiRoot + 'intelligent_documentation';
const intelligentDocumentationEndpoint = aiProcessingInputEndpoint + '/';
export const intelligentDocumentationSettingsEndpoint =
  intelligentDocumentationEndpoint + 'settings';

/* -------Extended Insights------- */
const extendedInsightsEndpoint = apiRoot + 'extended_insights/';
export const getSPCMonitorSystemsEndpoint = extendedInsightsEndpoint + 'spcMonitorSystems';
export const createSPCMonitorSystemEndpoint = extendedInsightsEndpoint + 'createSPCMonitor';
export const getControlChartDataEndpoint = extendedInsightsEndpoint + 'controlChartData';
