// CardManagement.js
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { eventCardEndpoint } from '../../../../api/endpoints';
import { useAuthStore } from '../../../../store/auth.store';
import TestCard from '../../TestCard';
import { uploadCardImageToServer } from '../../utils';
import CardControls from './CardControls';
import styles from './EventTests.module.css';

const CardManagement = ({
  currentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  setEventTestsGroundTruth,
  eventTestsGroundTruth,
  eventTests,
  setEventTests,
  qualityStation,
  orderIdentifierMapping,
  selectedLiveStreamData,
  events,
  restoreChange,
  filteredEventData,
  explanation,
  selectedEvent,
  setDeleteCardWarningOpen,
  eventDataWithId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();

  const setCardProperty = (property, value) => {
    const currentEventExplanation = explanation;
    const currentEventExplanationParts = new Set(currentEventExplanation.split(' - '));

    setEventTests((prevState) =>
      prevState.map((test) => {
        const testExplanationParts = new Set(test.explanation.split(' - '));
        const isMatchingExplanation = [...testExplanationParts].every((part) =>
          currentEventExplanationParts.has(part)
        );

        if (isMatchingExplanation) {
          const indexInSubset = prevState
            .filter((t) => {
              const parts = new Set(t.explanation.split(' - '));
              return [...parts].every((part) => currentEventExplanationParts.has(part));
            })
            .indexOf(test);

          if (indexInSubset === currentCardIndex) {
            return { ...test, [property]: value };
          }
        }
        return test;
      })
    );
  };

  const setTitle = (newTitle) => setCardProperty('title', newTitle);

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, qualityStation, 'dynamic');
    }

    const currentEventExplanation = explanation;
    const currentEventExplanationParts = new Set(currentEventExplanation.split(' - '));

    setEventTests((prevState) =>
      prevState.map((test) => {
        const testExplanationParts = new Set(test.explanation.split(' - '));
        const isMatchingExplanation = [...testExplanationParts].every((part) =>
          currentEventExplanationParts.has(part)
        );

        if (isMatchingExplanation) {
          const indexInSubset = prevState
            .filter((t) => {
              const parts = new Set(t.explanation.split(' - '));
              return [...parts].every((part) => currentEventExplanationParts.has(part));
            })
            .indexOf(test);

          if (indexInSubset === currentCardIndex) {
            const updatedImages = new Set([...test.images, newFileURL]);
            return { ...test, images: Array.from(updatedImages) };
          }
        }
        return test;
      })
    );
  };

  const setTestObject = (newTestObject) => setCardProperty('testObject', newTestObject);
  const setTestMethod = (newTestMethod) => setCardProperty('testMethod', newTestMethod);
  const setTestLocation = (newTestLocation) => setCardProperty('testLocation', newTestLocation);
  const setTestComment = (newTestComment) => setCardProperty('testComment', newTestComment);
  const setOrderIdentifier = (newOrderIdentifier) =>
    setCardProperty('orderIdentifier', newOrderIdentifier);

  const deleteImage = (imageIndex) => {
    const currentEventExplanation = explanation;
    const currentEventExplanationParts = new Set(currentEventExplanation.split(' - '));

    setEventTests((prevState) =>
      prevState.map((test) => {
        const testExplanationParts = new Set(test.explanation.split(' - '));
        const isMatchingExplanation = [...testExplanationParts].every((part) =>
          currentEventExplanationParts.has(part)
        );

        if (isMatchingExplanation) {
          const indexInSubset = prevState
            .filter((t) => {
              const parts = new Set(t.explanation.split(' - '));
              return [...parts].every((part) => currentEventExplanationParts.has(part));
            })
            .indexOf(test);

          if (indexInSubset === currentCardIndex) {
            const updatedImages = [...test.images];
            updatedImages.splice(imageIndex, 1);
            return { ...test, images: updatedImages };
          }
        }
        return test;
      })
    );
  };

  const calculateCurrentCardIndex = (newCardExplanation) => {
    const newCardExplanationParts = new Set(newCardExplanation.split(' - '));
    const matchingTests = eventTests.filter((test) => {
      const testExplanationParts = new Set(test.explanation.split(' - '));
      return [...testExplanationParts].every((part) => newCardExplanationParts.has(part));
    });
    return matchingTests.length;
  };

  const addCard = () => {
    const currentEvent = filteredEventData[currentIndex];
    const currentStreamData = selectedLiveStreamData.find(
      (el) => el.streamName === currentEvent[0]
    );
    const eventImageField = currentStreamData?.imageFieldName;

    const fieldNames = currentStreamData?.fieldNames.reduce((acc, fieldName, index) => {
      acc[fieldName] = currentEvent[index + 1];
      return acc;
    }, {});

    const newCard = {
      qualityStation: qualityStation,
      type: 'Dynamic',
      title: '',
      images: [],
      testObject: '',
      testLocation: '',
      testMethod: '',
      testComment: '',
      orderIdentifier: 0,
      positionIdentifier: 1000,
      explanation: explanation,
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      dynamic: {
        eventImageField: eventImageField,
        eventStreamName: currentEvent[0],
        eventFrequency: currentEvent.slice(-1)[0],
        isEvent: true,
        ...fieldNames,
      },
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    setEventTests([...eventTests, newCard]);
    setCurrentCardIndex(calculateCurrentCardIndex(newCard.explanation) || 0);
  };

  const isValidEventCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.dynamic.eventStreamName !== '' &&
        card.dynamic.eventFrequency !== ''
      );
    });
  };

  const handleSaveError = (error, errors) => {
    if (error.response && error.response.data.message.startsWith('Duplicate card title:')) {
      errors.add(t('duplicateCardTitle') + ': "' + error.response.data.duplicateCardTitle + '"');
    } else {
      errors.add('errorWhileSavingDynamicTestProposal');
      console.error('Error:', error);
    }
  };

  const handleSave = async (updatedEventTests) => {
    if (!isValidEventCards(updatedEventTests || eventTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), {
        variant: 'warning',
      });
      return;
    }

    const cardsToSave = (updatedEventTests || eventTests).filter((card) => {
      const groundTruth = eventTestsGroundTruth.find((el) => el.id === card.id);
      return (!card.id || !_.isEqual(card, groundTruth)) && card.explanation === explanation;
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(eventCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card;
        } catch (error) {
          handleSaveError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((el) => el === null).length > 0) {
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingDynamicTestProposal'), {
          variant: 'error',
        });
      }
      return;
    }

    updateTestsAndGroundTruth(savedCards);

    enqueueSnackbar(t('eventBasedTestingSavedSuccessfully'), {
      variant: 'success',
    });
  };

  const updateTestsAndGroundTruth = (savedCards) => {
    setEventTests((currentTests) => {
      const updatedTests = [...currentTests];
      const filteredCardsWithoutIds = updatedTests.filter((card) => card.id);
      savedCards.forEach((savedCard) => {
        const index = filteredCardsWithoutIds.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          filteredCardsWithoutIds[index] = _.cloneDeep(savedCard);
        } else {
          filteredCardsWithoutIds.push(_.cloneDeep(savedCard));
        }
      });

      return filteredCardsWithoutIds;
    });

    setEventTestsGroundTruth((currentGroundTruth) => {
      const updatedGroundTruth = [...currentGroundTruth];
      savedCards.forEach((savedCard) => {
        const index = updatedGroundTruth.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          updatedGroundTruth[index] = _.cloneDeep(savedCard);
        } else {
          updatedGroundTruth.push(_.cloneDeep(savedCard));
        }
      });
      return updatedGroundTruth;
    });
  };

  const updateCurrentCardIndex = (direction) => {
    const currentEventExplanationParts = new Set(explanation.split(' - '));
    const matchingTests = eventTests.filter((test) => {
      const testExplanationParts = new Set(test.explanation.split(' - '));
      return [...testExplanationParts].every((part) => currentEventExplanationParts.has(part));
    });

    if (direction === 'inc') {
      if (currentCardIndex < matchingTests.length - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(currentCardIndex - 1);
      }
    }
  };

  const getFilteredCards = () => {
    const currentExplanation = explanation;
    const explanationParts = new Set(currentExplanation.split(' - '));

    return eventTests
      .map((card) => {
        const cardExplanationParts = new Set(card.explanation.split(' - '));
        const isExactMatch =
          explanationParts.size === cardExplanationParts.size &&
          [...explanationParts].every((part) => cardExplanationParts.has(part));
        const isSubset = [...cardExplanationParts].every((part) => explanationParts.has(part));
        return {
          ...card,
          isExactMatch,
          isSubset: isSubset && !isExactMatch,
        };
      })
      .filter((card) => card.isSubset || card.isExactMatch);
  };

  const renderCardComponent = () => {
    const filteredCards = getFilteredCards();

    if (filteredCards.length > 0 && currentCardIndex < filteredCards.length) {
      const cardToDisplay = filteredCards[currentCardIndex];

      return (
        <TestCard
          id={cardToDisplay?.id || ''}
          editable={cardToDisplay.isExactMatch}
          title={cardToDisplay?.title}
          setTitle={setTitle}
          images={cardToDisplay?.images}
          setImages={setImages}
          testObject={cardToDisplay?.testObject}
          setTestObject={setTestObject}
          testMethod={cardToDisplay?.testMethod}
          setTestMethod={setTestMethod}
          testLocation={cardToDisplay?.testLocation}
          setTestLocation={setTestLocation}
          testComment={cardToDisplay?.testComment}
          setTestComment={setTestComment}
          orderIdentifier={cardToDisplay?.orderIdentifier}
          setOrderIdentifier={setOrderIdentifier}
          generation={t('Dynamic')}
          explanation={cardToDisplay?.explanation}
          index={currentCardIndex}
          metaIndex={currentIndex}
          numberCards={filteredCards?.length}
          qualityStation={qualityStation}
          orderIdentifierMapping={orderIdentifierMapping}
          deleteImage={deleteImage}
          editors={cardToDisplay?.editors}
          creator={cardToDisplay?.creator}
          creationDate={cardToDisplay?.creationDate}
          event={selectedEvent}
          restoreChange={restoreChange}
        />
      );
    }
  };

  const filteredCards = useMemo(
    () => getFilteredCards(),
    [explanation, eventTests, currentCardIndex]
  );
  const renderedCards = useMemo(
    () => renderCardComponent(),
    [filteredCards, currentCardIndex, explanation]
  );

  return (
    <>
      <div className={styles.testCards}>{renderedCards}</div>
      <CardControls
        currentCardIndex={currentCardIndex}
        updateCurrentCardIndex={updateCurrentCardIndex}
        filteredCards={filteredCards}
        setCurrentCardIndex={setCurrentCardIndex}
        events={events}
        currentIndex={currentIndex}
        addCard={addCard}
        setDeleteCardWarningOpen={setDeleteCardWarningOpen}
        handleSave={handleSave}
        eventDataWithId={eventDataWithId}
        qualityStation={qualityStation}
        setEventTests={setEventTests}
        eventTests={eventTests}
        explanation={explanation}
        selectedLiveStreamData={selectedLiveStreamData}
      />
    </>
  );
};

CardManagement.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  setEventTestsGroundTruth: PropTypes.func.isRequired,
  eventTestsGroundTruth: PropTypes.array.isRequired,
  eventTests: PropTypes.array.isRequired,
  setEventTests: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  selectedLiveStreamData: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  restoreChange: PropTypes.func.isRequired,
  filteredEventData: PropTypes.array.isRequired,
  explanation: PropTypes.string.isRequired,
  selectedEvent: PropTypes.object,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  eventDataWithId: PropTypes.array.isRequired,
};

export default CardManagement;
