import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRobot } from 'react-icons/fa6';
import { PiSortDescending } from 'react-icons/pi';
import axiosClient from '../../api/axiosClient';
import {
  cardCommentEndpoint,
  externalAPIUsageEndpoint,
  generateCardImageEndpoint,
  getChangeEventsEndpoint,
  getNumberOfCommentsAndToDosEndpoint,
  toDoEndpoint,
} from '../../api/endpoints';
import LabelIcon from '../../assets/icons/LabelIcon';
import TestCommentIcon from '../../assets/icons/TestCommentIcon';
import TestLocationIcon from '../../assets/icons/TestLocationIcon';
import TestMethodIcon from '../../assets/icons/TestMethodIcon';
import TestObjectIcon from '../../assets/icons/TestObjectIcon';
import { QualitatioDialog, QualitatioDropdown } from '../../components';
import QualitatioCommentCard from '../../components/QualitatioCommentCard/QualitatioCommentCard';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioToDoCard from '../../components/QualitatioToDoCard/QualitatioToDoCard';
import { useAuthStore } from '../../store/auth.store';
import CardFeedback from './CardFeedback';
import './TestCard.css';
import CommentsAndChangesDrawer from './changeEventDrawers/CommentsAndChangesDrawer';
import ToDoDrawer from './changeEventDrawers/ToDoDrawer';

export default function TestCard({
  id,
  editable = true,
  title,
  setTitle,
  images,
  setImages,
  testObject,
  setTestObject,
  testMethod,
  setTestMethod,
  testLocation,
  setTestLocation,
  testComment,
  setTestComment,
  orderIdentifier,
  setOrderIdentifier,
  generation,
  explanation,
  index,
  numberCards,
  qualityStation,
  orderIdentifierMapping,
  deleteImage,
  editors,
  creator,
  creationDate,
  defect,
  event,
  restoreChange,
  metaIndex = null,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const [externalImageAPIUsage, setExternalImageAPIUsage] = useState(false);

  const [activeField, setActiveField] = useState(null);
  const [commentCardOpen, setCommentCardOpen] = useState(null);
  const [toDoCardOpen, setToDoCardOpen] = useState(null);

  const card = {
    character: {
      title: title,
      testObject: testObject,
      testLocation: testLocation,
      testMethod: testMethod,
      testComment: testComment,
      explanation: explanation,
      images: images,
      generation: generation,
    },
  };

  const productID = 'Produkt ID';

  const getExternalAPIUsage = async () => {
    try {
      const response = await axiosClient.get(externalAPIUsageEndpoint);
      if (response.data.externalAPIUsage) {
        setExternalImageAPIUsage(response.data.externalAPIUsage.externalImageAPIUsage);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getExternalAPIUsage();
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [testCardOverviewOpen, setTestCardOverviewOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [toDoDrawerOpen, setToDoDrawerOpen] = useState(false);
  const [changeEvents, setChangeEvents] = useState([]);
  const [toDoEvents, setToDoEvents] = useState([]);

  const handleClose = () => {
    setCommentCardOpen(null);
    setToDoCardOpen(null);
    setActiveField(null);
    setComment('');
    setToDo({
      title: '',
      description: '',
      priority: { value: 'low', label: t('low') },
      type: { value: 'task', label: t('task') },
      deadline: dayjs().startOf('day'),
      assignees: [],
      keywords: [],
    });
  };

  const handleNextClick = (imagesLength) => {
    if (editable) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (imagesLength + 1));
    } else {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesLength);
    }
  };

  const handlePreviousClick = (imagesLength) => {
    if (editable) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + (imagesLength + 1)) % (imagesLength + 1)
      );
    } else {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1) % imagesLength);
    }
  };

  const inputRef = useRef(null);

  const { t } = useTranslation();

  // Transform the mapping into the options format
  const defaultDropdownOption = [{ value: 0, label: 0 }];

  // Inside your component
  const dropdownOptions = useMemo(() => {
    return (
      orderIdentifierMapping?.map((item) => ({
        value: item.orderIdentifier,
        label: item.notation,
      })) || defaultDropdownOption
    );
  }, [orderIdentifierMapping]);

  const dropdownValue = useMemo(
    () => ({
      value: Number(orderIdentifier),
      label:
        dropdownOptions.find((option) => option.value === orderIdentifier)?.label ||
        orderIdentifier,
    }),
    [orderIdentifier, dropdownOptions]
  );

  useEffect(() => {
    if (editable && images && images.length > 0) {
      setCurrentImageIndex(images.length);
    } else {
      // In non-editable mode, start with the first image (or placeholder if no images)
      setCurrentImageIndex(0);
    }
  }, [images, editable]);

  const [comment, setComment] = useState('');

  const [numberOfComments, setNumberOfComments] = useState({
    title: 0,
    testObject: 0,
    testLocation: 0,
    testMethod: 0,
    testComment: 0,
    orderIdentifier: 0,
  });
  const [numberOfToDos, setNumberOfToDos] = useState(0);

  const handlePostNewComment = async () => {
    try {
      const response = await axiosClient.post(cardCommentEndpoint, {
        id: id,
        comment: comment,
        field: activeField,
        creator: user.id,
        created: Date.now(),
      });
      setNumberOfComments((prev) => ({ ...prev, [activeField]: response.data.numberOfComments }));
      enqueueSnackbar(t('commentSavedSuccessfully'), { variant: 'success' });
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('commentCouldNotBeSaved'), { variant: 'error' });
    }
  };

  const getNumberOfCommentsAndToDos = async () => {
    try {
      const response = await axiosClient.get(getNumberOfCommentsAndToDosEndpoint, {
        headers: {
          id: id,
          userid: user.id,
        },
      });
      setNumberOfComments(response.data.numberOfComments);
      setNumberOfToDos(response.data.numberOfToDos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getChangeEvents = async (type) => {
    try {
      const response = await axiosClient.get(getChangeEventsEndpoint, {
        headers: {
          id: id,
        },
      });
      setChangeEvents(response.data.changeEvents.filter((event) => !event.type.startsWith('toDo')));
      setToDoEvents(response.data.changeEvents.filter((event) => event.type.startsWith('toDo')));
      if (type === 'toDo') {
        setToDoDrawerOpen(true);
      } else if (type === 'history') {
        setHistoryOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (id) {
      getNumberOfCommentsAndToDos();
    } else {
      setNumberOfComments({
        title: 0,
        testObject: 0,
        testLocation: 0,
        testMethod: 0,
        testComment: 0,
        orderIdentifier: 0,
      });
      setNumberOfToDos(0);
    }
  }, [index, metaIndex, changeEvents, toDoEvents]);

  const generateCardImage = async () => {
    const snackbarKey = enqueueSnackbar(t('generatingImage'), { variant: 'info', persist: true });
    try {
      const response = await axiosClient.post(generateCardImageEndpoint, {
        qualityStation: qualityStation,
        title: title,
        testObject: testObject,
        defect: defect,
        event: event,
      });
      setImages(response.data.image);
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('imageGeneratedSuccessfully'), { variant: 'success' });
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenGeneratingImage'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [toDo, setToDo] = useState({
    title: '',
    description: '',
    priority: { value: 'low', label: t('low') },
    type: { value: 'task', label: t('task') },
    deadline: dayjs().startOf('day'),
    assignees: [],
    keywords: [],
  });

  const handleOpenToDo = async () => {
    if (!toDo.title) {
      enqueueSnackbar(t('pleaseEnterTitle'), { variant: 'warning' });
      return;
    }
    if (!toDo.description) {
      enqueueSnackbar(t('pleaseEnterDescription'), { variant: 'warning' });
      return;
    }
    if (!toDo.priority) {
      enqueueSnackbar(t('pleaseSelectPriority'), { variant: 'warning' });
      return;
    }
    if (!toDo.type) {
      enqueueSnackbar(t('pleaseSelectType'), { variant: 'warning' });
      return;
    }
    if (!toDo.deadline) {
      enqueueSnackbar(t('pleaseSelectDeadline'), { variant: 'warning' });
      return;
    }
    if (toDo.assignees.length === 0) {
      enqueueSnackbar(t('pleaseSelectAssignees'), { variant: 'warning' });
      return;
    }
    try {
      const response = await axiosClient.post(toDoEndpoint, {
        cardID: id,
        userID: user.id,
        toDo: {
          ...toDo,
          field: activeField,
          assignees: toDo.assignees.map((assignee) => assignee.value),
          priority: toDo.priority.value,
          type: toDo.type.value,
        },
      });
      if (response.data.numberOfToDos) {
        setNumberOfToDos(response.data.numberOfToDos);
        handleClose();
        setToDo({});
        enqueueSnackbar(t('toDoSavedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('errorWhenSavingToDo'), { variant: 'error' });
    }
  };

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        style={{
          maxWidth: '325px',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '15px',
        }}
      >
        <Grid container style={{ padding: '5px', gap: '5px' }}>
          <Grid item xs={12}>
            <Paper
              style={{
                width: '100%',
                padding: '10px',
                display: 'flex',
                backgroundColor: editable ? theme.palette.primary.main : theme.palette.gray.main,
                borderRadius: '15px 15px 5px 5px',
                boxShadow: '0px 6.63px 13.25px #1018280d',
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  padding: '0px 4px',
                }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'left' }}>
                  {productID}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'center' }}>
                  {index + 1}/{numberCards}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  padding: '0px 4px',
                }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'right' }}>
                  {qualityStation}
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2vh',
                minHeight: '175px',
                width: '100%',
                padding: '10px',
                backgroundColor:
                  editable && currentImageIndex === 0 && theme.palette.success.secondary,
                boxShadow: '0px 6.63px 13.25px #1018280d',
                overflow: !editable && currentImageIndex !== 0 && 'hidden',
                position: 'relative',
              }}
            >
              {(!editable || currentImageIndex !== 0) && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${
                      card?.character?.images && card?.character?.images.length > 0
                        ? card.character.images[currentImageIndex - (editable ? 1 : 0)]
                            ?.split('?')[0]
                            .split('/')
                            .map((part, index) => (index <= 2 ? part : encodeURIComponent(part)))
                            .join('/')
                        : '/images/placeholderImage.png'
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '175px',
                  }}
                ></div>
              )}
              <Grid
                item
                xs={12}
                container
                spacing={2}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '175px',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    style={{
                      backgroundColor:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? '#1fbebd'
                          : '#0b2034',
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => setTestCardOverviewOpen(true)}
                  >
                    <StarBorderIcon
                      style={{
                        color:
                          card.character?.generation === t('Basis') ||
                          card.character?.generation === t('Rule')
                            ? 'white'
                            : '#1fbebd',
                      }}
                    />
                  </IconButton>
                  <QualitatioDialog
                    title={t('testCardOverview')}
                    description={t(
                      'inTheFollowingYouCanReviewTheTestCardPerformanceAsWellAsTheTestCardUserFeedback'
                    )}
                    open={testCardOverviewOpen}
                    onClose={() => setTestCardOverviewOpen(false)}
                    maxWidth="md"
                    actions={[
                      {
                        label: t('alright'),
                        onClick: () => {
                          setTestCardOverviewOpen(false);
                        },
                        order: 'primary',
                      },
                    ]}
                  >
                    {testCardOverviewOpen && <CardFeedback cardID={id} />}
                  </QualitatioDialog>
                  {editable && currentImageIndex !== 0 && (
                    <IconButton
                      style={{
                        backgroundColor: theme.palette.error.main,
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => deleteImage(currentImageIndex - 1)}
                    >
                      <DeleteIcon color={'#1fbebd'} />
                    </IconButton>
                  )}
                  <Chip
                    label={card.character?.generation}
                    style={{
                      zIndex: 1,
                      backgroundColor:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? '#1fbebd'
                          : '#0b2034',
                      color:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? 'white'
                          : '#1fbebd',
                    }}
                  />
                </Grid>
                {editable && currentImageIndex === 0 && (
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    gap={'10px'}
                    marginTop="10px"
                    style={{ paddingLeft: '16px' }}
                  >
                    <Grid
                      item
                      spacing={1}
                      display="flex"
                      direction="column"
                      alignItems="center"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <CameraAltIcon />
                      <Typography variant="body3" color="text.secondary">
                        {t('uploadImage')}
                      </Typography>
                      <input
                        ref={inputRef}
                        hidden
                        id="img"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setImages(e.target.files[0]);
                        }}
                        multiple
                      />
                    </Grid>
                    {
                      <Grid
                        item
                        spacing={1}
                        display="flex"
                        direction="column"
                        alignItems="center"
                        onClick={() => {
                          generateCardImage();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FaRobot size="24px" />
                        <Typography variant="body3" color="text.secondary">
                          {t('generateImage')}
                        </Typography>
                      </Grid>
                    }
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MobileStepper
                    variant="dots"
                    steps={
                      (editable
                        ? card.character.images?.length + 1
                        : card.character.images?.length) || 1
                    }
                    position="static"
                    activeStep={currentImageIndex}
                    sx={{
                      width: '100%',
                      flexGrow: 1,
                      backgroundColor: theme.palette.success.secondary,
                      '& .MuiMobileStepper-dots': {
                        zIndex: 1,
                      },
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={() => handleNextClick(card.character.images?.length)}
                        disabled={
                          editable
                            ? currentImageIndex >= card.character.images?.length
                            : currentImageIndex >= card.character.images?.length - 1
                        }
                      >
                        {t('next')}
                        <KeyboardArrowRightIcon />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={() => handlePreviousClick(card.character.images?.length)}
                        disabled={currentImageIndex === 0}
                      >
                        <KeyboardArrowLeftIcon />
                        {t('back')}
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
              {id && editable && (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{ position: 'absolute', right: '-50px', minHeight: '150px' }}
                >
                  {/* <IconButton
                    variant="qualitatio"
                    squared={true}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => {
                      enqueueSnackbar(`${t('thisFeatureWillBeAvailableSoon')}...`, {
                        variant: 'info',
                      });
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton> */}
                  <Badge
                    badgeContent={numberOfToDos}
                    color="error"
                    invisible={numberOfToDos === 0}
                    style={{ flexGrow: 1 }}
                  >
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => getChangeEvents('toDo')}
                    >
                      <ErrorOutlineIcon />
                    </IconButton>
                  </Badge>
                  <IconButton
                    variant="qualitatio"
                    squared={true}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => getChangeEvents('history')}
                  >
                    <HistoryIcon />
                  </IconButton>
                  <CommentsAndChangesDrawer
                    open={historyOpen}
                    onClose={() => setHistoryOpen(false)}
                    cardTitle={title}
                    editors={editors}
                    creator={creator}
                    creationDate={creationDate}
                    changeEvents={changeEvents}
                    id={id}
                    setChangeEvents={setChangeEvents}
                    orderIdentifierMapping={orderIdentifierMapping}
                    restoreChange={restoreChange}
                  />
                  <ToDoDrawer
                    open={toDoDrawerOpen}
                    onClose={() => setToDoDrawerOpen(false)}
                    cardTitle={title}
                    editors={editors}
                    creator={creator}
                    creationDate={creationDate}
                    toDoEvents={toDoEvents}
                    id={id}
                    setToDoEvents={setToDoEvents}
                    orderIdentifierMapping={orderIdentifierMapping}
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
          <ClickAwayListener onClickAway={handleClose}>
            <Grid item xs={12} display="flex" flexDirection="column" gap="3px">
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative',
                }}
              >
                <LabelIcon fill="black" />
                <Badge
                  badgeContent={numberOfComments.title}
                  color="primary"
                  invisible={numberOfComments.title === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioInput
                    label={t('title')}
                    value={card.character?.title || ''}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ width: '100%' }}
                    compact={true}
                    readOnly={!editable}
                    onFocus={() => setActiveField('title')}
                    inputPropsWidth={'100%'}
                  />
                </Badge>
                {id && editable && activeField === 'title' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'title'}
                    />
                    <QualitatioToDoCard
                      field={'title'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>

              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative', // Added for relative positioning of the comment icon
                }}
              >
                <TestObjectIcon fill="black" />
                <Badge
                  badgeContent={numberOfComments.testObject}
                  color="primary"
                  invisible={numberOfComments.testObject === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioInput
                    label={t('testObject')}
                    value={card.character?.testObject || ''}
                    onChange={(e) => setTestObject(e.target.value)}
                    width="100%"
                    compact={true}
                    readOnly={!editable}
                    onFocus={() => setActiveField('testObject')}
                    inputPropsWidth={'100%'}
                  />
                </Badge>
                {id && editable && activeField === 'testObject' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'testObject'}
                    />
                    <QualitatioToDoCard
                      field={'testObject'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative', // Added for relative positioning of the comment icon
                }}
              >
                <TestLocationIcon fill="black" />
                <Badge
                  badgeContent={numberOfComments.testLocation}
                  color="primary"
                  invisible={numberOfComments.testLocation === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioInput
                    label={t('testLocation')}
                    value={card.character?.testLocation || ''}
                    onChange={(e) => setTestLocation(e.target.value)}
                    width="100%"
                    compact={true}
                    readOnly={!editable}
                    onFocus={() => setActiveField('testLocation')}
                    inputPropsWidth={'100%'}
                  />
                </Badge>
                {id && editable && activeField === 'testLocation' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'testLocation'}
                    />
                    <QualitatioToDoCard
                      field={'testLocation'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative', // Added for relative positioning of the comment icon
                }}
              >
                <TestMethodIcon fill="black" />
                <Badge
                  badgeContent={numberOfComments.testMethod}
                  color="primary"
                  invisible={numberOfComments.testMethod === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioInput
                    label={t('testMethod')}
                    value={card.character?.testMethod || ''}
                    onChange={(e) => setTestMethod(e.target.value)}
                    width="100%"
                    compact={true}
                    readOnly={!editable}
                    onFocus={() => setActiveField('testMethod')}
                    inputPropsWidth={'100%'}
                  />
                </Badge>
                {id && editable && activeField === 'testMethod' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'testMethod'}
                    />
                    <QualitatioToDoCard
                      field={'testMethod'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative', // Added for relative positioning of the comment icon
                }}
              >
                <TestCommentIcon fill="black" />
                <Badge
                  badgeContent={numberOfComments.testComment}
                  color="primary"
                  invisible={numberOfComments.testComment === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioInput
                    label={t('testComment')}
                    value={card.character?.testComment || ''}
                    onChange={(e) => setTestComment(e.target.value)}
                    width="100%"
                    compact={true}
                    readOnly={!editable}
                    onFocus={() => setActiveField('testComment')}
                    inputPropsWidth={'100%'}
                  />
                </Badge>
                {id && editable && activeField === 'testComment' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'testComment'}
                    />
                    <QualitatioToDoCard
                      field={'testComment'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative', // Added for relative positioning of the comment icon
                }}
              >
                <PiSortDescending style={{ height: '24px', width: '24px' }} />
                <Badge
                  badgeContent={numberOfComments.orderIdentifier}
                  color="primary"
                  invisible={numberOfComments.orderIdentifier === 0 || !editable}
                  style={{ flexGrow: 1 }}
                >
                  <QualitatioDropdown
                    label={t('orderIdentifier')}
                    options={dropdownOptions}
                    compact={true}
                    value={dropdownValue}
                    size="lg"
                    onChange={(event, newValue) => setOrderIdentifier(Number(newValue?.value) || 0)}
                    width={'100%'}
                    readOnly={!editable}
                    onFocus={() => setActiveField('orderIdentifier')}
                  />
                </Badge>
                {id && editable && activeField === 'orderIdentifier' && (
                  <>
                    <QualitatioCommentCard
                      onSave={handlePostNewComment}
                      comment={comment}
                      setComment={setComment}
                      onCancel={handleClose}
                      onOpenCard={setCommentCardOpen}
                      openCard={commentCardOpen}
                      field={'orderIdentifier'}
                    />
                    <QualitatioToDoCard
                      field={'orderIdentifier'}
                      onCancel={handleClose}
                      openCard={toDoCardOpen}
                      onOpenCard={setToDoCardOpen}
                      toDo={toDo}
                      setToDo={setToDo}
                      onSave={handleOpenToDo}
                    />
                  </>
                )}
              </Paper>
            </Grid>
          </ClickAwayListener>
          <Grid item xs={12}>
            <Paper
              style={{
                width: '100%',
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: editable ? theme.palette.primary.main : theme.palette.gray.main,
                borderRadius: '5px 5px 15px 15px',
                boxShadow: '0px 6.63px 13.25px #1018280d',
              }}
            >
              <Typography variant="body3" color="text.secondary" textAlign="center">
                {card.character?.explanation}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
