import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CopilotButton from '../components/CopilotButton';
import styles from './EventTests.module.css';

const CardControls = ({
  events = [],
  currentIndex,
  addCard,
  filteredCards = [],
  currentCardIndex,
  setDeleteCardWarningOpen,
  handleSave,
  updateCurrentCardIndex,
  setCurrentCardIndex,
  eventDataWithId = [],
  qualityStation,
  setEventTests,
  eventTests,
  explanation,
  selectedLiveStreamData,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={styles.testCardControls}>
      <div className={styles.navigationControls}>
        {filteredCards && filteredCards.length > 0 && (
          <IconButton
            className={styles.testCardControlElement}
            variant="qualitatio"
            squared={true}
            onClick={() => updateCurrentCardIndex('dec')}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <ArrowBackIosNewRoundedIcon color="primary" />
          </IconButton>
        )}
        {filteredCards &&
          filteredCards.map((test, index) => (
            <div key={index} className={styles.testCardControlElement}>
              <svg
                key={index}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.basisNavButtons}
                onClick={() => setCurrentCardIndex(index)}
              >
                <circle
                  cx="10"
                  cy="10"
                  r="9.75"
                  fill={
                    index === currentCardIndex
                      ? 'var(--m-3refprimaryprimary-60)'
                      : 'var(--m3syslightsecondary-fixed)'
                  }
                  stroke="#0E0D13"
                  strokeWidth="0.5"
                />
              </svg>
            </div>
          ))}
        {filteredCards && filteredCards.length > 0 && (
          <IconButton
            className={styles.testCardControlElement}
            variant="qualitatio"
            squared={true}
            onClick={() => updateCurrentCardIndex('inc')}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <ArrowForwardIosRoundedIcon color="primary" />
          </IconButton>
        )}
      </div>
      <div className={styles.manipulationControls}>
        {filteredCards && filteredCards.length > 0 && (
          <Tooltip title={t('deleteTestCardTooltip')}>
            <IconButton
              className={styles.testCardControlElement}
              variant="qualitatio"
              squared={true}
              disabled={!filteredCards[currentCardIndex]?.isExactMatch}
              onClick={() => setDeleteCardWarningOpen(true)}
              style={{
                backgroundColor: theme.palette.error.main,
                width: '48px',
                height: '48px',
              }}
            >
              <DeleteForeverRoundedIcon color="white" fontSize="large" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('addTestCardTooltip')}>
          <IconButton
            className={styles.testCardControlElement}
            variant="qualitatio"
            squared={true}
            disabled={!events || !events[currentIndex]}
            onClick={() => addCard()}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '48px',
              height: '48px',
            }}
          >
            <AddRoundedIcon color="primary" fontSize="large" />
          </IconButton>
        </Tooltip>
        <CopilotButton
          defectDefinition={
            eventDataWithId &&
            eventDataWithId[currentIndex] &&
            selectedLiveStreamData[0] &&
            selectedLiveStreamData[0].filterFieldNames &&
            Object.fromEntries(
              Object.entries(eventDataWithId[currentIndex]).filter(
                ([key]) => !selectedLiveStreamData[0].filterFieldNames.includes(key)
              )
            )
          }
          qualityStation={qualityStation}
          allCards={eventTests}
          setAllCards={setEventTests}
          filteredCardsByEvent={filteredCards}
          setCurrentCardIndex={setCurrentCardIndex}
          explanation={explanation}
          cardType="dynamic"
          disabled={!events || !events[currentIndex]}
        />
        {filteredCards && filteredCards.length > 0 && (
          <Tooltip title={t('saveTestCardsTooltip')}>
            <IconButton
              className={styles.testCardControlElement}
              variant="qualitatio"
              squared={true}
              disabled={!filteredCards[currentCardIndex]?.isExactMatch}
              onClick={() => handleSave()}
              style={{
                backgroundColor: theme.palette.primary.main,
                width: '48px',
                height: '48px',
              }}
            >
              <SaveIcon color="white" fontSize="large" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

CardControls.propTypes = {
  events: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  addCard: PropTypes.func.isRequired,
  filteredCards: PropTypes.arrayOf(
    PropTypes.shape({
      isExactMatch: PropTypes.bool.isRequired,
    })
  ).isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  updateCurrentCardIndex: PropTypes.func.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  eventDataWithId: PropTypes.array.isRequired,
  qualityStation: PropTypes.string.isRequired,
  setEventTests: PropTypes.func.isRequired,
  eventTests: PropTypes.array.isRequired,
  explanation: PropTypes.string.isRequired,
  selectedLiveStreamData: PropTypes.array.isRequired,
};

export default CardControls;
