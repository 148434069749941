import AddCommentIcon from '@mui/icons-material/AddComment';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRobot } from 'react-icons/fa6';
import axiosClient from '../../api/axiosClient';
import { externalAPIUsageEndpoint, generateCardImageEndpoint } from '../../api/endpoints';
import LabelIcon from '../../assets/icons/LabelIcon';
import { QualitatioDialog } from '../../components';
import QualitatioCommentCard from '../../components/QualitatioCommentCard/QualitatioCommentCard';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import CardFeedback from './CardFeedback';
import './TestCard.css';

const TestCardVisual = ({
  id,
  editable = true,
  title,
  setTitle,
  images,
  setImages,
  generation,
  explanation,
  index,
  numberCards,
  qualityStation,
  deleteImage,
  defect,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const [externalImageAPIUsage, setExternalImageAPIUsage] = useState(false);

  const [commentCardOpen, setCommentCardOpen] = useState(false);

  const [commentIconOpen, setCommentIconOpen] = useState(false);

  const card = {
    character: {
      title: title,
      explanation: explanation,
      images: images,
      generation: generation,
    },
  };

  const productID = 'Produkt ID';

  const getExternalAPIUsage = async () => {
    try {
      const response = await axiosClient.get(externalAPIUsageEndpoint);
      if (response.data.externalAPIUsage) {
        setExternalImageAPIUsage(response.data.externalAPIUsage.externalImageAPIUsage);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getExternalAPIUsage();
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [testCardOverviewOpen, setTestCardOverviewOpen] = useState(false);

  const handleFocus = (field) => {
    // Close all comment cards except the one that was clicked
    setCommentIconOpen(true);
    setCommentCardOpen(false);
  };

  const handleCommentCardOpen = (field) => {
    // Close all comment cards except the one that was clicked
    setCommentCardOpen((prev) => !prev);
  };

  const handleNextClick = (imagesLength) => {
    if (editable) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (imagesLength + 1));
    } else {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesLength);
    }
  };

  const handlePreviousClick = (imagesLength) => {
    if (editable) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + (imagesLength + 1)) % (imagesLength + 1),
      );
    } else {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1) % imagesLength);
    }
  };

  const inputRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (editable && images && images.length > 0) {
      setCurrentImageIndex(images.length);
    } else {
      // In non-editable mode, start with the first image (or placeholder if no images)
      setCurrentImageIndex(0);
    }
  }, [images, editable]);

  const generateCardImage = async () => {
    const snackbarKey = enqueueSnackbar(t('generatingImage'), { variant: 'info', persist: true });
    try {
      const response = await axiosClient.post(generateCardImageEndpoint, {
        qualityStation: qualityStation,
        title: title,
        testObject: title,
        defect: defect,
      });
      setImages(response.data.image);
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('imageGeneratedSuccessfully'), { variant: 'success' });
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenGeneratingImage'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        style={{
          maxWidth: '325px',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '15px',
        }}
      >
        <Grid container style={{ padding: '5px', gap: '5px' }}>
          <Grid item xs={12}>
            <Paper
              style={{
                width: '100%',
                padding: '10px',
                display: 'flex',
                backgroundColor: editable ? theme.palette.primary.main : theme.palette.gray.main,
                borderRadius: '15px 15px 5px 5px',
                boxShadow: '0px 6.63px 13.25px #1018280d',
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  padding: '0px 4px',
                }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'left' }}>
                  {productID}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'center' }}>
                  {index + 1}/{numberCards}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  padding: '0px 4px',
                }}
              >
                <Typography variant="body3" color="text.secondary" style={{ textAlign: 'right' }}>
                  {qualityStation}
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2vh',
                minHeight: '434px',
                width: '100%',
                padding: '10px',
                backgroundColor:
                  editable && currentImageIndex === 0 && theme.palette.success.secondary,
                boxShadow: '0px 6.63px 13.25px #1018280d',
                overflow: !editable && currentImageIndex !== 0 && 'hidden',
                position: 'relative',
              }}
            >
              {(!editable || currentImageIndex !== 0) && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${
                      card?.character?.images && card?.character?.images.length > 0
                        ? card.character.images[currentImageIndex - (editable ? 1 : 0)]
                            ?.split('?')[0]
                            .split('/')
                            .map((part, index) => (index <= 2 ? part : encodeURIComponent(part)))
                            .join('/')
                        : '/images/placeholderImage.png'
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '434px',
                  }}
                ></div>
              )}
              <Grid
                item
                xs={12}
                container
                spacing={2}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '434px',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    style={{
                      backgroundColor:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? '#1fbebd'
                          : '#0b2034',
                      height: '32px',
                      width: '32px',
                    }}
                    onClick={() => setTestCardOverviewOpen(true)}
                  >
                    <StarBorderIcon
                      style={{
                        color:
                          card.character?.generation === t('Basis') ||
                          card.character?.generation === t('Rule')
                            ? 'white'
                            : '#1fbebd',
                      }}
                    />
                  </IconButton>
                  <QualitatioDialog
                    title={t('testCardOverview')}
                    description={t(
                      'inTheFollowingYouCanReviewTheTestCardPerformanceAsWellAsTheTestCardUserFeedback',
                    )}
                    open={testCardOverviewOpen}
                    onClose={() => setTestCardOverviewOpen(false)}
                    maxWidth="md"
                    actions={[
                      {
                        label: t('alright'),
                        onClick: () => {
                          setTestCardOverviewOpen(false);
                        },
                        order: 'primary',
                      },
                    ]}
                  >
                    {testCardOverviewOpen && <CardFeedback cardID={id} />}
                  </QualitatioDialog>
                  {editable && currentImageIndex !== 0 && (
                    <IconButton
                      style={{
                        backgroundColor: theme.palette.error.main,
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => deleteImage(currentImageIndex - 1)}
                    >
                      <DeleteIcon color={'#1fbebd'} />
                    </IconButton>
                  )}
                  <Chip
                    label={card.character?.generation}
                    style={{
                      zIndex: 1,
                      backgroundColor:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? '#1fbebd'
                          : '#0b2034',
                      color:
                        card.character?.generation === t('Basis') ||
                        card.character?.generation === t('Rule')
                          ? 'white'
                          : '#1fbebd',
                    }}
                  />
                </Grid>
                {editable && currentImageIndex === 0 && (
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    gap={'30%'}
                    style={{ paddingLeft: '16px' }}
                  >
                    <Grid
                      item
                      xs={12}
                      spacing={1}
                      display="flex"
                      direction="column"
                      alignItems="center"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <CameraAltIcon />
                      <Typography variant="body3" color="text.secondary">
                        {t('uploadImage')}
                      </Typography>
                      <input
                        ref={inputRef}
                        hidden
                        id="img"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setImages(e.target.files[0]);
                        }}
                        multiple
                      />
                    </Grid>
                    {externalImageAPIUsage && (
                      <Grid
                        item
                        spacing={1}
                        display="flex"
                        direction="column"
                        alignItems="center"
                        onClick={() => {
                          generateCardImage();
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FaRobot size="24px" />
                        <Typography variant="body3" color="text.secondary">
                          {t('generateImage')}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MobileStepper
                    variant="dots"
                    steps={
                      editable ? card.character.images?.length + 1 : card.character.images?.length
                    }
                    position="static"
                    activeStep={currentImageIndex}
                    sx={{
                      width: '100%',
                      flexGrow: 1,
                      backgroundColor: theme.palette.success.secondary,
                      '& .MuiMobileStepper-dots': {
                        zIndex: 1,
                      },
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={() => handleNextClick(card.character.images?.length)}
                        disabled={
                          editable
                            ? currentImageIndex >= card.character.images?.length
                            : currentImageIndex >= card.character.images?.length - 1
                        }
                      >
                        {t('next')}
                        <KeyboardArrowRightIcon />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={() => handlePreviousClick(card.character.images?.length)}
                        disabled={currentImageIndex === 0}
                      >
                        <KeyboardArrowLeftIcon />
                        {t('back')}
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <ClickAwayListener
            onClickAway={() => {
              setCommentIconOpen(false);
              setCommentCardOpen(false);
            }}
          >
            <Grid item xs={12} display="flex" flexDirection="column" gap="3px">
              <Paper
                square={false}
                style={{
                  width: '100%',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1vw',
                  alignItems: 'center',
                  boxShadow: '0px 6.63px 13.25px #1018280d',
                  position: 'relative',
                }}
              >
                <LabelIcon fill="black" />
                <QualitatioInput
                  label={t('title')}
                  value={card.character?.title || ''}
                  onChange={(e) => setTitle(e.target.value)}
                  width={'100%'}
                  compact={true}
                  readOnly={!editable}
                  onFocus={(e) => handleFocus('title')}
                />
                {commentIconOpen && (
                  <AddCommentIcon
                    style={{
                      position: 'absolute',
                      right: '-50px',
                      height: '24px',
                      width: '24px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCommentCardOpen('title')}
                  />
                )}
                {commentCardOpen && (
                  <QualitatioCommentCard
                    top={'40px'}
                    right={'-320px'}
                    onSave={() => {}}
                    onCancel={() => setCommentCardOpen(false)}
                  />
                )}
              </Paper>
            </Grid>
          </ClickAwayListener>
          <Grid item xs={12}>
            <Paper
              style={{
                width: '100%',
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: editable ? theme.palette.primary.main : theme.palette.gray.main,
                borderRadius: '5px 5px 15px 15px',
                boxShadow: '0px 6.63px 13.25px #1018280d',
              }}
            >
              <Typography variant="body3" color="text.secondary" textAlign="center">
                {card.character?.explanation}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TestCardVisual;
