import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Checkbox, IconButton, Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineBarChart, AiOutlineDollar, AiOutlineFieldTime } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import {
  aiCardEndpoint,
  cardLayoutEndpoint,
  defectDescriptionFieldsEndpoint,
  defectMappingEndpoint,
  eventCardEndpoint,
  externalAPIUsageEndpoint,
  generateCardEndpoint,
  getCardsByIDsEndpoint,
  ruleCardEndpoint,
  staticCardEndpoint,
} from '../../../api/endpoints';
import { QualitatioButton, QualitatioDialog } from '../../../components';
import QualitatioTable from '../../../components/QualitatioTable/QualitatioTable';
import { useAuthStore } from '../../../store/auth.store';
import TestCard from '../TestCard';
import { uploadCardImageToServer } from '../utils';
import './Copilot.css';
import CardSelectionModal from './components/CardSelectionModal';
import CopilotButton from './components/CopilotButton';
import NewAIModal from './components/NewAIModal';
import NewEventModal from './components/NewEventModal';
import NewRuleModal from './components/NewRuleModal';

// Warum bin ich hier? Gibt es noch mehr wie mich?
// Was machen die Parameter?
const Copilot = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  setTestLogicIndex,
  defectMapping,
  setDefectMapping,
  qualityStation,
  orderIdentifierMapping,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [cardLayout, setCardLayout] = useState(false);
  const [externalTextAPIUsage, setExternalTextAPIUsage] = useState(false);

  const [showOnlyUnmappedDefects, setShowOnlyUnmappedDefects] = useState(false);
  const [defectRows, setDefectRows] = useState([]);
  const [filteredDefectRows, setFilteredDefectRows] = useState([]);
  const currentDefect = defectRows.find((defect) => defect.id === currentIndex);
  const [defectDescriptionFields, setDefectDescriptionFields] = useState([]);
  const [cards, setCards] = useState([]);
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [defectColumns, setDefectColumns] = useState([]);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const getDefectRows = async () => {
    const parsedDefects = defectMapping.map((defect) => ({
      id: defect.id,
      ...JSON.parse(defect.defect),
      cost: defect.cost,
      frequency: defect.frequency,
      lastOccurrence: defect.lastOccurrence,
    }));
    setDefectRows(parsedDefects);
  };

  const getDefectDescriptionFields = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionFieldsEndpoint);
      setDefectDescriptionFields(response.data.defectDescriptionFields);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getCardLayout = async () => {
    try {
      const response = await axiosClient.get(cardLayoutEndpoint);
      setCardLayout(response.data.layout);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingCardLayout'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const getExternalAPIUsage = async () => {
    try {
      const response = await axiosClient.get(externalAPIUsageEndpoint);
      if (response.data.externalAPIUsage) {
        setExternalTextAPIUsage(response.data.externalAPIUsage.externalTextAPIUsage);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getDefectRows();
  }, [defectMapping]);

  useEffect(() => {
    getDefectDescriptionFields();
    getCardLayout();
    getExternalAPIUsage();
  }, []);

  useEffect(() => {
    const newDefectColumns = (defectDescriptionFields ?? []).map((field) => ({
      field: field,
      headerName: field,
      flex: 1,
    }));
    if ((defectRows ?? []).some((defect) => defect.cost)) {
      newDefectColumns?.push({
        field: 'cost',
        headerName: t('cost'),
        flex: 1,
        renderCell: (params) => {
          return params.value ? (
            <Chip
              label={params.value}
              icon={<AiOutlineDollar />}
              sx={{ backgroundColor: theme.palette.error.secondary }}
            />
          ) : (
            ''
          );
        },
      });
    }
    if ((defectRows ?? []).some((defect) => defect.frequency)) {
      newDefectColumns.push({
        field: 'frequency',
        headerName: t('frequency'),
        flex: 1,
        renderCell: (params) => {
          return params.value ? (
            <Chip
              label={params.value}
              icon={<AiOutlineBarChart />}
              sx={{ backgroundColor: theme.palette.warning.main }}
            />
          ) : (
            ''
          );
        },
      });
    }
    if ((defectRows ?? []).some((defect) => defect.lastOccurrence)) {
      newDefectColumns.push({
        field: 'lastOccurrence',
        headerName: t('lastOccurrence'),
        flex: 1,
        renderCell: (params) => {
          return params.value ? (
            <Chip
              label={new Date(params.value).toLocaleString()}
              icon={<AiOutlineFieldTime size={'24px'} />}
              sx={{ backgroundColor: theme.palette.gray.main }}
            />
          ) : (
            ''
          );
        },
      });
    }
    setDefectColumns(newDefectColumns);
  }, [defectDescriptionFields, defectRows]);

  useEffect(() => {
    getCards();
  }, [currentIndex, defectMapping]);

  const getCards = async () => {
    if (currentIndex !== -1) {
      const defect = defectMapping.find((defect) => defect.id === currentIndex);
      if (defect && defect.tests) {
        const cards = await getCardsByIDs(defect.tests);
        setCards(cards);
      } else {
        setCards([]);
      }
    }
  };

  const getCardsByIDs = async (cardIDs) => {
    try {
      if (cardIDs?.length >= 0) {
        const response = await axiosClient.get(getCardsByIDsEndpoint, {
          headers: {
            cardIDs: cardIDs,
          },
        });
        return response.data.cards;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handle generation, mapping, and removal of test card and defects
  const mapCard = () => {
    setCardSelectionModalOpen(true);
  };

  const generateCard = async () => {
    const snackbarKey = enqueueSnackbar(t('testCardGenerationStarted'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.post(generateCardEndpoint, {
        qualityStation: qualityStation,
        defect: defectRows.find((defect) => defect.id === currentIndex),
        creator: user.id,
      });
      closeSnackbar(snackbarKey);
      if (response.data.card) {
        setCards([...cards, response.data.card]);
        setCurrentCardIndex([...cards, response.data.card].length - 1);
        enqueueSnackbar(t('testCardGeneratedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenGeneratingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  // This defect cannot be detected in the current quality station
  const removeDefect = async () => {
    await updateDefectMapping();
    enqueueSnackbar(t('defectRemovedSuccessfully'), { variant: 'success' });
  };

  // Handle generated card saving
  const saveGeneratedCard = async () => {
    // Open the dialog
    setDialogOpen(true);
  };

  const navigateToTestCard = () => {
    const testLogic =
      cards[currentCardIndex]?.type === 'Basis'
        ? 0
        : cards[currentCardIndex]?.type === 'Rule'
          ? 1
          : cards[currentCardIndex]?.type === 'Dynamic'
            ? 2
            : 3;

    const url = `/intelligent_testing/configurator?step=3&qualityStation=${encodeURIComponent(
      cards[currentCardIndex]?.qualityStation
    )}&testLogic=${encodeURIComponent(testLogic)}&id=${encodeURIComponent(
      cards[currentCardIndex]?.id
    )}`;
    navigate(url);
    window.location.reload();
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const dialogOptions = [
    t('staticTestProposal'),
    t('ruleBasedTestProposal'),
    t('eventBasedTestProposal'),
    t('aiBasedTestProposal'),
  ];

  const testLogicSelected = async (index) => {
    if (index === 0) {
      await saveGeneratedCardStatic();
    } else if (index === 1) {
      await handleNewRule();
    } else if (index === 2) {
      await handleNewEvent();
    } else if (index === 3) {
      await saveGeneratedCardAI();
    }
  };

  const updateDefectMapping = async (cardID) => {
    // Update the defect mapping
    const response = await axiosClient.post(defectMappingEndpoint, {
      defectID: currentDefect.id,
      qualityStation: qualityStation,
      cardID: cardID,
      irrelevantQualityStation: cardID ? null : qualityStation,
    });
    if (response.data.defectMapping) {
      setDefectMapping(response.data.defectMapping);
      enqueueSnackbar(t('defectMappingUpdatedSuccessfully'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('errorWhenUpdatingDefectMapping'), { variant: 'error' });
    }
  };

  const removeCardFromDefectMapping = async () => {
    // Update the defect mapping
    const response = await axiosClient.delete(defectMappingEndpoint, {
      data: {
        defectID: currentDefect.id,
        qualityStation: qualityStation,
        cardID: cards[currentCardIndex].id,
      },
    });
    if (response.data.defectMapping) {
      setDefectMapping(response.data.defectMapping);
      setCurrentCardIndex(currentCardIndex - 1 >= 0 ? currentCardIndex - 1 : 0);
      enqueueSnackbar(t('defectMappingRemovedSuccessfully'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('errorWhenUpdatingDefectMapping'), { variant: 'error' });
    }
  };

  // General card handling
  const [isCardSelectionModalOpen, setCardSelectionModalOpen] = useState(false);
  const cardSelected = async (selectedCard) => {
    await updateDefectMapping(selectedCard._id);
    setCardSelectionModalOpen(false);
    enqueueSnackbar(t('testCardMappedSuccessfully'), { variant: 'success' });
  };

  // Static card handling
  const saveGeneratedCardStatic = async () => {
    try {
      const snackbarKey = enqueueSnackbar(t('testCardSavingStarted'), {
        variant: 'info',
        persist: true,
      });
      const response = await axiosClient.post(staticCardEndpoint, {
        qualityStation: qualityStation,
        card: { ...cards[currentCardIndex], explanation: t('fromStaticTestPlan') },
      });
      closeSnackbar(snackbarKey);
      if (response.data.card.id) {
        enqueueSnackbar(t('testCardSavedSuccessfully'), { variant: 'success' });
        await updateDefectMapping(response.data.card.id);
        setDialogOpen(false);
      } else {
        enqueueSnackbar(t('errorWhenSavingTestCard'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhenSavingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const saveGeneratedCardRule = async () => {
    try {
      const snackbarKey = enqueueSnackbar(t('testCardSavingStarted'), {
        variant: 'info',
        persist: true,
      });
      const newCard = {
        ...cards[currentCardIndex],
        explanation: currentRule.ruleName,
        rule: currentRule,
      };
      const response = await axiosClient.post(ruleCardEndpoint, {
        qualityStation: qualityStation,
        card: newCard,
      });
      closeSnackbar(snackbarKey);
      if (response.data.card.id) {
        enqueueSnackbar(t('testCardSavedSuccessfully'), { variant: 'success' });
        await updateDefectMapping(response.data.card.id);
        setDialogOpen(false);
        setIsRuleModalOpen(false);
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhenSavingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  // Rule Handling
  // State to manage the current rule and modal visibility
  const [currentRule, setCurrentRule] = useState();
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);

  // Function to open the modal for a new rule
  const handleNewRule = () => {
    setCurrentRule({ ruleName: '', logic: [] });
    setIsRuleModalOpen(true);
  };

  // Function to handle saving the rule (either new or edited)
  const handleSaveRule = async () => {
    await saveGeneratedCardRule();
    setIsRuleModalOpen(false);
  };

  // Function to close the rule modal
  const handleCloseRuleModal = () => {
    setIsRuleModalOpen(false);
  };

  // Function to add a new logic condition
  const addLogic = () => {
    const updatedRule = { ...currentRule };
    updatedRule.logic.push({
      operator: '',
      fieldTwoValue: '',
      connector: '',
      orderNumber: updatedRule.logic.length,
    });
    setCurrentRule(updatedRule);
  };

  // Function to delete a logic condition
  const deleteLogic = (index) => {
    const updatedRule = { ...currentRule };
    updatedRule.logic.splice(index, 1);
    setCurrentRule(updatedRule);
  };

  // Event Handling
  const [currentEvent, setCurrentEvent] = useState();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  // Function to open the modal for a new event
  const handleNewEvent = () => {
    setCurrentEvent({
      eventImageField: null,
      eventStreamName: '',
      eventFrequency: 0,
      isEvent: true,
    });
    setIsEventModalOpen(true);
  };

  // Function to handle saving the event (either new or edited)
  const handleSaveEvent = async (selectedEvent) => {
    await saveGeneratedCardEvent(selectedEvent);
    setIsEventModalOpen(false);
  };

  // Function to close the event modal
  const handleCloseEventModal = () => {
    setIsEventModalOpen(false);
  };

  const saveGeneratedCardEvent = async (selectedEvent) => {
    const snackbarKey = enqueueSnackbar(t('testCardSavingStarted'), {
      variant: 'info',
      persist: true,
    });
    // explanation: selectedEvent all fields except id, eventStreamName, and eventFrequency separated by ' - '
    const explanation = Object.values(
      _.omit(
        selectedEvent,
        'id',
        'eventStreamName',
        'eventFrequency',
        'cost',
        'frequency',
        'lastOccurrence'
      )
    ).join(' - ');
    try {
      const response = await axiosClient.post(eventCardEndpoint, {
        qualityStation: qualityStation,
        card: {
          ...cards[currentCardIndex],
          explanation: explanation,
          dynamic: {
            eventImageField: null,
            eventStreamName: selectedEvent.eventStreamName,
            eventFrequency: selectedEvent.eventFrequency,
            isEvent: true,
            ..._.omit(selectedEvent, 'id', 'eventStreamName', 'eventFrequency'),
          },
        },
      });
      if (response.data.card.id) {
        closeSnackbar(snackbarKey);
        enqueueSnackbar(t('testCardSavedSuccessfully'), { variant: 'success' });
        await updateDefectMapping(response.data.card.id);
        setDialogOpen(false);
      }
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenSavingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }

    setAddAIClassOpen(false);
  };

  // AI Handling
  const [addAIClassOpen, setAddAIClassOpen] = useState(false);

  const saveGeneratedCardAI = async () => {
    setAddAIClassOpen(true);
  };

  /**
   *
   * Saves
   * Makes a POST request to the server to save the new AI test cards.
   * @param {Array} selectedMLClass - {modelID, aiClassName}
   */
  const handleSaveSelectedAITests = async (selectedMLClass) => {
    const snackbarKey = enqueueSnackbar(t('testCardSavingStarted'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.post(aiCardEndpoint, {
        qualityStation: qualityStation,
        card: {
          ...cards[currentCardIndex],
          explanation: selectedMLClass.aiClassName,
          ai: {
            aiClassName: selectedMLClass.aiClassName,
            active: true,
            modelID: selectedMLClass.modelID,
            modelActive: true,
          },
        },
      });
      if (response.data.card.id) {
        closeSnackbar(snackbarKey);
        enqueueSnackbar(t('testCardSavedSuccessfully'), { variant: 'success' });
        await updateDefectMapping(response.data.card.id);
        setDialogOpen(false);
      }
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenSavingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }

    setAddAIClassOpen(false);
  };

  // Handle card manipulation
  const setTitle = (newTitle) => {
    const newCards = [...cards];
    newCards[currentCardIndex].title = newTitle;
    setCards(newCards);
  };

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, qualityStation, 'copilot');
    }
    const newCards = [...cards];
    newCards[currentCardIndex].images = [
      ...new Set([...newCards[currentCardIndex].images, newFileURL]),
    ];
    setCards(newCards);
  };

  const setTestObject = (newTestObject) => {
    const newCards = [...cards];
    newCards[currentCardIndex].testObject = newTestObject;
    setCards(newCards);
  };

  const setTestMethod = (newTestMethod) => {
    const newCards = [...cards];
    newCards[currentCardIndex].testMethod = newTestMethod;
    setCards(newCards);
  };

  const setTestLocation = (newTestLocation) => {
    const newCards = [...cards];
    newCards[currentCardIndex].testLocation = newTestLocation;
    setCards(newCards);
  };

  const setTestComment = (newTestComment) => {
    const newCards = [...cards];
    newCards[currentCardIndex].testComment = newTestComment;
    setCards(newCards);
  };

  const setOrderIdentifier = (newOrderIdentifier) => {
    const newCards = [...cards];
    newCards[currentCardIndex].orderIdentifier = newOrderIdentifier;
    setCards(newCards);
  };

  const deleteImage = (index) => {
    const newCards = [...cards];
    newCards[currentCardIndex].images.splice(index, 1);
    setCards(newCards);
  };

  const updateCurrentCardIndex = (direction) => {
    if (direction === 'inc' && currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else if (direction === 'dec' && currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const checkboxClick = () => {
    if (showOnlyUnmappedDefects) {
      setFilteredDefectRows(defectRows);
    } else {
      setFilteredDefectRows(
        defectRows.filter(
          (defect) => !(defectMapping.find((d) => d.id === defect.id)?.tests?.length > 0)
        )
      );
    }
    setShowOnlyUnmappedDefects(!showOnlyUnmappedDefects);
  };

  return (
    <>
      <div className="defects-wrapper">
        <div className="defects">
          <div className="defects-header-wrapper">
            <div
              className="unlabeled-defects-checkbox"
              onClick={() => {
                checkboxClick();
              }}
            >
              <Checkbox variant="qualitatio" checked={showOnlyUnmappedDefects} />
              {t('showOnlyUnmappedDefects')}
            </div>
            {/* <QualitatioDateTimePicker
                            label={t("selectStartTime")}
                            value={startTime}
                            onChange={(e) => { handleAnimation(); setStartTime(e) }}
                            variant="responsive"
                            margin={"0 3px"}
                        />
                        <QualitatioDateTimePicker
                            label={t("selectEndTime")}
                            value={endTime}
                            onChange={(e) => { handleAnimation(); setEndTime(e) }}
                            variant="responsive"
                            margin={"0 3px"}
                        /> */}
            {/* <QualitatioButton
                            text={t("update")}
                            order='primary'
                            onClick={() => _.isEqual(cardsGroundTruth, cards) ? updatecardsData() : setDiscardWarningOpen(true)}
                            className={animateUpdateButton ? 'animate' : ''}
                            width={"12%"}
                            minWidth={"150px"}
                            height={"100%"}
                            fontSize={"calc(12px + 0.25vw)"}
                            margin={"0 0 0 3px"}
                        /> */}
          </div>
          <div className="defects-table-wrapper">
            {defectRows.length === 0 ? (
              <div className="no-data">{t('noDefectsAvailable')}</div>
            ) : (
              <QualitatioTable
                rows={showOnlyUnmappedDefects ? filteredDefectRows : defectRows}
                columns={defectColumns}
                pageSize={10}
                pageSizeOptions={[10]}
                height={'100%'}
                maxHeight={'600px'}
                setCurrentIndex={setCurrentIndex}
              />
            )}
          </div>
        </div>
        <div className="cards">
          <div className="test-card">
            {cards?.length > 0 && (
              <TestCard
                editable={!cards[currentCardIndex]?.type}
                title={cards[currentCardIndex]?.title}
                setTitle={setTitle}
                images={cards[currentCardIndex]?.images}
                setImages={setImages}
                testObject={cards[currentCardIndex]?.testObject}
                setTestObject={setTestObject}
                testMethod={cards[currentCardIndex]?.testMethod}
                setTestMethod={setTestMethod}
                testLocation={cards[currentCardIndex]?.testLocation}
                setTestLocation={setTestLocation}
                testComment={cards[currentCardIndex]?.testComment}
                setTestComment={setTestComment}
                orderIdentifier={cards[currentCardIndex]?.orderIdentifier}
                setOrderIdentifier={setOrderIdentifier}
                generation={cards[currentCardIndex]?.type || t('Copilot')}
                explanation={
                  cards[currentCardIndex]?.explanation ||
                  Object.values(
                    _.omit(currentDefect, 'id', 'cost', 'lastOccurrence', 'frequency')
                  ).join(' - ')
                }
                index={currentCardIndex}
                metaIndex={currentIndex}
                numberCards={cards?.length}
                qualityStation={cards[currentCardIndex]?.qualityStation}
                orderIdentifierMapping={orderIdentifierMapping}
                deleteImage={deleteImage}
                defect={_.omit(currentDefect, 'id', 'cost', 'lastOccurrence', 'frequency')}
              />
            )}
          </div>
          <div className="test-card-navigation">
            {cards?.map((test, index) => {
              return (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="basis-nav-buttons"
                  onClick={() => setCurrentCardIndex(index)}
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="9.75"
                    fill={
                      index === currentCardIndex
                        ? 'var(--m-3refprimaryprimary-60)'
                        : 'var(--m3syslightsecondary-fixed)'
                    }
                    stroke="#0E0D13"
                    strokeWidth="0.5"
                  />
                </svg>
              );
            })}
          </div>
          <div className="test-card-generation">
            {!(cards?.length > 0) && String(currentIndex).length === 24 && (
              <>
                <Tooltip title={t('alreadyHaveACheck')}>
                  <IconButton
                    className="test-card-generation-element"
                    variant="qualitatio"
                    squared={true}
                    onClick={() => mapCard()}
                    style={{
                      backgroundColor: theme.palette.success.secondary,
                      width: '48px',
                      height: '48px',
                    }}
                  >
                    <img src="/images/checkIcon.svg" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    externalTextAPIUsage
                      ? t('generateTestCardTooltip')
                      : t('pleaseActivateTheExternalTextAPI')
                  }
                >
                  <Box>
                    <CopilotButton
                      defectDefinition={defectRows.find((defect) => defect.id === currentIndex)}
                      qualityStation={qualityStation}
                      allCards={cards}
                      setAllCards={setCards}
                      setCurrentCardIndex={setCurrentCardIndex}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={t('cannotSpotThisDefect')}>
                  <IconButton
                    className="test-card-generation-element"
                    variant="qualitatio"
                    squared={true}
                    onClick={() => removeDefect()}
                    style={{
                      backgroundColor: theme.palette.success.secondary,
                      width: '48px',
                      height: '48px',
                    }}
                  >
                    <img src="/images/crossIcon.svg" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {cards?.length > 0 && (
              <>
                {cards[currentCardIndex]?.type && (
                  <IconButton
                    className="delete-button"
                    variant="qualitatio"
                    squared={true}
                    onClick={removeCardFromDefectMapping}
                    style={{
                      backgroundColor: theme.palette.error.main,
                      width: '48px',
                      height: '48px',
                    }}
                  >
                    <DeleteForeverRoundedIcon color="white" fontSize="large" />
                  </IconButton>
                )}
                <IconButton
                  className="test-card-generation-element"
                  variant="qualitatio"
                  squared={true}
                  onClick={() => updateCurrentCardIndex('dec')}
                  style={{
                    backgroundColor: theme.palette.success.secondary,
                    width: '36px',
                    height: '36px',
                  }}
                >
                  <ArrowBackIosNewRoundedIcon color="primary" />
                </IconButton>

                <IconButton
                  className="test-card-generation-element"
                  variant="qualitatio"
                  squared={true}
                  onClick={() => {
                    cards[currentCardIndex]?.type ? generateCard() : saveGeneratedCard();
                  }}
                  style={{
                    backgroundColor: cards[currentCardIndex]?.type
                      ? theme.palette.warning.main
                      : theme.palette.success.main,
                    width: '48px',
                    height: '48px',
                  }}
                >
                  {cards[currentCardIndex]?.type ? (
                    <img src="/images/copilotIcon.svg" style={{ width: '40px', height: '40px' }} />
                  ) : (
                    <SaveIcon color="white" fontSize="large" />
                  )}
                </IconButton>
                <IconButton
                  className="test-card-generation-element"
                  variant="qualitatio"
                  squared={true}
                  onClick={() => updateCurrentCardIndex('inc')}
                  style={{
                    backgroundColor: theme.palette.success.secondary,
                    width: '36px',
                    height: '36px',
                  }}
                >
                  <ArrowForwardIosRoundedIcon color="primary" />
                </IconButton>
                {cards[currentCardIndex]?.type && (
                  <IconButton
                    className="delete-button"
                    variant="qualitatio"
                    squared={true}
                    onClick={() => {
                      navigateToTestCard();
                    }}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      width: '48px',
                      height: '48px',
                    }}
                  >
                    <EditRoundedIcon color="white" fontSize="large" />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <QualitatioDialog
          title={t('saveGeneratedTestCard')}
          description={t(
            'toSaveTheGeneratedTestCardYouNeedToSelectTheTestLogicThatYouWantToAssignTheTestCardTo'
          )}
          open={isDialogOpen}
          onClose={handleClose}
        >
          {dialogOptions.map((logicName, index) => (
            <div className="test-logic" key={index}>
              <div className="test-logic-name-wrapper">
                <QualitatioButton
                  text={logicName}
                  width={'100%'}
                  onClick={async () => await testLogicSelected(index)}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  startIcon={
                    <img
                      src={
                        '/images/' +
                        (index === 0
                          ? 'Basis'
                          : index === 1
                            ? 'Rules'
                            : index === 2
                              ? 'Events'
                              : 'AI') +
                        '.png'
                      }
                    />
                  }
                  startIconIsImage={true}
                  startIconRounded={false}
                />
              </div>
            </div>
          ))}
        </QualitatioDialog>
      )}
      {isRuleModalOpen && (
        <NewRuleModal
          isOpen={isRuleModalOpen}
          handleClose={handleCloseRuleModal}
          handleSave={handleSaveRule}
          rule={currentRule || { ruleName: '', logic: [] }}
          setRule={setCurrentRule}
          addLogicCallback={addLogic}
          deleteLogicCallback={deleteLogic}
        />
      )}
      {isEventModalOpen && (
        <NewEventModal
          isOpen={isEventModalOpen}
          handleClose={handleCloseEventModal}
          handleSave={handleSaveEvent}
        />
      )}
      {isCardSelectionModalOpen && (
        <CardSelectionModal
          cardSeletionModalOpen={isCardSelectionModalOpen}
          setCardSelectionModalOpen={setCardSelectionModalOpen}
          qualityStation={qualityStation}
          cardLayout={cardLayout}
          orderIdentifierMapping={orderIdentifierMapping}
          handleClose={handleClose}
          cardSelected={cardSelected}
        />
      )}
      {addAIClassOpen && (
        <NewAIModal
          isOpen={addAIClassOpen}
          onClose={() => setAddAIClassOpen(false)}
          onSave={handleSaveSelectedAITests}
        />
      )}
    </>
  );
};

Copilot.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  setTestLogicIndex: PropTypes.func.isRequired,
  defectMapping: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      defect: PropTypes.string.isRequired,
      cost: PropTypes.number,
      frequency: PropTypes.number,
      lastOccurrence: PropTypes.string,
    })
  ).isRequired,
  setDefectMapping: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  orderIdentifierMapping: PropTypes.arrayOf(
    PropTypes.shape({
      orderIdentifier: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Copilot;
