import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { liveStreamDataEndpoint } from '../../../../api/endpoints';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDropdown from '../../../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from '../../../../components/QualitatioInput/QualitatioInput';

const NewRuleModal = ({
  isOpen,
  handleClose,
  handleSave,
  rule,
  setRule,
  addLogicCallback,
  deleteLogicCallback,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [fields, setFields] = useState([]);

  // Get live-stream data
  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      const newFields = response.data.liveStreamData.map((el) => {
        el.fieldNames = el.fieldNames.map((fieldName) => {
          return {
            name: fieldName,
            type: el.fieldTypes[el.fieldNames.indexOf(fieldName)],
          };
        });
        return el;
      });
      setFields(newFields);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getLiveStreamData();
  }, []);

  const fieldOptions = useMemo(() => {
    return fields.flatMap((stream) =>
      stream.fieldNames.map((field) => ({
        label: field.name,
        value: `${stream.streamName} > ${field.name}`,
        group: stream.streamName,
      })),
    );
  }, [fields]);

  const fieldValues = useMemo(() => {
    const logic = rule.logic || [];
    return logic.map((el) => {
      const fieldOneString = `${el.fieldOneStreamName} > ${el.fieldOneFieldName}`;
      const foundOption = fieldOptions.find((opt) => opt.value === fieldOneString);
      return foundOption || { value: '', label: '' };
    });
  }, [rule, fieldOptions]);

  const operatorOptions = useMemo(() => {
    return (
      fields
        .flatMap((stream) => stream.fieldNames)
        .reduce((acc, field) => {
          const type = field.type;

          let options;
          if (type === 'number') {
            options = [
              { label: '<', value: '<' },
              { label: '>', value: '>' },
              { label: '=', value: '=' },
              { label: '!=', value: '!=' },
            ];
          } else {
            options = [
              { value: 'startsWith', label: t('startsWith') },
              { value: 'equals', label: t('equals') },
              { value: 'endsWith', label: t('endsWith') },
              { value: 'includes', label: t('includes') },
            ];
          }

          acc[field.name] = options;
          return acc;
        }, {}) || []
    );
  }, [fields]);

  const optionsValue = useMemo(() => {
    const logic = rule.logic || [];
    return logic.map((el) => {
      const foundOption = operatorOptions[el.fieldOneFieldName]?.find(
        (opt) => opt.value === el.operator,
      );
      return foundOption || { value: '', label: '' };
    });
  }, [rule, fieldOptions, operatorOptions]);

  console.log(
    'disabled check: ' +
      rule.logic.some((el) => el.operator?.length === 0) +
      ' and now the operator: ' +
      rule.logic.map((el) => el.operator),
  );

  const getOperatorOptions = (fieldName) => {
    const type = fields
      .flatMap((stream) => stream.fieldNames)
      .find((field) => field.name === fieldName)?.type;
    return type === 'number'
      ? [
          { label: '<', value: '<' },
          { label: '>', value: '>' },
          { label: '=', value: '=' },
          { label: '!=', value: '!=' },
        ]
      : [
          { value: 'startsWith', label: t('startsWith') },
          { value: 'equals', label: t('equals') },
          { value: 'endsWith', label: t('endsWith') },
          { value: 'includes', label: t('includes') },
        ];
  };

  const logicalOperatorOptions = [
    { value: 'AND', label: t('AND') },
    { value: 'OR', label: t('OR') },
  ];

  // Translate this to the new logic
  // const getField1Value = (fieldOneStreamName, fieldOneFieldName) => {
  //     return field1Options.find(opt => opt.value === `${fieldOneStreamName} > ${fieldOneFieldName}`) || { value: '', label: '' };
  // }

  const getFieldValue = (fieldOneStreamName, fieldOneFieldName) => {
    return (
      fieldOptions.find((opt) => opt.value === `${fieldOneStreamName} > ${fieldOneFieldName}`) || {
        value: '',
        label: '',
      }
    );
  };

  const handleFieldChange = (value, index) => {
    let [streamName, fieldName] = value.split(' > ');
    if (!fieldName) {
      fieldName = '';
    }
    const fieldDataType = getFieldDataType(streamName, fieldName);

    const updatedRule = { ...rule };
    updatedRule.logic[index] = {
      ...updatedRule.logic[index],
      fieldOneStreamName: streamName,
      fieldOneFieldName: fieldName,
      fieldOneDataType: fieldDataType,
      operator:
        fieldDataType === updatedRule.logic[index].fieldOneDataType
          ? updatedRule.logic[index].operator
          : '',
    };

    setRule(updatedRule);
  };

  const getFieldDataType = (streamName, fieldName) => {
    const field = fields
      .find((stream) => stream.streamName === streamName)
      ?.fieldNames.find((field) => field.name === fieldName);

    return field?.type === 'string' ? 'String' : 'Integer';
  };

  const handleOperatorChange = (value, index) => {
    const updatedRule = { ...rule };
    updatedRule.logic[index].operator = value;
    setRule(updatedRule);
  };

  const handleValueChange = (value, index) => {
    const updatedRule = { ...rule };
    updatedRule.logic[index].fieldTwoValue = value;
    setRule(updatedRule);
  };

  const handleConnectorChange = (value, index) => {
    const updatedRule = { ...rule };
    updatedRule.logic[index].connector = value;
    setRule(updatedRule);
  };

  const handleAddLogic = () => {
    addLogicCallback();
  };

  const handleDeleteLogic = (index) => {
    deleteLogicCallback(index);
  };

  return (
    <QualitatioDialog
      title={t('edit') + ' ' + rule.ruleName}
      open={isOpen}
      onClose={handleClose}
      actions={[
        {
          label: t('save'),
          onClick: handleSave,
          disabled:
            rule.ruleName.length === 0 ||
            rule.logic.length === 0 ||
            rule.logic.some((el) => el.fieldOneFieldName?.length === 0) ||
            rule.logic.some((el) => el.operator?.length === 0) ||
            rule.logic.some((el) => el.fieldTwoValue?.length === 0) ||
            rule.logic.slice(0, -1).some((el) => el.connector?.length === 0),
          order: 'primary',
        },
        {
          label: t('cancel'),
          onClick: handleClose,
          order: 'secondary',
        },
      ]}
    >
      <div className="rule-name-change-wrapper">
        <QualitatioInput
          label={t('ruleName')}
          value={rule.ruleName}
          type="text"
          compact={true}
          onChange={(e) => setRule({ ...rule, ruleName: e.target.value })}
        />
      </div>
      <div className="rule-logic-wrapper">
        {rule.logic.map((logicItem, index) => (
          <div key={index} className="logic-wrapper">
            {index === 0 && <div className="if-text">{t('if')}</div>}
            <QualitatioDropdown
              options={fieldOptions || []}
              groupBy={(option) => option.group}
              label={t('field')}
              value={fieldValues[index]}
              onChange={(event, option) =>
                option ? handleFieldChange(option.value, index) : handleFieldChange('', index)
              }
              placeholder={t('field')}
            />
            <QualitatioDropdown
              options={operatorOptions[logicItem.fieldOneFieldName] || []}
              label={t('operator')}
              value={optionsValue[index]}
              onChange={(event, option) => option && handleOperatorChange(option.value, index)}
              placeholder={t('operator')}
            />
            <QualitatioInput
              label={t('value')}
              value={logicItem.fieldTwoValue}
              type="text"
              onChange={(e) => handleValueChange(e.target.value, index)}
            />
            {index !== rule.logic.length - 1 && (
              <QualitatioDropdown
                options={logicalOperatorOptions}
                label={t('logicalOperator')}
                className="logical-operator"
                value={logicalOperatorOptions.find((opt) => opt.value === logicItem.connector)}
                onChange={(event, option) =>
                  option
                    ? handleConnectorChange(option.value, index)
                    : handleConnectorChange('', index)
                }
                placeholder={t('logicalOperator')}
              />
            )}
            <IconButton
              className="delete-button"
              onClick={() => handleDeleteLogic(index)}
              style={{ backgroundColor: theme.palette.error.main }}
            >
              {/* Replace with your delete icon */}
              <DeleteForeverRoundedIcon color="white" />
            </IconButton>
            {index === rule.logic.length - 1 && (
              <IconButton
                onClick={handleAddLogic}
                style={{ backgroundColor: theme.palette.success.secondary }}
              >
                <AddRoundedIcon color="primary" />
              </IconButton>
            )}
          </div>
        ))}
        {rule.logic.length === 0 && (
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={handleAddLogic}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <AddRoundedIcon color="primary" />
          </IconButton>
        )}
      </div>
    </QualitatioDialog>
  );
};

export default NewRuleModal;
