import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Button, Chip, Grid, IconButton, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { getSPCMonitorSystemsEndpoint } from '../../../api/endpoints';
import { QualitatioChipArray, QualitatioDialog, QualitatioTable } from '../../../components';
import ConfirmationDialog from '../../../components/ConfirmationOverlay';
import QualitatioFormDialog from '../../../components/QualitatioFormDialog/QualitatioFormDialog';
import EditMonitorSystemForm from './EditMonitorSystemForm';

export default function MonitorSystemsTable() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // States
  const [monitorSystems, setMonitorSystems] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [editMonitorSystemDialogOpen, setEditMonitorSystemDialogOpen] = useState(false);
  const [filterFieldsDialogOpen, setFilterFieldsDialogOpen] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Action methods for the table
  const handleAddNewSystem = () => {
    setEditMonitorSystemDialogOpen(true);
    setSelectedSystem(null);
  };

  const handleEditSystem = (system) => {
    setSelectedSystem(system);
    setEditMonitorSystemDialogOpen(true);
  };

  const handleDeleteSystem = async (system) => {
    try {
      const res = await axiosClient.delete(`${getSPCMonitorSystemsEndpoint}/${system.id}`);
      if (res.status === 204) {
        enqueueSnackbar(t('ei.deleteSystemSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('ei.deleteSystemError'), { variant: 'error' });
      }
      await fetchMonitorSystems();
      setDeleteDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(t('ei.deleteSystemError'), { variant: 'error' });
    }
  };

  const handleActivationToggle = async (system) => {
    console.log('Toggling system state:', system);
    const nextState = system.active ? 'deactivate' : 'activate';
    try {
      const response = await axiosClient.post(
        `${getSPCMonitorSystemsEndpoint}/${system.id}/${nextState}`
      );
      console.log('response:', response);
      fetchMonitorSystems();
      if (response.status === 200) {
        enqueueSnackbar(t('ei.toggleSystemStateSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('ei.toggleSystemStateError'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('ei.toggleSystemStateError'), { variant: 'error' });
    }
  };

  const handleDialogClose = async () => {
    setEditMonitorSystemDialogOpen(false);
    await fetchMonitorSystems();
  };

  const fetchMonitorSystems = async () => {
    try {
      const fetchedSystems = await axiosClient.get(getSPCMonitorSystemsEndpoint);
      setMonitorSystems(fetchedSystems.data);
    } catch (error) {
      enqueueSnackbar(t('ei.errorLoadingSystems'), { variant: 'error' });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMonitorSystems();
  }, []);

  useEffect(() => {
    setTableRows(
      monitorSystems.map((system) => {
        return {
          id: system.systemId,
          ...system,
        };
      })
    );
  }, [monitorSystems]);

  // Table content
  const columns = [
    {
      field: 'name',
      headerName: t('ei.monitorName'),
      width: 200,
      flex: 1,
    },
    {
      field: 'filterFields',
      headerName: t('ei.filterFields'),
      width: 200,
      flex: 1,
      renderCell: (params) =>
        params.value.length > 0 && (
          <>
            <Chip
              label={params.value.join(', ')}
              onClick={() => {
                setFilterFieldsDialogOpen((prevState) => {
                  const newState = [...prevState];
                  newState[params.id] = true;
                  return newState;
                });
              }}
              sx={{ backgroundColor: theme.palette.success.secondary }}
              width={'100%'}
            />
            <QualitatioDialog
              title={t('ei.filterFields')}
              open={filterFieldsDialogOpen[params.id]}
              onClose={() =>
                setFilterFieldsDialogOpen((prevState) => {
                  const newState = [...prevState];
                  newState[params.id] = false;
                  return newState;
                })
              }
              description={t('ei.filterFieldsDescription')}
              actions={[
                {
                  label: t('close'),
                  onClick: () =>
                    setFilterFieldsDialogOpen((prevState) => {
                      const newState = [...prevState];
                      newState[params.id] = false;
                      return newState;
                    }),
                  order: 'primary',
                },
              ]}
            >
              <QualitatioChipArray
                chipData={params.value.map((elem) => {
                  return {
                    label: elem,
                  };
                })}
                backgroundColor={theme.palette.primary.main}
              />
            </QualitatioDialog>
          </>
        ),
    },
    {
      field: 'monitoringFrequencyMin',
      headerName: t('ei.monitoringFrequency'),
      width: 120,
      flex: 1,
    },
    {
      field: 'spcControlPeriodMin',
      headerName: t('ei.controlPeriod'),
      width: 120,
      flex: 1,
    },
    {
      field: 'active',
      headerName: t('ei.status'),
      width: 100,
      flex: 1,
      renderCell: (params) => (
        <Switch
          variant="qualitatio"
          checked={params.value}
          onChange={() => handleActivationToggle(params.row)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 120,
      flex: 1,
      renderCell: (params) => (
        <div className="actions">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => handleEditSystem(params.row)}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '30px',
              height: '30px',
            }}
          >
            <EditRoundedIcon color="primary" />
          </IconButton>
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => {
              setSelectedSystem(params.row);
              setDeleteDialogOpen(true);
            }}
            style={{ backgroundColor: theme.palette.error.main, width: '30px', height: '30px' }}
          >
            <DeleteForeverRoundedIcon color="white" />
          </IconButton>
        </div>
      ),
    },
  ];

  const deleteDialogProps = {
    open: deleteDialogOpen,
    onCancel: () => setDeleteDialogOpen(false),
    onConfirm: () => handleDeleteSystem(selectedSystem),
    title: t('ei.confirmDeletion'),
    content: t('ei.confirmSystemDeletionText'),
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
  };

  return (
    <Grid container alignItems="center" direction="row" spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2">{t('ei.monitorSystems')}</Typography>
      </Grid>

      <Grid item xs={12} sm={'auto'} style={{ textAlign: 'right', margin: '0 0 0 auto' }}>
        <Button variant="qualitatio" size="small" onClick={handleAddNewSystem}>
          {t('ei.addNewMonitorSystem')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <QualitatioTable
          rows={tableRows}
          columns={columns}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
        />
      </Grid>
      {editMonitorSystemDialogOpen && (
        <QualitatioFormDialog
          dialogTitle={selectedSystem ? t('ei.editMonitorSystem') : t('ei.addNewMonitorSystem')}
          FormComponent={<EditMonitorSystemForm />}
          formProps={{ selectedSystem: selectedSystem, onSubmitCallback: handleDialogClose }}
          isOpen={true}
          onClose={handleDialogClose}
        />
      )}
      <ConfirmationDialog {...deleteDialogProps} />
    </Grid>
  );
}
