
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import QualitatioButton from '../QualitatioButton/QualitatioButton';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import './QualitatioDialog.css';
import { FaSave } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QualitatioDialog = ({
    title,
    description,
    open,
    onClose,
    actions,
    children,
    subheader = "",
    maxWidth = "lg",
    buttonOnLeft,
    footer,
    ...otherProps
}) => {
    const defaultMuiTheme = createTheme();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="qualitatio-dialog-description"
            maxWidth={maxWidth}
            fullWidth={true}
            {...otherProps}
            variant="qualitatio_dialog"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '2vh' }}>
                <DialogContentText>
                    {subheader}
                </DialogContentText>
                <DialogContentText id="qualitatio-dialog-description">
                    {/* Make this work also if HTML tag are given */}
                    {description?.split("\n")?.map((item, idx) => (
                        <div key={idx} dangerouslySetInnerHTML={{ __html: item }}></div>
                    ))}
                </DialogContentText>
                {children}
                <DialogContentText>
                    {footer}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                {buttonOnLeft && (
                    <div className='button-on-the-left'>
                        <QualitatioButton
                            text={buttonOnLeft.label}
                            onClick={buttonOnLeft.onClick}
                            order={buttonOnLeft.order}
                            startIcon={buttonOnLeft.startIcon}
                            width={buttonOnLeft.width}
                            disabled={buttonOnLeft.disabled}
                        />
                    </div>
                )}
                <div className={'other-buttons-' + (buttonOnLeft ? 'half' : 'full')}>
                    {actions?.map((action, idx) => (
                        <QualitatioButton
                            key={idx}
                            text={action.label}
                            onClick={action.onClick || onClose}
                            order={action.order}
                            margin={"0 0 0 10px"}
                            disabled={action.disabled}
                            startIcon={action.startIcon}
                            width={action.width}
                        />
                    ))}
                </div>
            </DialogActions>

        </Dialog>
    );
};

export default QualitatioDialog;
