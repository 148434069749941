import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';

// Contains 'delete card' and 'discard unsaved changes' dialogs that are general to all test cards
const CardEditDialogs = ({ deleteCardWarningDialog, discardChangesWarningDialog }) => {
  const { t } = useTranslation();

  const { deleteCardWarningOpen, setDeleteCardWarningOpen, deleteCard } = deleteCardWarningDialog;

  const { discardChangesWarningOpen, setDiscardChangesWarningOpen, discardChanges } =
    discardChangesWarningDialog;

  return (
    <>
      <QualitatioDialog
        title={t('areYouSureYouWantToDeleteThisCard')}
        description={t('thisActionCannotBeReverted')}
        open={deleteCardWarningOpen}
        onClose={() => {
          setDeleteCardWarningOpen(false);
        }}
        maxWidth="sm"
        actions={[
          {
            label: t('cancel'),
            onClick: () => {
              setDeleteCardWarningOpen(false);
            },
            order: 'secondary',
          },
          {
            label: t('continue'),
            onClick: () => {
              deleteCard();
              setDeleteCardWarningOpen(false);
            },
            order: 'primary',
          },
        ]}
      />
      <QualitatioDialog
        title={t('unsavedChanges')}
        description={t(
          'youHaveUnsavedChangesWhichCanBeLostIfYouProceedNowAreYouSureYouWantToProceed'
        )}
        open={discardChangesWarningOpen !== -1}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') {
            return;
          }
          setDiscardChangesWarningOpen(-1);
        }}
        maxWidth="sm"
        actions={[
          {
            label: t('continue'),
            onClick: (event, reason) => {
              if (reason && reason === 'backdropClick') {
                return;
              }
              discardChanges();
              setDiscardChangesWarningOpen(-1);
            },
            order: 'secondary',
          },
          {
            label: t('cancel'),
            onClick: (event, reason) => {
              if (reason && reason === 'backdropClick') {
                return;
              }
              setDiscardChangesWarningOpen(-1);
            },
            order: 'primary',
          },
        ]}
      />
    </>
  );
};

const deleteCardWarningDialogPropTypes = {
  deleteCardWarningOpen: PropTypes.bool.isRequired,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

const discardChangesWarningDialogPropTypes = {
  discardChangesWarningOpen: PropTypes.number.isRequired,
  setDiscardChangesWarningOpen: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};

CardEditDialogs.propTypes = {
  deleteCardWarningDialog: PropTypes.shape(deleteCardWarningDialogPropTypes).isRequired,
  discardChangesWarningDialog: PropTypes.shape(discardChangesWarningDialogPropTypes).isRequired,
};

export default CardEditDialogs;
