import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import PropTypes from 'prop-types';

const QualitatioSlider = ({
  children,
  activeIndex, // received from parent component
  onActiveIndexChange,
  ...props
}) => {
  const swiperRef = useRef(null);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const swiper = swiperRef.current;
    if (swiper) {
      const updateState = () => {
        setIsAtBeginning(swiper.isBeginning);
        setIsAtEnd(swiper.isEnd);
        if (onActiveIndexChange) onActiveIndexChange(swiper.activeIndex);
      };

      // Initial update
      updateState();

      // Listen for slide changes
      swiper.on('slideChange', updateState);

      // Clean up
      return () => {
        swiper.off('slideChange', updateState);
      };
    }
  }, [swiperRef, onActiveIndexChange, children]);

  useEffect(() => {
    if (swiperRef.current && typeof activeIndex === 'number') {
      swiperRef.current.slideTo(activeIndex);
    }
  }, [activeIndex]);

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();
  };

  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  return (
    <Grid container justifyContent="space-evenly" alignItems="center">
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          color="primary"
          variant="qualitatio"
          size="small"
          squared
          onClick={handlePrev}
          disabled={activeIndex === 0 || isAtBeginning}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      </Grid>

      <Grid item xs={8}>
        <Swiper
          modules={[Navigation]}
          onSwiper={(setSwiperRef) => (swiperRef.current = setSwiperRef)}
          {...props}
          // other Swiper parameters
        >
          {children}
        </Swiper>
      </Grid>

      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          color="primary"
          variant="qualitatio"
          size="small"
          squared
          onClick={handleNext}
          disabled={activeIndex === children.length - 1 || isAtEnd}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default QualitatioSlider;

QualitatioSlider.propTypes = {
  children: PropTypes.node.isRequired,
  activeIndex: PropTypes.number,
  onActiveIndexChange: PropTypes.func,
};
