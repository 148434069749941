/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import InputRoundedIcon from '@mui/icons-material/InputRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ShutterSpeedRoundedIcon from '@mui/icons-material/ShutterSpeedRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import SystemSecurityUpdateGoodRoundedIcon from '@mui/icons-material/SystemSecurityUpdateGoodRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import MaxMenuIcon from '../../assets/icons/MaxMenuIcon';
import ROLES from '../../authentication/roleConst';
import { useAuthStore } from '../../store/auth.store';
import { getRedirectPath } from '../../utils/accessUtils';
import './style.css';

export const Navigationbarclosed = ({ className, setNavigationBarOpen, allowedModules }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPage = location.pathname;
  const currentPath = `${location.pathname}${location.search}`;

  const user = useAuthStore((state) => state.user);

  return (
    <>
      {user &&
        !currentPage.startsWith('/intelligent_testing/recommender') &&
        !currentPage.startsWith('/efficient_testing/recommender') &&
        !currentPage.startsWith('/setup') && (
          <>
            <div className={`navigationbarclosed ${className}`}>
              <div className="home-link">
                <div onClick={() => setNavigationBarOpen(true)}>
                  <MaxMenuIcon className="icons-einklappen-instance" fill={'var(--m-3white)'} />
                </div>
              </div>
              <Link to={getRedirectPath(user)}>
                <div className="link" description={t('home')}>
                  <HomeRoundedIcon color={currentPage === '/home' ? 'primaryShiny' : 'white'} />
                </div>
              </Link>
              {user?.role === ROLES.ADMIN && (
                <div className="dropdown">
                  <Link to="/basis">
                    <div className="link" description={t('systemAdministration')}>
                      <HandymanRoundedIcon
                        color={currentPage === '/basis' ? 'primaryShiny' : 'white'}
                      />
                    </div>
                  </Link>
                  <div className="links-container">
                    <Link to="/basis/user_management">
                      <div className="link" description={t('userManagement')}>
                        <PersonAddAltRoundedIcon
                          color={
                            currentPage === '/basis/user_management' ? 'primaryShiny' : 'white'
                          }
                        />
                      </div>
                    </Link>
                    <Link to="/basis/input_configurator">
                      <div className="link" description={t('inputConfiguration')}>
                        <InputRoundedIcon
                          color={
                            currentPage === '/basis/input_configurator' ? 'primaryShiny' : 'white'
                          }
                        />
                      </div>
                    </Link>
                    <Link to="/basis/consumer_configurator">
                      <div className="link" description={t('consumerConfiguration')}>
                        <OutputRoundedIcon
                          color={
                            currentPage === '/basis/consumer_configurator'
                              ? 'primaryShiny'
                              : 'white'
                          }
                        />
                      </div>
                    </Link>
                    <Link to="/basis/system_configurator">
                      <div className="link" description={t('systemConfiguration')}>
                        <SettingsRoundedIcon
                          color={
                            currentPage === '/basis/system_configurator' ? 'primaryShiny' : 'white'
                          }
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.AI_ENGINEER) &&
                allowedModules.includes('Artificial Intelligence') && (
                  <div className="dropdown-2">
                    <Link to="/artificial_intelligence">
                      <div className="link" description={t('artificialIntelligence')}>
                        <ScatterPlotRoundedIcon
                          color={
                            currentPage === '/artificial_intelligence' ? 'primaryShiny' : 'white'
                          }
                        />
                      </div>
                    </Link>
                    <div className="links-container">
                      <Link to="/artificial_intelligence/configurator">
                        <div className="link" description={t('aiTraining')}>
                          <SsidChartRoundedIcon
                            color={
                              currentPage === '/artificial_intelligence/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                      <Link to="/artificial_intelligence/optimizer">
                        <div className="link" description={t('aiOptimization')}>
                          <TuneRoundedIcon
                            color={
                              currentPage === '/artificial_intelligence/optimizer'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.IT_MANAGER) &&
                allowedModules.includes('Intelligent Testing') && (
                  <div className="dropdown-2">
                    <Link to="/intelligent_testing">
                      <div className="link" description={t('intelligentTesting')}>
                        <VerifiedRoundedIcon
                          color={currentPage === '/intelligent_testing' ? 'primaryShiny' : 'white'}
                        />
                      </div>
                    </Link>
                    <div className="links-container">
                      <Link to="/intelligent_testing/configurator">
                        <div className="link" description={t('productionConfigurator')}>
                          <AccountTreeRoundedIcon
                            color={
                              currentPage === '/intelligent_testing/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                      <Link to="/intelligent_testing/recommender/select">
                        <div className="link" description={t('aiBasedTesting')}>
                          <SystemSecurityUpdateGoodRoundedIcon
                            color={
                              currentPage.startsWith('/intelligent_testing/recommender/')
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                      <Link to="/intelligent_testing/knowledge">
                        <div className="link" description={t('testKnowledge')}>
                          <MenuBookRoundedIcon
                            color={
                              currentPage === '/intelligent_testing/knowledge'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.BUSINESS_ANALYST) &&
                allowedModules.includes('Business Insights') && (
                  <div className="dropdown-2">
                    <Link to="/business_insights">
                      <div className="link" description={t('businessInsights')}>
                        <InsightsRoundedIcon
                          color={currentPage === '/business_insights' ? 'primary' : 'white'}
                        />
                      </div>
                    </Link>
                    <div className="links-container">
                      <Link to="/business_insights/monitor">
                        <div className="link" description={t('monitoring')}>
                          <BarChartRoundedIcon
                            color={
                              currentPage === '/business_insights/monitor'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                      <Link to="/business_insights/reporter">
                        <div className="link" description={t('reporting')}>
                          <DescriptionRoundedIcon
                            color={
                              currentPage === '/business_insights/reporter'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.ET_MANAGER) &&
                allowedModules.includes('Efficient Testing') && (
                  <div className="dropdown-2">
                    <Link to="/efficient_testing">
                      <div className="link" description={t('efficientTesting')}>
                        <ShutterSpeedRoundedIcon
                          color={currentPage === '/efficient_testing' ? 'primaryShiny' : 'white'}
                        />
                      </div>
                    </Link>
                    <div className="links-container">
                      <Link to="/efficient_testing/configurator">
                        <div className="link" description={t('productionConfigurator')}>
                          <AccountTreeRoundedIcon
                            color={
                              currentPage === '/efficient_testing/configurator'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                      <Link to="/efficient_testing/recommender/select">
                        <div className="link" description={t('efficientTesting')}>
                          <AltRouteRoundedIcon
                            color={
                              currentPage.startsWith('/efficient_testing/recommender/')
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {(user?.role === ROLES.ADMIN || user?.role === ROLES.EWS_MANAGER) &&
                allowedModules.includes('Early Warning System') && (
                  <div className="dropdown-2">
                    <Link to="/early_warning_system">
                      <div className="link" description={t('ews.name')}>
                        <AddAlertRoundedIcon
                          color={currentPage === '/early_warning_system' ? 'primary' : 'white'}
                        />
                      </div>
                    </Link>
                    <div className="links-container">
                      <Link to="/early_warning_system/monitor">
                        <div className="link" description={t('monitor')}>
                          <BarChartRoundedIcon
                            color={
                              currentPage === '/early_warning_system/monitor'
                                ? 'primaryShiny'
                                : 'white'
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              <Link to={`/profile?lastPath=${encodeURIComponent(currentPath)}`}>
                <div className="link" description={t('account')}>
                  <PersonRoundedIcon
                    color={currentPage === '/profile' ? 'primaryShiny' : 'white'}
                  />
                </div>
              </Link>
              <Link to="/support">
                <div className="link" description={t('support')}>
                  <InfoRoundedIcon color={currentPage === '/support' ? 'primaryShiny' : 'white'} />
                </div>
              </Link>
            </div>
          </>
        )}
    </>
  );
};

Navigationbarclosed.propTypes = {
  imgWrapperIcons: PropTypes.string,
  iconsKiLlm: PropTypes.string,
  iconsKiTrainingIconsKiTraining: PropTypes.string,
  iconsKiBasiertesRecurrentNeural: PropTypes.string,
};
