import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { getMLModelsWithPredictedClassesEndpoint } from '../../../../api/endpoints';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';
import { Modules } from '../../../../config/enums';

const NewAIModal = ({ isOpen, onClose, onSave }) => {
  const { t } = useTranslation();
  const [selectedClass, setSelectedClass] = useState({});
  const [mlModelsWithPredictedClasses, setMLModelsWithPredictedClasses] = useState([]);

  // get the ml model data at getMLModelsWithPredictedClassesEndpoint
  const getMLModelsData = async () => {
    try {
      const response = await axiosClient.get(getMLModelsWithPredictedClassesEndpoint);
      setMLModelsWithPredictedClasses(response.data.mlModelsWithPredictedClasses);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getMLModelsData();
    }
  }, [isOpen]);

  const handleCheckboxClicked = (modelID, aiClassName) => {
    setSelectedClass({ modelID: modelID, aiClassName: aiClassName });
  };

  const handleSave = () => {
    onSave(selectedClass);
    onClose();
  };

  return (
    <QualitatioDialog
      title={t('addNewAIClasses')}
      description={t('addNewAIClassesInfoText')}
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      actions={[
        {
          label: t('cancel'),
          onClick: onClose,
          order: 'secondary',
        },
        {
          label: t('save'),
          onClick: handleSave,
          order: 'primary',
        },
      ]}
    >
      <div className="add-ai-class-wrapper">
        {mlModelsWithPredictedClasses.map(
          (predictionClass, index) =>
            predictionClass.modelActive &&
            predictionClass.module === Modules.INTELLIGENT_TESTING && (
              <div
                className="ai-test"
                key={index}
                onClick={() =>
                  handleCheckboxClicked(predictionClass.modelID, predictionClass.aiClassName)
                }
              >
                <div className="ai-test-name-wrapper">
                  {`${predictionClass.aiClassName} (${predictionClass.modelName})`}
                </div>
                <Checkbox
                  variant="qualitatio"
                  checked={
                    selectedClass.modelID === predictionClass.modelID &&
                    selectedClass.aiClassName === predictionClass.aiClassName
                  }
                />
              </div>
            ),
        )}
      </div>
    </QualitatioDialog>
  );
};

export default NewAIModal;
