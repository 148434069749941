import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveIcon from '@mui/icons-material/Save';
import { Checkbox, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import {
  aiCardEndpoint,
  generateCardEndpoint,
  getAvailableAIClassesEndpoint,
  getMLModelNamesEndpoint,
} from '../../../api/endpoints';
import { Card } from '../../../components/Card';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import QualitatioChipDropdown from '../../../components/QualitatioChipDropdown/QualitatioChipDropdown';
import QualitatioDialog from '../../../components/QualitatioDialog/QualitatioDialog';
import { useAuthStore } from '../../../store/auth.store';
import TestCard from '../TestCard';
import { uploadCardImageToServer } from '../utils';
import './AITests.css';
import CopilotButton from './components/CopilotButton';

const AITests = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  aiTestsGroundTruth,
  setAITestsGroundTruth,
  aiTests,
  setAITests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [addAIClassOpen, setAddAIClassOpen] = useState(false);

  const [mlModelNames, setMLModelNames] = useState([]);
  const [availableAIClasses, setAvailableAIClasses] = useState([]);

  const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
  const [discardChangesWarningOpen, setDiscardChangesWarningOpen] = useState(-1);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getMLModelNames();
    getAvailableAIClasses();
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = aiTests.find((el) => el.id === id);
      if (card) {
        const index = availableAIClasses.findIndex(
          (el) => el.aiClassName === card.ai.aiClassName && el.modelID === card.ai.modelID
        );
        const cardIndex = aiTests
          .filter(
            (el) => el.ai.aiClassName === card.ai.aiClassName && el.ai.modelID === card.ai.modelID
          )
          .indexOf(card);
        if (index !== -1 && cardIndex !== -1) {
          setCurrentIndex(index);
          setCurrentCardIndex(cardIndex);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [availableAIClasses, aiTests]);

  const getMLModelNames = async () => {
    try {
      const response = await axiosClient.get(getMLModelNamesEndpoint);
      setMLModelNames(response.data.mlModelNames);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getAvailableAIClasses = async () => {
    try {
      const response = await axiosClient.post(getAvailableAIClassesEndpoint, {
        qualityStationName: qualityStation,
      });
      setAvailableAIClasses(response.data.aiClasses);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setTitle = (newTitle) => {
    setAITests((prevState) => {
      const newState = [...prevState];

      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );

      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);

        if (actualIndex !== -1) {
          newState[actualIndex].title = newTitle;
        }
      }

      return newState;
    });
  };

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, qualityStation, 'ai');
    }
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].images = [
            ...new Set([...newState[actualIndex].images, newFileURL]),
          ];
        }
      }
      return newState;
    });
  };

  const setTestObject = (newTestObject) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].testObject = newTestObject;
        }
      }
      return newState;
    });
  };

  const setTestMethod = (newTestMethod) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].testMethod = newTestMethod;
        }
      }
      return newState;
    });
  };

  const setTestLocation = (newTestLocation) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].testLocation = newTestLocation;
        }
      }
      return newState;
    });
  };

  const setTestComment = (newTestComment) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].testComment = newTestComment;
        }
      }
      return newState;
    });
  };

  const setOrderIdentifier = (newOrderIdentifier) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          newState[actualIndex].orderIdentifier = newOrderIdentifier;
        }
      }
      return newState;
    });
  };

  const deleteImage = (imageIndex) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1 && imageIndex < newState[actualIndex].images.length) {
          const newImages = [...newState[actualIndex].images];
          newImages.splice(imageIndex, 1);
          newState[actualIndex].images = newImages;
        }
      }
      return newState;
    });
  };

  const deleteAIClass = async (index) => {
    const aiClassNameToDelete = availableAIClasses[index].aiClassName;
    const modelIDFromClassToDelete = availableAIClasses[index].modelID;

    const updatedAvailableAIClasses = availableAIClasses.map((el, ind) =>
      ind === index ? { ...el, active: false } : el
    );

    const updatedAITests = aiTests.map((test) => {
      if (
        test.ai &&
        test.ai.aiClassName === aiClassNameToDelete &&
        test.ai.modelID === modelIDFromClassToDelete
      ) {
        return { ...test, ai: { ...test.ai, active: false } };
      } else {
        return test;
      }
    });

    const cardsToSave = updatedAITests.filter((card) => {
      return (
        card.ai.aiClassName === aiClassNameToDelete && card.ai.modelID === modelIDFromClassToDelete
      );
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(aiCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card; // Assume API returns the saved card data
        } catch (error) {
          handleSaveError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((el) => el === null).length > 0) {
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingAiBasedTestProposal'), { variant: 'error' });
      }
      return;
    }

    if (index === currentIndex) {
      setCurrentIndex(0);
      setCurrentCardIndex(0);
    }

    setAvailableAIClasses(updatedAvailableAIClasses);
    updateTestsAndGroundTruth(savedCards);
    enqueueSnackbar(t('aiBasedTestsSavedSuccessfully'), { variant: 'success' });
  };

  const handleCopilotButtonClick = async () => {
    // I am sorry this was a quick fix, but look at this file a proper fix would have taken me ages :'(
    try {
      const response = await axiosClient.post(generateCardEndpoint, {
        qualityStation: qualityStation,
        defect: availableAIClasses[currentIndex],
        creator: user.id,
        explanation: availableAIClasses[currentIndex]?.aiClassName,
        cardType: 'ai',
      });
      addCard(response.data.card);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGeneratingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const addCard = (card) => {
    const newCurrentCardIndex = aiTests.filter(
      (el) =>
        el.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
        el.ai.modelID === availableAIClasses[currentIndex]?.modelID
    ).length;

    const newCard = {
      qualityStation: qualityStation,
      type: 'AI',
      title: card?.title || '',
      images: [],
      testObject: card?.testObject || '',
      testLocation: card?.testLocation || '',
      testMethod: card?.testMethod || '',
      testComment: card?.testComment || '',
      orderIdentifier: 0,
      positionIdentifier: 1000, // Assign a position identifier
      explanation: availableAIClasses[currentIndex]?.aiClassName,
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      ai: {
        aiClassName: availableAIClasses[currentIndex]?.aiClassName,
        active: true,
        modelID: availableAIClasses[currentIndex]?.modelID,
        modelActive: availableAIClasses[currentIndex]?.modelActive,
      },
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    setAITests([...aiTests, newCard]);
    setCurrentCardIndex(newCurrentCardIndex);
  };

  const deleteCard = async () => {
    // Step 1: Calculate the new state locally
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Find the index of the card to delete in the aiTests array
    const indexToRemove = aiTests.findIndex((card, index) => {
      if (
        card.ai &&
        card.ai.aiClassName === currentAIClassName &&
        card.ai.modelID === currentModelID
      ) {
        const cardIndexInSubarray = aiTests
          .filter(
            (el) =>
              el.ai && el.ai.aiClassName === currentAIClassName && el.ai.modelID === currentModelID
          )
          .indexOf(card);
        return cardIndexInSubarray === currentCardIndex;
      }
      return false;
    });

    const cardToDelete = aiTests[indexToRemove];
    const updatedAITests = [...aiTests];
    updatedAITests.splice(indexToRemove, 1);

    if (cardToDelete.id) {
      const updatedAITestsGroundTruth = aiTestsGroundTruth.filter(
        (card) => card.id !== cardToDelete.id
      );
      try {
        await axiosClient.delete(aiCardEndpoint, {
          data: { cardID: cardToDelete.id },
        });
        enqueueSnackbar(t('aiBasedTestDeletedSuccessfully'), { variant: 'success' });
        setAITestsGroundTruth(updatedAITestsGroundTruth);
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingAiBasedTestProposal'), { variant: 'error' });
        console.error('Error:', error);
        return;
      }
    }

    setAITests(updatedAITests);

    // Update the current card index
    setCurrentCardIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const selectClassesOfModel = (selectedClasses, modelID) => {
    setAvailableAIClasses((prevState) =>
      prevState.map((aiClass) =>
        aiClass.modelID === modelID
          ? {
              ...aiClass,
              active:
                selectedClasses.map((el) => el.aiClassName).includes(aiClass.aiClassName) &&
                selectedClasses.map((el) => el.modelID).includes(aiClass.modelID),
            }
          : aiClass
      )
    );

    setAITests((prevState) =>
      prevState.map((test) =>
        test.ai && test.ai.modelID === modelID
          ? {
              ...test,
              ai: {
                ...test.ai,
                active:
                  selectedClasses.map((el) => el.aiClassName).includes(test.ai.aiClassName) &&
                  selectedClasses.map((el) => el.modelID).includes(test.ai.modelID),
              },
            }
          : test
      )
    );
  };

  const selectAllClassesOfModel = (modelID, active) => {
    setAvailableAIClasses((prevState) =>
      prevState.map((el) => (el.modelID === modelID ? { ...el, active: active } : el))
    );

    setAITests((prevState) => {
      return prevState.map((test) => {
        if (test.ai && test.ai.modelID === modelID) {
          return { ...test, ai: { ...test.ai, active: active } };
        } else {
          return test;
        }
      });
    });
  };

  const isValidAITests = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.ai &&
        card.ai.modelID !== '' &&
        card.ai.aiClassName !== ''
      );
    });
  };

  const handleSaveError = (error, errors) => {
    if (error.response && error.response.data.message.startsWith('Duplicate card title:')) {
      errors.add(t('duplicateCardTitle') + ': "' + error.response.data.duplicateCardTitle + '"');
    } else {
      errors.add('errorWhileSavingRuleBasedTestProposal');
      console.error('Error:', error);
    }
  };

  const handleSave = async (updatedAITests) => {
    if (!isValidAITests(updatedAITests || aiTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'warning' });
      return;
    }

    const cardsToSave = (updatedAITests || aiTests).filter((card) => {
      const groundTruth = aiTestsGroundTruth.find((el) => el.id === card.id);
      return (
        (!card.id || !_.isEqual(card, groundTruth)) &&
        card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
        card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(aiCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card; // Assume API returns the saved card data
        } catch (error) {
          handleSaveError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((el) => el === null).length > 0) {
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingAiBasedTestProposal'), { variant: 'error' });
      }
      return;
    }

    updateTestsAndGroundTruth(savedCards);
    enqueueSnackbar(t('aiBasedTestsSavedSuccessfully'), { variant: 'success' });
  };

  const updateTestsAndGroundTruth = (savedCards) => {
    // Update aiTests with saved cards, reflecting changes or additions
    setAITests((currentTests) => {
      const updatedTests = [...currentTests];
      const filteredCardsWithoutIds = updatedTests.filter((card) => card.id);
      savedCards.forEach((savedCard) => {
        const index = filteredCardsWithoutIds.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          filteredCardsWithoutIds[index] = _.cloneDeep(savedCard);
        } else {
          filteredCardsWithoutIds.push(_.cloneDeep(savedCard));
        }
      });
      return filteredCardsWithoutIds;
    });

    // Update aiTestsGroundTruth to match the updated aiTests
    setAITestsGroundTruth((currentGroundTruth) => {
      const updatedGroundTruth = [...currentGroundTruth];
      savedCards.forEach((savedCard) => {
        const index = updatedGroundTruth.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          updatedGroundTruth[index] = _.cloneDeep(savedCard); // Deep copy updated items
        } else {
          updatedGroundTruth.push(_.cloneDeep(savedCard)); // Deep copy new items
        }
      });
      return updatedGroundTruth;
    });
  };

  const persistActivationChanges = async () => {
    const cardsToSave = aiTests.filter((card) => {
      const groundTruth = aiTestsGroundTruth.find((el) => el.id === card.id);
      return !_.isEqual(card, groundTruth);
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(aiCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card; // Assume API returns the saved card data
        } catch (error) {
          handleSaveError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((el) => el === null).length > 0) {
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingAiBasedTestProposal'), { variant: 'error' });
      }
      return;
    }

    setAddAIClassOpen(false);
    updateTestsAndGroundTruth(savedCards);
    enqueueSnackbar(t('aiBasedTestsSavedSuccessfully'), { variant: 'success' });
  };

  const updateCurrentCardIndex = (direction) => {
    // Find the AI class name for the current index in availableAIClasses
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Filter aiTests to find matching cards with the current AI class name
    const matchingCards = aiTests.filter(
      (test) =>
        test.ai && test.ai.aiClassName === currentAIClassName && test.ai.modelID === currentModelID
    );

    // Determine the next index based on the direction
    if (direction === 'inc' && currentCardIndex < matchingCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else if (direction === 'dec' && currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const getMatchingCards = () => {
    // First, determine the AI class name and the model ID for the current index
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Filter aiTests to find matching cards with the current AI class name
    const matchingCards = aiTests.filter((test) => {
      return (
        test.ai && test.ai.aiClassName === currentAIClassName && test.ai.modelID === currentModelID
      );
    });

    return matchingCards;
  };

  const renderCardComponent = () => {
    const matchingCards = getMatchingCards();

    if (matchingCards.length > 0) {
      return (
        <TestCard
          id={matchingCards[currentCardIndex]?.id || ''}
          editable={true}
          title={matchingCards[currentCardIndex]?.title}
          setTitle={setTitle}
          images={matchingCards[currentCardIndex]?.images}
          setImages={setImages}
          testObject={matchingCards[currentCardIndex]?.testObject}
          setTestObject={setTestObject}
          testMethod={matchingCards[currentCardIndex]?.testMethod}
          setTestMethod={setTestMethod}
          testLocation={matchingCards[currentCardIndex]?.testLocation}
          setTestLocation={setTestLocation}
          testComment={matchingCards[currentCardIndex]?.testComment}
          setTestComment={setTestComment}
          orderIdentifier={matchingCards[currentCardIndex]?.orderIdentifier}
          setOrderIdentifier={setOrderIdentifier}
          generation={t('AI')}
          explanation={matchingCards[currentCardIndex]?.explanation}
          index={currentCardIndex}
          metaIndex={currentIndex}
          numberCards={matchingCards.length}
          qualityStation={qualityStation}
          orderIdentifierMapping={orderIdentifierMapping}
          deleteImage={deleteImage}
          editors={matchingCards[currentCardIndex]?.editors}
          creator={matchingCards[currentCardIndex]?.creator}
          creationDate={matchingCards[currentCardIndex]?.creationDate}
          restoreChange={restoreChange}
        />
      );
    }
    return null;
  };

  const selectClassesDialogData = useMemo(() => {
    return mlModelNames.map((model) => {
      // Filter AI classes for the current model
      const modelAIClasses = availableAIClasses.filter((aiClass) => aiClass.modelID === model.id);

      // Determine which AI classes are currently active
      const activeClasses = modelAIClasses.filter((aiClass) => aiClass.active);

      // Determine if all classes are selected
      const allSelected = activeClasses.length === modelAIClasses.length;

      // Create options for the dropdown
      const options = [
        { label: t('selectAllClasses'), value: 'selectAll' },
        ...modelAIClasses.map((aiClass) => ({
          label: aiClass.aiClassName,
          value: aiClass.id,
          active: aiClass.active,
        })),
      ];

      // Create value for the dropdown
      const value = activeClasses.map((aiClass) => ({
        label: aiClass.aiClassName,
        value: aiClass.id,
      }));

      return {
        model,
        options,
        value,
        allSelected,
      };
    });
  }, [mlModelNames, availableAIClasses, t]);

  return (
    <div className="ai-wrapper">
      <div className="ai">
        <div className="ai-class-wrapper">
          <div className="ai-header">
            <Card className="card-2" variant="filled">
              <div className="text-wrapper-10">{t('addNewAIClasses')}</div>
            </Card>
          </div>
          <div className="ai-body">
            {availableAIClasses.map((availableAIClass, index) => {
              return (
                availableAIClass.active &&
                availableAIClass.modelActive && (
                  <div className="ai-test" key={index}>
                    <div className="ai-test-name-wrapper">
                      <QualitatioButton
                        text={availableAIClass.aiClassName}
                        fontSize={'calc(10px + 0.25vw)'}
                        width={'100%'}
                        onClick={() => {
                          if (index !== currentIndex) {
                            if (_.isEqual(aiTests, aiTestsGroundTruth)) {
                              setCurrentIndex(index);
                              setCurrentCardIndex(0);
                            } else {
                              setDiscardChangesWarningOpen(index);
                            }
                          }
                        }}
                        endIcon={
                          <ArrowForwardIosRoundedIcon style={{ fontSize: 'calc(10px + 0.25vw)' }} />
                        }
                        order={index === currentIndex ? 'primary' : 'secondary'}
                      />
                    </div>
                    <div className="ai-test-delete-wrapper">
                      <IconButton
                        className="delete-button"
                        variant="qualitatio"
                        squared={true}
                        onClick={() => {
                          deleteAIClass(index);
                        }}
                        style={{
                          backgroundColor: theme.palette.error.main,
                          width: '36px',
                          height: '36px',
                        }}
                      >
                        <DeleteForeverRoundedIcon color="white" />
                      </IconButton>
                    </div>
                  </div>
                )
              );
            })}
            <div className="add-ai">
              <IconButton
                variant="qualitatio"
                squared={true}
                onClick={() => {
                  if (_.isEqual(aiTests, aiTestsGroundTruth)) {
                    setAddAIClassOpen(true);
                  } else {
                    setDiscardChangesWarningOpen(-2);
                  }
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <AddRoundedIcon color="primary" />
              </IconButton>
              <QualitatioDialog
                title={t('addNewAIClasses')}
                description={t('addNewAIClassesInfoText')}
                open={addAIClassOpen}
                onClose={(event, reason) => {
                  if (reason && reason === 'backdropClick') {
                    return;
                  }
                  setAddAIClassOpen(false);
                }}
                maxWidth="sm"
                actions={[
                  {
                    label: t('save'),
                    onClick: () => {
                      persistActivationChanges();
                    },
                    order: 'primary',
                  },
                ]}
              >
                <div className="add-ai-class-wrapper">
                  {selectClassesDialogData.map(
                    ({ model, options, value, allSelected }, modelIndex) => (
                      <>
                        <QualitatioChipDropdown
                          label={`${t('selectClasses')} ${t('of')} ${model.name}`}
                          width={'100%'}
                          options={options}
                          value={value}
                          onChange={(newSelectedValues) => {
                            if (newSelectedValues.some((option) => option.value === 'selectAll')) {
                              selectAllClassesOfModel(model.id, !allSelected);
                            } else {
                              // Update individual class selections
                              const selectedIDs = newSelectedValues.map((option) => option.value);
                              const selectedClasses = availableAIClasses.filter((aiClass) =>
                                selectedIDs.includes(aiClass.id)
                              );
                              selectClassesOfModel(selectedClasses, model.id);
                            }
                          }}
                          multiple={true}
                          renderOption={(props, option, { selected }) => {
                            const { label, value, active } = option;
                            return (
                              <li key={value} {...props}>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckboxIcon fontSize="small" />}
                                  checked={selected}
                                  color="primary"
                                />
                                {label}
                              </li>
                            );
                          }}
                          filterSelectedOptions={false}
                          disableCloseOnSelect={true}
                        />
                      </>
                    )
                  )}
                </div>
              </QualitatioDialog>
            </div>
          </div>
        </div>
      </div>
      <div className="cards">
        <div className="test-card">{renderCardComponent()}</div>
        <div className="test-card-navigation">
          {getMatchingCards().map((test, index) => {
            return (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="basis-nav-buttons"
                onClick={() => setCurrentCardIndex(index)}
              >
                <circle
                  cx="10"
                  cy="10"
                  r="9.75"
                  fill={
                    index === currentCardIndex
                      ? 'var(--m-3refprimaryprimary-60)'
                      : 'var(--m3syslightsecondary-fixed)'
                  }
                  stroke="#0E0D13"
                  strokeWidth="0.5"
                />
              </svg>
            );
          })}
        </div>
        <div className="test-card-manipulation">
          {getMatchingCards().length > 0 && (
            <>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => setDeleteCardWarningOpen(true)}
                style={{ backgroundColor: theme.palette.error.main, width: '48px', height: '48px' }}
              >
                <DeleteForeverRoundedIcon color="white" fontSize="large" />
              </IconButton>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => updateCurrentCardIndex('dec')}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <ArrowBackIosNewRoundedIcon color="primary" />
              </IconButton>
            </>
          )}
          <IconButton
            className="add-button"
            variant="qualitatio"
            squared={true}
            disabled={
              !availableAIClasses[currentIndex] || !availableAIClasses[currentIndex]?.active
            }
            onClick={() => addCard()}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '48px',
              height: '48px',
            }}
          >
            <AddRoundedIcon color="primary" fontSize="large" />
          </IconButton>
          <CopilotButton
            overwriteFunction={handleCopilotButtonClick}
            disabled={
              !availableAIClasses[currentIndex] || !availableAIClasses[currentIndex]?.active
            }
          />
          {getMatchingCards().length > 0 && (
            <>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => updateCurrentCardIndex('inc')}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <ArrowForwardIosRoundedIcon color="primary" />
              </IconButton>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => handleSave()}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  width: '48px',
                  height: '48px',
                }}
              >
                <SaveIcon color="white" fontSize="large" />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <QualitatioDialog
        title={t('areYouSureYouWantToDeleteThisCard')}
        description={t('thisActionCannotBeReverted')}
        open={deleteCardWarningOpen}
        onClose={() => {
          setDeleteCardWarningOpen(false);
        }}
        maxWidth="sm"
        actions={[
          {
            label: t('cancel'),
            onClick: () => {
              setDeleteCardWarningOpen(false);
            },
            order: 'secondary',
          },
          {
            label: t('continue'),
            onClick: async () => {
              await deleteCard();
              setDeleteCardWarningOpen(false);
            },
            order: 'primary',
          },
        ]}
      />
      <QualitatioDialog
        title={t('unsavedChanges')}
        description={t(
          'youHaveUnsavedChangesWhichCanBeLostIfYouProceedNowAreYouSureYouWantToProceed'
        )}
        open={discardChangesWarningOpen !== -1}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') {
            return;
          }
          setDiscardChangesWarningOpen(-1);
        }}
        maxWidth="sm"
        actions={[
          {
            label: t('continue'),
            onClick: (event, reason) => {
              if (reason && reason === 'backdropClick') {
                return;
              }
              if (discardChangesWarningOpen === -2) {
                setAddAIClassOpen(true);
              } else {
                setCurrentIndex(discardChangesWarningOpen);
              }
              setCurrentCardIndex(0);
              setDiscardChangesWarningOpen(-1);
              setAITests(aiTestsGroundTruth);
            },
            order: 'secondary',
          },
          {
            label: t('cancel'),
            onClick: (event, reason) => {
              if (reason && reason === 'backdropClick') {
                return;
              }
              setDiscardChangesWarningOpen(-1);
            },
            order: 'primary',
          },
        ]}
      />
    </div>
  );
};

export default AITests;
