import PropTypes from 'prop-types';

const RuleTestCardsNavigation = ({ ruleCards, currentCardIndex, setCurrentCardIndex }) => {
  return (
    <>
      {ruleCards.map((el, index) => {
        return (
          <svg
            key={index}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="basis-nav-buttons"
            onClick={() => setCurrentCardIndex(index)}
          >
            <circle
              cx="10"
              cy="10"
              r="9.75"
              fill={
                index === currentCardIndex
                  ? 'var(--m-3refprimaryprimary-60)'
                  : 'var(--m3syslightsecondary-fixed)'
              }
              stroke="#0E0D13"
              strokeWidth="0.5"
            />
          </svg>
        );
      })}
    </>
  );
};

RuleTestCardsNavigation.propTypes = {
  ruleCards: PropTypes.array.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
};

export default RuleTestCardsNavigation;
