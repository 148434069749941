import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './QualitatioSelect.css';

export default function QualitatioSelect({
  value,
  onChange,
  optionProps,
  width = '100%',
  size = 'small',
  defaultText = '',
  compact = false,
  xs = false,
  readOnly = false,
  ...otherProps
}) {
  const { t } = useTranslation();

  // Determine the class name based on compact and xs props
  let className = 'qualitatio-select';
  if (compact) {
    className += ' compact-input';
  } else if (xs) {
    className += ' xs-input';
  }

  return (
    <FormControl
      sx={{ m: 1, minWidth: 120, width: width }}
      size={size}
      {...otherProps}
      className={className}
    >
      <InputLabel htmlFor="grouped-native-select">{defaultText || t('select')}</InputLabel>
      <Select
        native
        id="grouped-native-select"
        label={defaultText || t('select')}
        value={value}
        onChange={onChange}
        disabled={readOnly}
      >
        <option aria-label="None" value="" />
        {optionProps}
      </Select>
    </FormControl>
  );
}
