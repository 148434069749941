import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { staticCardEndpoint } from '../../../api/endpoints';
import { QualitatioDialog } from '../../../components';
import { useAuthStore } from '../../../store/auth.store';
import TestCard from '../TestCard';
import { uploadCardImageToServer } from '../utils';
import './BasisTests.css';

const BasisTests = ({
  currentIndex,
  setCurrentIndex,
  staticTestsGroundTruth,
  setStaticTestsGroundTruth,
  staticTests,
  setStaticTests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const [deletionWarningOpen, setDeletionWarningOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = staticTests.find((el) => el.id === id);
      if (card) {
        const index = staticTests.indexOf(card);
        if (index !== -1) {
          setCurrentIndex(index);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [staticTests]);

  const setTitle = (newTitle) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) => (index === currentIndex ? { ...card, title: newTitle } : card))
    );
  };

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, qualityStation, 'static');
    }
    const newStaticTests = [...staticTests];
    newStaticTests[currentIndex].images.push(newFileURL);
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex
          ? { ...card, images: [...new Set([...card.images, newFileURL])] }
          : card
      )
    );
  };

  const setTestObject = (newTestObject) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex ? { ...card, testObject: newTestObject } : card
      )
    );
  };

  const setTestMethod = (newTestMethod) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex ? { ...card, testMethod: newTestMethod } : card
      )
    );
  };

  const setTestLocation = (newTestLocation) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex ? { ...card, testLocation: newTestLocation } : card
      )
    );
  };

  const setTestComment = (newTestComment) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex ? { ...card, testComment: newTestComment } : card
      )
    );
  };

  const setOrderIdentifier = (newOrderIdentifier) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) =>
        index === currentIndex ? { ...card, orderIdentifier: newOrderIdentifier } : card
      )
    );
  };

  const deleteImage = (imageIndex) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) => {
        if (index === currentIndex) {
          const newImages = [...card.images];
          newImages.splice(imageIndex, 1);
          return { ...card, images: newImages };
        }
        return card;
      })
    );
  };

  const addCard = () => {
    const newCard = {
      qualityStation: qualityStation,
      type: 'Basis',
      title: '', // Set initial title, possibly empty or a default value
      images: [],
      testObject: '',
      testLocation: '',
      testMethod: '',
      testComment: '',
      orderIdentifier: 0,
      positionIdentifier: 1000, // or any logic for assigning this
      explanation: t('fromStaticTestPlan'),
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    const newStaticTests = [...staticTests, newCard];

    setStaticTests(newStaticTests);
    setCurrentIndex(newStaticTests.length - 1); // Set to the index of the new card
  };

  const deleteCard = async () => {
    const cardToDelete = staticTests[currentIndex];

    const updatedStaticTests = [...staticTests];
    updatedStaticTests.splice(currentIndex, 1);

    if (cardToDelete.id) {
      const updatedStaticTestsGroundTruth = staticTestsGroundTruth.filter(
        (card) => card.id !== cardToDelete.id
      );
      try {
        await axiosClient.delete(staticCardEndpoint, {
          data: {
            cardID: cardToDelete.id,
          },
        });
        enqueueSnackbar(t('staticTestProposalDeletedSuccessfully'), { variant: 'success' });
        setStaticTestsGroundTruth(updatedStaticTestsGroundTruth);
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingStaticTestProposal'), { variant: 'error' });
        console.error('Error:', error);
        return;
      }
    }

    setStaticTests(updatedStaticTests);
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
  };

  const isValidCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== ''
      );
    });
  };

  const handleSaveError = (error, errors) => {
    if (error.response && error.response.data.message.startsWith('Duplicate card title:')) {
      errors.add(t('duplicateCardTitle') + ': "' + error.response.data.duplicateCardTitle + '"');
    } else {
      errors.add('errorWhileSavingStaticTestProposal');
      console.error('Error:', error);
    }
  };

  const handleSave = async (updatedStaticTests) => {
    if (!isValidCards(updatedStaticTests || staticTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'error' });
      return;
    }

    const cardsToSave = (updatedStaticTests || staticTests).filter((card, index) => {
      const groundTruth = staticTestsGroundTruth[index];
      return !card.id || !_.isEqual(card, groundTruth);
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(staticCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card; // Assume API returns the saved card data
        } catch (error) {
          handleSaveError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((card) => card === null).length > 0) {
      // Check for specific "Duplicate card title" error
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingStaticTestProposal'), { variant: 'error' });
      }
      return;
    }

    // Update the tests and ground truth arrays
    updateTestsAndGroundTruth(savedCards);

    enqueueSnackbar(t('staticTestProposalSavedSuccessfully'), { variant: 'success' });
  };

  const updateTestsAndGroundTruth = (savedCards) => {
    // Update staticTests with saved cards, reflecting changes or additions
    setStaticTests((currentTests) => {
      const updatedTests = [...currentTests];
      const filteredCardsWithoutIds = updatedTests.filter((card) => card.id);
      savedCards.forEach((savedCard) => {
        const index = filteredCardsWithoutIds.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          filteredCardsWithoutIds[index] = _.cloneDeep(savedCard);
        } else {
          filteredCardsWithoutIds.push(_.cloneDeep(savedCard));
        }
      });

      return filteredCardsWithoutIds;
    });

    // Update staticTestsGroundTruth to match the updated staticTests
    // Ensuring ground truth is synchronized with the latest state
    setStaticTestsGroundTruth((currentGroundTruth) => {
      const updatedGroundTruth = [...currentGroundTruth];
      savedCards.forEach((savedCard) => {
        const index = updatedGroundTruth.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          updatedGroundTruth[index] = _.cloneDeep(savedCard); // Deep copy updated items
        } else {
          updatedGroundTruth.push(_.cloneDeep(savedCard)); // Deep copy new items
        }
      });
      return updatedGroundTruth;
    });
  };

  const updateCurrentIndex = (direction) => {
    if (direction === 'inc') {
      if (currentIndex < staticTests.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    <div className="cards">
      <div className="test-card">
        {staticTests[currentIndex] && (
          <TestCard
            id={staticTests[currentIndex]?.id || ''}
            editable={true}
            title={staticTests[currentIndex]?.title}
            setTitle={setTitle}
            images={staticTests[currentIndex]?.images}
            setImages={setImages}
            testObject={staticTests[currentIndex]?.testObject}
            setTestObject={setTestObject}
            testMethod={staticTests[currentIndex]?.testMethod}
            setTestMethod={setTestMethod}
            testLocation={staticTests[currentIndex]?.testLocation}
            setTestLocation={setTestLocation}
            testComment={staticTests[currentIndex]?.testComment}
            setTestComment={setTestComment}
            orderIdentifier={staticTests[currentIndex]?.orderIdentifier}
            setOrderIdentifier={setOrderIdentifier}
            generation={t('Basis')}
            explanation={t('fromStaticTestPlan')}
            index={currentIndex}
            numberCards={staticTests.length}
            qualityStation={qualityStation}
            orderIdentifierMapping={orderIdentifierMapping}
            deleteImage={deleteImage}
            editors={staticTests[currentIndex]?.editors}
            creator={staticTests[currentIndex]?.creator}
            creationDate={staticTests[currentIndex]?.creationDate}
            restoreChange={restoreChange}
          />
        )}
      </div>
      <div className="test-card-navigation">
        {staticTests.map((test, index) => {
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="basis-nav-buttons"
              onClick={() => setCurrentIndex(index)}
            >
              <circle
                cx="10"
                cy="10"
                r="9.75"
                fill={
                  index === currentIndex
                    ? 'var(--m-3refprimaryprimary-60)'
                    : 'var(--m3syslightsecondary-fixed)'
                }
                stroke="#0E0D13"
                strokeWidth="0.5"
              />
            </svg>
          );
        })}
      </div>
      <div className="test-card-manipulation">
        {staticTests[currentIndex] && (
          <>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => setDeletionWarningOpen(true)}
              style={{ backgroundColor: theme.palette.error.main, width: '48px', height: '48px' }}
            >
              <DeleteForeverRoundedIcon color="white" fontSize="large" />
            </IconButton>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => updateCurrentIndex('dec')}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <ArrowBackIosNewRoundedIcon color="primary" />
            </IconButton>
          </>
        )}
        <IconButton
          className="add-button"
          variant="qualitatio"
          squared={true}
          onClick={() => addCard()}
          style={{
            backgroundColor: theme.palette.success.secondary,
            width: '48px',
            height: '48px',
          }}
        >
          <AddRoundedIcon color="primary" fontSize="large" />
        </IconButton>
        {staticTests[currentIndex] && (
          <>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => updateCurrentIndex('inc')}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <ArrowForwardIosRoundedIcon color="primary" />
            </IconButton>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => handleSave()}
              style={{ backgroundColor: theme.palette.primary.main, width: '48px', height: '48px' }}
            >
              <SaveIcon color="white" fontSize="large" />
            </IconButton>
          </>
        )}
      </div>
      <QualitatioDialog
        title={t('areYouSureYouWantToDeleteThisCard')}
        description={t('thisActionCannotBeReverted')}
        open={deletionWarningOpen}
        onClose={() => {
          setDeletionWarningOpen(false);
        }}
        maxWidth="sm"
        actions={[
          {
            label: t('cancel'),
            onClick: () => {
              setDeletionWarningOpen(false);
            },
            order: 'secondary',
          },
          {
            label: t('continue'),
            onClick: () => {
              deleteCard();
              setDeletionWarningOpen(false);
            },
            order: 'primary',
          },
        ]}
      />
    </div>
  );
};

export default BasisTests;
