import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { getQualityStationAllRulesEndpoint, ruleCardEndpoint } from '../../../api/endpoints';
import { Card } from '../../../components/Card';
import { useAuthStore } from '../../../store/auth.store';
import PropTypes from 'prop-types';
import './RuleTests.css';

import RuleTestCard from './ruleTests/RuleTestCard';
import RulesOverview from './ruleTests/RulesOverview';
import RuleTestCardsNavigation from './ruleTests/RuleTestCardsNavigation';
import RuleTestCardsManipulation from './ruleTests/RuleTestCardsManipulation';
import CardEditDialogs from './components/CardEditDialogs';

const RuleTests = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  ruleTestsGroundTruth,
  setRuleTestsGroundTruth,
  ruleTests,
  setRuleTests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const { enqueueSnackbar } = useSnackbar();
  const [ruleEditOpen, setRuleEditOpen] = useState([]);

  const [rules, setRules] = useState([]);
  const [rulesGroundTruth, setRulesGroundTruth] = useState([]);

  const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
  // Used to remember the user action that triggered the confirmation of discarding unsaved changes
  // -1: No warning open, -2 + cardIndex: Edit rule, -3: Add rule, cardIndex: View rule
  const [discardChangesWarningOpen, setDiscardChangesWarningOpen] = useState(-1);
  const [deleteRuleIndex, setDeleteRuleIndex] = useState(0);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getQualityStationAllRules();
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = ruleTests.find((el) => el.id === id);
      if (card) {
        const index = rules.findIndex((el) => _.isEqual(el, card.rule));
        const cardIndex = ruleTests.filter((el) => _.isEqual(card.rule, el.rule)).indexOf(card);
        if (index !== -1 && cardIndex !== -1) {
          setCurrentIndex(index);
          setCurrentCardIndex(cardIndex);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [rules, ruleTests]);

  const getQualityStationAllRules = async () => {
    try {
      const response = await axiosClient.post(getQualityStationAllRulesEndpoint, {
        qualityStationName: qualityStation,
      });
      setRules(response.data.rules);
      setRulesGroundTruth(response.data.rules);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingQualityStationAllRules'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  // ------- Rule test card manipulation functions -------
  const handleAddCard = () => {
    const newCard = {
      qualityStation: qualityStation,
      type: 'Rule',
      title: '', // Initial empty title
      images: [],
      testObject: '',
      testLocation: '',
      testMethod: '',
      testComment: '',
      orderIdentifier: 0,
      positionIdentifier: 1000, // Assign a position identifier
      explanation: rules[currentIndex].ruleName,
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      rule: { ...rules[currentIndex] }, // Clone the current rule object
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    setRuleTests((prevState) => [...prevState, newCard]);
    setCurrentCardIndex(ruleTests.filter((el) => _.isEqual(el.rule, rules[currentIndex])).length);
  };

  const deleteCard = async () => {
    // Find the index of the card to delete in the ruleTests array
    const indexToRemove = ruleTests.findIndex((card, index) => {
      if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
        const cardIndexInSubarray = ruleTests
          .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
          .indexOf(card);
        return cardIndexInSubarray === currentCardIndex;
      }
      return false;
    });

    // Filter out the card at the found index
    const cardToDelete = ruleTests[indexToRemove];
    const updatedRuleTests = [...ruleTests];
    updatedRuleTests.splice(indexToRemove, 1);

    if (cardToDelete.id) {
      const updatedRuleTestsGroundTruth = ruleTestsGroundTruth.filter(
        (card) => card.id !== cardToDelete.id
      );
      try {
        await axiosClient.delete(ruleCardEndpoint, {
          data: { cardID: cardToDelete.id },
        });
        enqueueSnackbar(t('cardDeletedSuccessfully'), { variant: 'success' });
        setRuleTestsGroundTruth(updatedRuleTestsGroundTruth);
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingRuleBasedTestProposal'), { variant: 'error' });
        console.error('Error:', error);
        return;
      }
    }

    setRuleTests(updatedRuleTests);

    // Adjust the currentCardIndex after deletion
    setCurrentCardIndex((prevIndex) =>
      prevIndex > 0 && prevIndex - 1 < updatedRuleTests.length ? prevIndex - 1 : 0
    );
  };

  const handleDeleteCard = () => {
    setDeleteCardWarningOpen(true);
  };

  const isValidCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.rule?.logic?.length > 0 &&
        card.rule?.logic?.every((logic) => {
          return (
            logic.fieldOneStreamName !== '' &&
            logic.fieldOneFieldName !== '' &&
            logic.operator !== '' &&
            logic.fieldTwoValue !== ''
          );
        })
      );
    });
  };

  const handleSaveCardError = (error, errors) => {
    if (error.response && error.response.data.message.startsWith('Duplicate card title:')) {
      errors.add(t('duplicateCardTitle') + ': "' + error.response.data.duplicateCardTitle + '"');
    } else {
      errors.add('errorWhileSavingRuleBasedTestProposal');
      console.error('Error:', error);
    }
  };

  const handleSaveCard = async (updatedRuleTests) => {
    const filteredCards = (updatedRuleTests || ruleTests).filter((card) =>
      _.isEqual(card.rule, rules[currentIndex])
    );
    if (!isValidCards(filteredCards)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'warning' });
      return;
    } else if (rules.filter((rule) => rule.ruleName === rules[currentIndex].ruleName).length > 1) {
      enqueueSnackbar(t('ruleNameAlreadyExists'), { variant: 'warning' });
      return;
    }

    const cardsToSave = (updatedRuleTests || ruleTests).filter((card, index) => {
      const groundTruth = ruleTestsGroundTruth.find((el) => el.id === card.id);
      return (
        (!card.id || !_.isEqual(card, groundTruth)) && _.isEqual(card.rule, rules[currentIndex])
      );
    });

    const errors = new Set();

    const savedCards = await Promise.all(
      cardsToSave.map(async (card) => {
        try {
          const response = await axiosClient.post(ruleCardEndpoint, {
            qualityStation: qualityStation,
            card: card,
            userID: user.id,
          });
          return response.data.card; // Assume API returns the saved card data
        } catch (error) {
          handleSaveCardError(error, errors);
          return null;
        }
      })
    );

    if (errors.size > 0 || savedCards.filter((el) => el === null).length > 0) {
      const duplicateTitleError = Array.from(errors).find((e) =>
        e.startsWith(t('duplicateCardTitle'))
      );
      if (duplicateTitleError) {
        enqueueSnackbar(duplicateTitleError, { variant: 'error' });
      } else {
        enqueueSnackbar(t('errorWhileSavingRuleBasedTestProposal'), { variant: 'error' });
      }
      return;
    }

    _updateTestsAndGroundTruth(savedCards);
    await getQualityStationAllRules();

    enqueueSnackbar(t('ruleBasedTestingSavedSuccessfully'), { variant: 'success' });
  };

  const _updateTestsAndGroundTruth = (savedCards) => {
    // Update ruleTests with saved cards, reflecting changes or additions
    setRuleTests((currentTests) => {
      const updatedTests = [...currentTests];
      const filteredCardsWithoutIds = updatedTests.filter((card) => card.id);
      savedCards.forEach((savedCard) => {
        const index = filteredCardsWithoutIds.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          filteredCardsWithoutIds[index] = _.cloneDeep(savedCard);
        } else {
          filteredCardsWithoutIds.push(_.cloneDeep(savedCard));
        }
      });

      return filteredCardsWithoutIds;
    });

    // Update ruleTestsGroundTruth to match the updated ruleTests
    // Ensuring ground truth is synchronized with the latest state
    setRuleTestsGroundTruth((currentGroundTruth) => {
      const updatedGroundTruth = [...currentGroundTruth];
      savedCards.forEach((savedCard) => {
        const index = updatedGroundTruth.findIndex((test) => test.id === savedCard.id);
        if (index !== -1) {
          updatedGroundTruth[index] = _.cloneDeep(savedCard); // Deep copy updated items
        } else {
          updatedGroundTruth.push(_.cloneDeep(savedCard)); // Deep copy new items
        }
      });
      return updatedGroundTruth;
    });
  };

  const _updateCurrentCardIndex = (direction) => {
    const matchingCardsLength = ruleTests.filter((card) =>
      _.isEqual(card.rule, rules[currentIndex])
    ).length;

    if (direction === 'inc') {
      if (currentCardIndex < matchingCardsLength - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(currentCardIndex - 1);
      }
    }
  };

  const handlePreviousCard = () => {
    _updateCurrentCardIndex('dec');
  };

  const handleNextCard = () => {
    _updateCurrentCardIndex('inc');
  };

  // ------- Rule manipulation functions -------
  const openRuleEdit = (index) => {
    setRuleEditOpen((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  // Discard changes and shows the corresponding UI according to user actions before discarding
  const discardChanges = () => {
    // User clicked editing button
    if (String(discardChangesWarningOpen).startsWith('-2')) {
      openRuleEdit(Number(String(discardChangesWarningOpen).slice(2)));
      setCurrentIndex(Number(String(discardChangesWarningOpen).slice(2)));
      setRuleTests(ruleTestsGroundTruth);
      setRules(rulesGroundTruth);
      // User clicked add rule button
    } else if (discardChangesWarningOpen === -3) {
      addRule();
      // User clicked view rule button
    } else {
      setCurrentIndex(discardChangesWarningOpen);
      setRuleTests(ruleTestsGroundTruth);
      setRules(rulesGroundTruth);
    }
    setCurrentCardIndex(0);
    setDiscardChangesWarningOpen(-1);
  };

  const handleEditRule = (index) => {
    if (index === currentIndex) {
      openRuleEdit(index);
    } else {
      if (_.isEqual(ruleTests, ruleTestsGroundTruth)) {
        openRuleEdit(index);
        setCurrentCardIndex(0);
        setCurrentIndex(index);
      } else {
        setDiscardChangesWarningOpen(Number('-2' + index));
      }
    }
  };

  const handleDeleteRule = (index) => {
    setDeleteRuleIndex(index);
  };

  const deleteRule = async () => {
    // Filter out cards and rules that match the ruleName
    const updatedRuleTests = ruleTests.filter(
      (card) => !_.isEqual(card.rule, rules[deleteRuleIndex])
    );
    const updatedRuleTestsGroundTruth = ruleTestsGroundTruth.filter(
      (card) => !_.isEqual(card.rule, rules[deleteRuleIndex])
    );
    const updatedRules = rules.filter((rule) => !_.isEqual(rule, rules[deleteRuleIndex]));

    const updatedRulesGroundTruth = rulesGroundTruth.filter(
      (rule) => !_.isEqual(rule, rules[deleteRuleIndex])
    );

    const cardsToDelete = ruleTests.filter((card) => _.isEqual(card.rule, rules[deleteRuleIndex]));

    if (cardsToDelete.length > 0) {
      try {
        await Promise.all(
          cardsToDelete.map(async (card) => {
            if (card.id) {
              await axiosClient.delete(ruleCardEndpoint, {
                data: {
                  cardID: card.id,
                },
              });
            }
          })
        );
        if (cardsToDelete.some((card) => card.id)) {
          enqueueSnackbar(t('ruleAndCardsDeletedSuccessfully'), { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingRuleBasedTestProposal'), { variant: 'error' });
        console.error('Error:', error);
        return;
      }
    }

    setRuleTests(updatedRuleTests);
    setRules(updatedRules);
    setRuleTestsGroundTruth(updatedRuleTestsGroundTruth);
    setRulesGroundTruth(updatedRulesGroundTruth);

    // Update currentIndex after deletion
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
    setCurrentCardIndex(deleteRuleIndex === currentIndex ? 0 : currentCardIndex);
  };

  const handleViewRule = (index) => {
    if (_.isEqual(ruleTests, ruleTestsGroundTruth)) {
      setCurrentIndex(index);
      setCurrentCardIndex(0);
    } else {
      setDiscardChangesWarningOpen(index);
    }
  };

  const handleAddRule = () => {
    if (_.isEqual(ruleTests, ruleTestsGroundTruth) && _.isEqual(rules, rulesGroundTruth)) {
      addRule();
    } else {
      setDiscardChangesWarningOpen(-3);
    }
  };

  const addRule = () => {
    const newRuleName = t('newRule');

    // New rule object for rules array
    const newRule = {
      ruleName: newRuleName,
      logic: [],
    };

    // New card object for ruleTests array
    const newCard = {
      qualityStation: qualityStation,
      type: 'Rule',
      title: '', // Initial empty title
      images: [],
      testObject: '',
      testLocation: '',
      testMethod: '',
      testComment: '',
      orderIdentifier: 0,
      positionIdentifier: 1000, // Assign a position identifier
      explanation: newRuleName,
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      rule: newRule,
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    setRules([...rulesGroundTruth, newRule]);
    setRuleTests([...ruleTestsGroundTruth, newCard]);
    setCurrentIndex(rulesGroundTruth.length); // Update currentIndex to point to the new rule
    setCurrentCardIndex(0); // Reset current card index
  };

  const getMatchingCards = () => {
    return ruleTests.filter((card) => _.isEqual(card.rule, rules[currentIndex]));
  };

  return (
    <div className="rules-wrapper">
      <div className="rules">
        <div className="rule-wrapper">
          <div className="rule-header">
            <Card className="card-2" variant="filled">
              <div className="text-wrapper-10">{t('addNewRule')}</div>
            </Card>
          </div>
          <div className="rule-body">
            <RulesOverview
              rules={rules}
              setRules={setRules}
              ruleTests={ruleTests}
              setRuleTests={setRuleTests}
              currentIndex={currentIndex}
              ruleEditOpen={ruleEditOpen}
              setRuleEditOpen={setRuleEditOpen}
              handleViewRule={handleViewRule}
              handleAddRule={handleAddRule}
              handleSave={handleSaveCard}
              handleEditRule={handleEditRule}
              handleDeleteRule={handleDeleteRule}
              deleteRule={deleteRule}
            />
          </div>
        </div>
      </div>
      <div className="cards">
        <RuleTestCard
          matchingCards={getMatchingCards()}
          rules={rules}
          setRules={setRules}
          ruleTests={ruleTests}
          setRuleTests={setRuleTests}
          currentIndex={currentIndex}
          currentCardIndex={currentCardIndex}
          qualityStation={qualityStation}
          orderIdentifierMapping={orderIdentifierMapping}
          restoreChange={restoreChange}
        />
        <div className="test-card-navigation">
          <RuleTestCardsNavigation
            ruleCards={getMatchingCards()}
            currentCardIndex={currentCardIndex}
            setCurrentCardIndex={setCurrentCardIndex}
          />
        </div>
        <div className="test-card-manipulation">
          <RuleTestCardsManipulation
            ruleCards={getMatchingCards()}
            isAddCardDisabled={!rules[currentIndex]}
            handleDeleteCard={handleDeleteCard}
            handleAddCard={handleAddCard}
            handleSaveCard={handleSaveCard}
            handlePreviousCard={handlePreviousCard}
            handleNextCard={handleNextCard}
          />
        </div>
      </div>
      <CardEditDialogs
        deleteCardWarningDialog={{
          deleteCardWarningOpen,
          setDeleteCardWarningOpen,
          deleteCard,
        }}
        discardChangesWarningDialog={{
          discardChangesWarningOpen,
          setDiscardChangesWarningOpen,
          discardChanges,
        }}
      />
    </div>
  );
};

RuleTests.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  ruleTestsGroundTruth: PropTypes.array.isRequired,
  setRuleTestsGroundTruth: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  setQualityStationStats: PropTypes.func.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  restoreChange: PropTypes.func.isRequired,
};

export default RuleTests;
