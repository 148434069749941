import { Autocomplete, Avatar, Chip, TextField } from '@mui/material';
import React from 'react';
import './QualitatioChipDropdown.css';

export default function QualitatioChipDropdown({
  options,
  label,
  value,
  onChange,
  width,
  minWidth,
  margin,
  placeholder,
  renderOption,
  groupBy,
  disabled = false,
  compact = false,
  xs = false,
  readOnly = false,
  multiple = false,
  filterSelectedOptions = true,
  disableCloseOnSelect = false,
  ...otherProps
}) {
  const renderTags = (value, getTagProps) =>
    value?.map((option, index) => (
      <Chip
        avatar={
          option.profileImage ? (
            <Avatar src={option.profileImage} alt={option.label[0]} />
          ) : (
            <Avatar>{option.label[0]}</Avatar>
          )
        }
        label={option.label}
        {...getTagProps({ index })}
        disabled={disabled}
        size="small"
      />
    ));

  const style = {
    width: width || 'auto',
    minWidth: minWidth || 'auto',
    background: 'white',
    margin: margin || '0',
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      options={options}
      multiple={multiple}
      readOnly={readOnly}
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions={filterSelectedOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      renderTags={renderTags}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      style={style}
      renderOption={renderOption || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          className={`${compact ? 'qualitatio-chips-dropdown-compact' : ''} ${xs ? 'xs-chip-dropdown' : ''}`}
          variant="outlined"
          InputProps={{ ...params.InputProps, readOnly }}
          style={style}
        />
      )}
      {...otherProps}
    />
  );
}
