import { Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import { getTestCardFeedbackEndpoint, getTestCardPerformanceEndpoint } from '../../api/endpoints';
import QualitatioRating from '../../components/QualitatioRating/QualitatioRating';
import './CardFeedback.css';

const CardFeedback = ({ cardID }) => {
  const { t } = useTranslation();
  const [testCardPerformance, setTestCardPerformance] = useState(null);
  const [testCardFeedback, setTestCardFeedback] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTestCardPerformance();
    getTestCardFeedback();
  }, [cardID]);

  const getTestCardPerformance = async () => {
    try {
      const response = await axiosClient.post(getTestCardPerformanceEndpoint, {
        cardID: cardID,
      });
      if (response.data.testCardPerformance) {
        setTestCardPerformance(response.data.testCardPerformance);
      } else {
        enqueueSnackbar(t('errorWhileGettingTestCardPerformance'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getTestCardFeedback = async () => {
    try {
      const response = await axiosClient.post(getTestCardFeedbackEndpoint, {
        cardID: cardID,
      });
      if (response.data.testCardFeedback) {
        setTestCardFeedback(response.data.testCardFeedback);
      } else {
        enqueueSnackbar(t('errorWhileGettingTestCardFeedback'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const time = testCardPerformance?.testCardPerformanceTimeSeries?.map(
    (entry) => new Date(entry.time),
  );
  const successRates = testCardPerformance?.testCardPerformanceTimeSeries?.map((entry) =>
    parseFloat(entry.successRate),
  );

  const series = [
    {
      data: successRates,
      type: 'line',
      label: 'Success Rate',
      area: false,
      stack: 'total',
      showMark: false,
    },
  ];

  return (
    <div className="test-card-stats-wrapper">
      {/* Test Card Performance Section */}
      <div className="test-card-performance">
        <div className="performance-rate">{testCardPerformance?.testCardPerformance}</div>
        <Box
          className="performance-graph"
          style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        >
          {series?.length > 0 && time?.length > 0 ? (
            <LineChart
              className="nathan-chart"
              width={400}
              height={300}
              series={series}
              // colors={["rgba(31, 190, 188, 1)", "rgba(204, 232, 231, 1)", "rgba(224, 227, 226, 1)", "rgba(255, 218, 214, 1)"]}
              xAxis={[
                {
                  scaleType: 'time',
                  data: time,
                  min: time[0]?.getTime(),
                  max: time[time.length - 1]?.getTime(),
                },
              ]}
              sx={{
                '--ChartsLegend-itemMarkSize': '10px',
              }}
            />
          ) : (
            <div className="no-data">{t('noDataAvailable')}</div>
          )}
        </Box>
      </div>

      <div className="separator"></div>

      {/* Test Card Feedback Section */}
      <div className="test-card-feedback">
        <div className="feedback-rating">
          <QualitatioRating
            value={Number(testCardFeedback?.testCardAverageFeedback)}
            precision={0.01}
            max={3}
            size="3rem"
            readOnly
          />
          {/* Place your star rating component here */}
          <div className="average-feedback">{testCardFeedback?.testCardAverageFeedback}</div>
        </div>
        <div className="feedback-comments">
          {testCardFeedback?.testCardComments.map((comment, idx) => (
            <>
              {comment.feedback?.length > 0 && (
                <div key={idx} className="comment">
                  <div className="comment-user">
                    <img src={comment.userImage} alt={comment.userName} className="user-image" />
                    <div className="user-name-time-wrapper">
                      <span className="user-name">{comment.userName}</span>
                      <span className="comment-time">
                        {new Date(comment.time).toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="comment-text">{comment.feedback}</div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardFeedback;
