import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { sendFeedbackEndpoint } from '../../../api/endpoints';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import QualitatioChipArray from '../../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import { useAuthStore } from '../../../store/auth.store';
import './TestFeedback.css';

export default function TestFeedback({
  productID,
  cardID,
  testTitle,
  isFeedbackOpen,
  setIsFeedbackOpen,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
}) {
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const theme = useTheme();
  const { t } = useTranslation();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && value) {
      document.getElementById('link').click();
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleSelection = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  const sendFeedback = async () => {
    if (selectedIndex === 1 && !value) {
      enqueueSnackbar(t('pleaseAddComment'), { variant: 'warning' });
      return;
    }
    try {
      const response = await axiosClient.post(sendFeedbackEndpoint, {
        productID: productID,
        cardID: cardID,
        testTitle: testTitle,
        feedback: value,
        rating: selectedIndex,
        userID: user.id,
        userName: user.name,
        userImage: user.profileImage,
      });
      enqueueSnackbar(t('thankYouForYourFeedback'), { variant: 'success' });
      console.log(response);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('errorSendingFeedback'), { variant: 'error' });
    }
    setIsFeedbackOpen(false);
  };

  return (
    <>
      {recommenderMenuOpen && (
        <div className={'test-feedback-layout-' + (isFeedbackOpen ? 'display' : 'hide')}>
          <div className="test-feedback-header">{t('thanksForYourFeedback')}</div>
          <div className="test-feedback-icon">
            <img className="test-feedback-image" alt="Mask group" src="/images/feedback.svg" />
          </div>
          <div className="test-feedback-text">{t('howHelpfulWasThisRecommendation')}</div>
          <div className="test-feedback-input">
            <QualitatioChipArray
              chipData={[
                {
                  label: t('bad'),
                  avatar: '/images/StarEmpty.png',
                  backgroundColor: selectedIndex === 1 ? theme.palette.primary.main : 'default',
                  onClick: () => handleSelection(1),
                },
                {
                  label: t('medium'),
                  avatar: '/images/StarHalf.png',
                  backgroundColor: selectedIndex === 2 ? theme.palette.primary.main : 'default',
                  onClick: () => handleSelection(2),
                },
                {
                  label: t('good'),
                  avatar: '/images/StarFull.png',
                  backgroundColor: selectedIndex === 3 ? theme.palette.primary.main : 'default',
                  onClick: () => handleSelection(3),
                },
              ]}
              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
            />
            <QualitatioInput
              label={t('addComment')}
              type="text"
              placeholder={t('addComment') + '...'}
              width={'80%'}
              size={'small'}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={handleKeyPress}
              value={value}
            />
          </div>
          <div className="test-feedback-buttons">
            <QualitatioButton
              text={t('save')}
              id="link"
              order="primary"
              width={'80%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              endIcon={<ArrowForwardIosRoundedIcon />}
              disabled={!selectedIndex}
              onClick={sendFeedback}
            />
            <QualitatioButton
              text={t('cancel')}
              order="secondary"
              width={'80%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              startIcon={<ArrowBackIosNewRoundedIcon />}
              onClick={() => setIsFeedbackOpen(false)}
            />
          </div>
          <div className="test-feedback-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      )}
    </>
  );
}
