import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TestCard from '../../TestCard';
import { uploadCardImageToServer } from '../../utils';

const RuleTestCard = ({
  matchingCards,
  rules,
  ruleTests,
  setRuleTests,
  currentIndex,
  currentCardIndex,
  qualityStation,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();

  const setTitle = (newTitle) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, title: newTitle };
          }
        }
        return card;
      })
    );
  };

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, qualityStation, 'rules');
    }
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, images: [...card.images, newFileURL] };
          }
        }
        return card;
      })
    );
  };

  const deleteImage = (imageIndex) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            const updatedImages = card.images.filter((_, index) => index !== imageIndex);
            return { ...card, images: updatedImages };
          }
        }
        return card;
      })
    );
  };

  const setTestObject = (newTestObject) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, testObject: newTestObject };
          }
        }
        return card;
      })
    );
  };

  const setTestMethod = (newTestMethod) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, testMethod: newTestMethod };
          }
        }
        return card;
      })
    );
  };

  const setTestLocation = (newTestLocation) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, testLocation: newTestLocation };
          }
        }
        return card;
      })
    );
  };

  const setTestComment = (newTestComment) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, testComment: newTestComment };
          }
        }
        return card;
      })
    );
  };

  const setOrderIdentifier = (newOrderIdentifier) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            return { ...card, orderIdentifier: newOrderIdentifier };
          }
        }
        return card;
      })
    );
  };

  return (
    matchingCards.length > 0 && (
      <TestCard
        id={matchingCards[currentCardIndex]?.id || ''}
        editable={true}
        title={matchingCards[currentCardIndex]?.title}
        setTitle={setTitle}
        images={matchingCards[currentCardIndex]?.images}
        setImages={setImages}
        testObject={matchingCards[currentCardIndex]?.testObject}
        setTestObject={setTestObject}
        testMethod={matchingCards[currentCardIndex]?.testMethod}
        setTestMethod={setTestMethod}
        testLocation={matchingCards[currentCardIndex]?.testLocation}
        setTestLocation={setTestLocation}
        testComment={matchingCards[currentCardIndex]?.testComment}
        setTestComment={setTestComment}
        orderIdentifier={matchingCards[currentCardIndex]?.orderIdentifier}
        setOrderIdentifier={setOrderIdentifier}
        generation={t('Rule')}
        explanation={matchingCards[currentCardIndex]?.rule.ruleName}
        index={currentCardIndex}
        metaIndex={currentIndex}
        numberCards={matchingCards.length}
        qualityStation={qualityStation}
        orderIdentifierMapping={orderIdentifierMapping}
        deleteImage={deleteImage}
        editors={matchingCards[currentCardIndex]?.editors}
        creationDate={matchingCards[currentCardIndex]?.creationDate}
        creator={matchingCards[currentCardIndex]?.creator}
        restoreChange={restoreChange}
      />
    )
  );
};

RuleTestCard.propTypes = {
  matchingCards: PropTypes.array.isRequired,
  rules: PropTypes.array.isRequired,
  setRules: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  qualityStation: PropTypes.object.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  restoreChange: PropTypes.func.isRequired,
};

export default RuleTestCard;
