import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './QualitatioTable.css';
import createLabelText from './tableLanguages';

const QualitatioToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      {/*       <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
};

const QualitatioTable = ({
  rows = [],
  columns = [],
  height = 400,
  maxHeight,
  width = '100%',
  pageSize = 100,
  pageSizeOptions = [100],
  setCurrentIndex,
  rowSelection = true,
  slots = { toolbar: QualitatioToolbar },
  checkboxSelection = true,
  onRowSelectionModelChange = null,
  disableRowSelectionOnClick = false,
  setCurrentCardIndex = () => {},
  setDiscardWarningOpen = () => {},
  openDiscardWarning = false,
  currentIndex = null,
  ...otherProps
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (newSelection) => {
    const lastSelectedRow = newSelection[newSelection.length - 1];
    if (openDiscardWarning) {
      if (newSelection[0] !== lastSelectedRow) {
        setDiscardWarningOpen(lastSelectedRow);
        return;
      }
    } else {
      setCurrentCardIndex(0);
      if (setCurrentIndex) {
        setSelectedRows([lastSelectedRow]);
        setCurrentIndex(lastSelectedRow);
      }
    }
  };

  const labelText = createLabelText();

  return (
    <div
      className="qualitatio-table-container"
      style={{ height: height, width: width, maxHeight: maxHeight }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection={checkboxSelection}
        rowSelection={rowSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        localeText={labelText}
        slots={slots}
        {...otherProps}
        variant="qualitatio"
        onRowSelectionModelChange={(e) => {
          if (onRowSelectionModelChange) {
            onRowSelectionModelChange(e); // Call the provided callback
          } else {
            handleSelectionChange(e); // Call the default callback
          }
        }}
        rowSelectionModel={currentIndex === null ? setCurrentIndex && selectedRows : currentIndex} // Control the selected rows via state
      />
    </div>
  );
};

QualitatioTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setCurrentIndex: PropTypes.func,
  rowSelection: PropTypes.bool,
  slots: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func,
  disableRowSelectionOnClick: PropTypes.bool,
  setCurrentCardIndex: PropTypes.func,
  setDiscardWarningOpen: PropTypes.func,
  openDiscardWarning: PropTypes.bool,
  currentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

QualitatioTable.defaultProps = {
  rows: [],
  columns: [],
  height: 400,
  width: '100%',
  pageSize: 100,
  pageSizeOptions: [100],
  rowSelection: true,
  slots: { toolbar: QualitatioToolbar },
  checkboxSelection: true,
  disableRowSelectionOnClick: false,
  setCurrentCardIndex: () => {},
  setDiscardWarningOpen: () => {},
  openDiscardWarning: false,
  currentIndex: null,
};

export default QualitatioTable;
