import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Autocomplete, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axiosClient from '../../../api/axiosClient';
import {
  availableOutputStreamsEndpoint,
  createSPCMonitorSystemEndpoint,
  getSPCMonitorSystemsEndpoint,
  inputStreamsEndpoint,
  usersEndpoint,
} from '../../../api/endpoints';
import InfoTooltip from '../../../components/TooltipWrapper';
import METRIC_TYPES from '../../Const';

const OUTPUT_DATA_STREAM_NAME = 'All Quality Checks';

const _monitorSystemFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  dataStreamId: Yup.string().required('Required'),
  metricType: Yup.string().oneOf(Object.values(METRIC_TYPES)).required('Required'),
  defectField: Yup.string().when(['dataStreamId', 'metricType'], {
    is: (dataStreamId, metricType) =>
      dataStreamId !== 'default' && metricType === METRIC_TYPES.DEFECT_RATE,
    then: () => Yup.string().required('Please indicate Defect Field when using Inputstream'),
    otherwise: () => Yup.string().notRequired(),
  }),
  defectFieldValue: Yup.string().when(['dataStreamId', 'metricType'], {
    is: (dataStreamId, metricType) =>
      dataStreamId !== 'default' && metricType === METRIC_TYPES.DEFECT_RATE,
    then: () => Yup.string().required('Please indicate a value for Defect Field'),
    otherwise: () => Yup.string().notRequired(),
  }),
  monitoringFrequency: Yup.number().min(1).required('Required'),
  controlPeriod: Yup.number().min(1).required('Required'),
  //granularity: Yup.number().min(1).required('Required'),
});

const _monitorSystemFormInitialValues = (selectedSystem) => {
  return {
    name: selectedSystem?.name || '',
    // Default to 'default' if no inputStreamID is set
    dataStreamId: selectedSystem?.inputStreamId || 'default',
    metricType: selectedSystem?.metricType || METRIC_TYPES.DEFECT_RATE,
    filterFields: selectedSystem?.filterFields || [],
    defectField: selectedSystem?.defectField || '',
    defectFieldValue: selectedSystem?.defectFieldValue || '',
    monitoringFrequency: selectedSystem?.monitoringFrequencyMin || 1,
    controlPeriod: selectedSystem?.spcControlPeriodMin || 5,
    //granularity: selectedSystem?.granularity || 1,
    connectedUsers: selectedSystem?.subscriberIds || [],
    isActive: selectedSystem ? selectedSystem.active : true, // Activate system by default
  };
};

const _formValuesToApiBody = (values) => {
  return {
    name: values.name,
    metricType: values.metricType,
    filterFields: values.filterFields,
    defectField: values.defectField,
    defectFieldValue: values.defectFieldValue,
    usingStream: values.dataStreamId !== 'default',
    inputStreamId: values.dataStreamId === 'default' ? '' : values.dataStreamId,
    monitoringFrequencyMin: values.monitoringFrequency,
    spcControlPeriodMin: values.controlPeriod,
    spcControlGranularityMin: values.monitoringFrequency,
    connectedUsers: values.connectedUsers,
    active: values.isActive,
  };
};

const _getQualityCheckFields = (availableOutputStreams) => {
  if (availableOutputStreams.length === 0) {
    return [];
  }
  const qualityCheckStream = availableOutputStreams.find(
    (stream) => stream.name === OUTPUT_DATA_STREAM_NAME
  );
  return qualityCheckStream.fields || [];
};

const _displayDataStreams = (inputStreams) => {
  const filteredStreams = (inputStreams || []).map((stream) => ({
    id: stream.id,
    name: stream.name,
  }));
  const result = [{ id: 'default', name: 'Default: QualitatioQualityData' }, ...filteredStreams];
  return result;
};

const EditMonitorSystemForm = forwardRef(
  (
    {
      selectedSystem = null, // selectedSystem is null when creating a new system
      onSubmitCallback = () => {}, // Callback function to be called after form submission
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [userOptions, setUserOptions] = useState([]);
    const [inputStreams, setInputStreams] = useState([]);
    const [availableOutputStreams, setAvailableOutputStreams] = useState([]);

    // Methods for handling formik submission
    const handleAddNewSystem = async (values) => {
      try {
        console.log('request body: ', _formValuesToApiBody(values));
        const response = await axiosClient.post(
          `${createSPCMonitorSystemEndpoint}`,
          _formValuesToApiBody(values)
        );
        if (response.status >= 200 && response.status < 300) {
          enqueueSnackbar(t('ei.addSystemSuccess'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('ei.addSystemError'), { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(t('ei.addSystemError'), { variant: 'error' });
      }
    };

    const handleEditSystem = async (values) => {
      console.info('values:', values);
      try {
        const response = await axiosClient.patch(
          `${getSPCMonitorSystemsEndpoint}/${selectedSystem.id}`,
          _formValuesToApiBody(values)
        );
        console.info('response:', response);
        if (response.status >= 200 && response.status < 300) {
          enqueueSnackbar(t('ei.editSystemSuccess'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('ei.editSystemError'), { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(t('ei.editSystemError'), { variant: 'error' });
      }
    };

    // Fetch dependencies: users, input and output streams
    const getUsers = async () => {
      try {
        const response = await axiosClient.get(usersEndpoint);
        if (response.data) {
          setUserOptions(
            response.data.results.map((user) => ({
              id: user.id,
              name: user.name,
            }))
          );
        }
      } catch (error) {
        enqueueSnackbar(t('ei.errorLoadingUsers'), { variant: 'error' });
      }
    };

    const getInputStreams = async () => {
      try {
        const response = await axiosClient.get(inputStreamsEndpoint);
        if (response.data?.inputStreams && !response.data.error) {
          const streams = response.data.inputStreams;
          if (streams?.length === 0) {
            formik.setTouched({ dataStreamId: true });
          }
          setInputStreams(streams);
        }
      } catch (error) {
        enqueueSnackbar(t('ei.errorLoadingInputStreams'), {
          variant: 'error',
        });
      }
    };

    const getAvailableOutputStreams = async () => {
      try {
        const response = await axiosClient.get(availableOutputStreamsEndpoint);
        if (response.data.availableOutputStreams) {
          setAvailableOutputStreams(response.data.availableOutputStreams);
        }
      } catch (error) {
        enqueueSnackbar(t('errorWhileFetchingAvailableOutputStreams'), {
          variant: 'error',
        });
      }
    };

    useEffect(() => {
      getUsers();
      getInputStreams();
      getAvailableOutputStreams();
    }, []);

    const formik = useFormik({
      initialValues: _monitorSystemFormInitialValues(selectedSystem),
      // If the validation schema is not working, onSubmit will not be called
      validationSchema: _monitorSystemFormSchema,
      onSubmit: async (values) => {
        selectedSystem ? await handleEditSystem(values) : await handleAddNewSystem(values);
        onSubmitCallback();
      },
    });

    // Expose formik instance to parent components
    useImperativeHandle(ref, () => formik);

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            {...formik.getFieldProps('name')}
            label={t('ei.monitorName')}
            placeholder={t('ei.monitorName')}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreateOutlinedIcon
                    color={formik.touched.name && Boolean(formik.errors.name) ? 'error' : 'primary'}
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography ml={2}>{t('ei.activateSystem')}</Typography>
          <Switch
            checked={formik.values.isActive}
            onChange={() => formik.setFieldValue('isActive', !formik.values.isActive)}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            variant="qualitatio"
            options={_displayDataStreams(inputStreams)} // Always add the default option. Ensure inputStreams is always an array
            getOptionLabel={(option) => option?.name || ''} // Safely access 'name' and provide a fallback
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              _displayDataStreams(inputStreams).find(
                (option) => option.id === formik.values.dataStreamId
              ) || null
            } // Ensure a valid object is set or null
            onChange={(event, newValue) => {
              //set fields to empty array when changing the selected inputStream
              formik.setFieldValue('filterFields', []);
              formik.setFieldValue('dataStreamId', newValue ? newValue.id : '');
              formik.setFieldValue('defectField', '');
              formik.setFieldValue('defectFieldValue', '');
            }}
            onBlur={() => formik.setFieldTouched('dataStreamId', true)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('ei.dataStream')}
                label={t('ei.dataStream')}
                error={formik.touched.dataStreamId && Boolean(formik.errors.dataStreamId)}
                helperText={formik.touched.dataStreamId && formik.errors.dataStreamId}
                fullWidth
              />
            )}
            fullWidth
          />
          <InfoTooltip description={t('ei.dataStreamTip')} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            variant="qualitatio"
            options={Object.values(METRIC_TYPES)}
            getOptionLabel={(option) => t(`ei.metricTypes.${option}`)}
            value={formik.values.metricType} // Ensure a valid object is set or null
            onChange={(event, newValue) => {
              formik.setFieldValue('metricType', newValue);
            }}
            onBlur={() => formik.setFieldTouched('metricType', true)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('ei.metricType')}
                label={t('ei.metricType')}
                error={formik.touched.metricType && Boolean(formik.errors.metricType)}
                helperText={formik.touched.metricType && formik.errors.metricType}
                fullWidth
              />
            )}
            fullWidth
          />
          <InfoTooltip description={t('ei.metricTypeTip')} />
        </Grid>
        {formik.values.dataStreamId !== 'default' &&
          formik.values.metricType === METRIC_TYPES.DEFECT_RATE && (
            <Grid item xs={12} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Grid item xs={6}>
                <Autocomplete
                  id="defect-field-selector"
                  options={(
                    inputStreams.find((stream) => stream.id === formik.values.dataStreamId)
                      ?.streamFields || []
                  ).map((field) => field.name)}
                  getOptionLabel={(option) => option}
                  value={formik.values.defectField}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('defectField', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('ei.defectField')}
                      placeholder={t('ei.defectField')}
                      error={formik.touched.defectField && Boolean(formik.errors.defectField)}
                      helperText={formik.touched.defectField && formik.errors.defectField}
                      fullWidth
                    />
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="defect-field-value-selector"
                  variant="outlined"
                  label={t('ei.defectFieldValue')}
                  placeholder={t('ei.defectFieldValue')}
                  value={formik.values.defectFieldValue}
                  onChange={(event) => formik.setFieldValue('defectFieldValue', event.target.value)}
                  error={formik.touched.defectFieldValue && Boolean(formik.errors.defectFieldValue)}
                  helperText={formik.touched.defectFieldValue && formik.errors.defectFieldValue}
                  fullWidth
                />
                <InfoTooltip description={t('ei.defectFieldTip')} />
              </Grid>
            </Grid>
          )}
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            multiple
            id="fields-selector"
            options={
              formik.values.dataStreamId === 'default'
                ? _getQualityCheckFields(availableOutputStreams)
                : (
                    inputStreams.find((stream) => stream.id === formik.values.dataStreamId)
                      ?.streamFields || []
                  ).map((field) => field.name)
            }
            getOptionLabel={(option) => option} // Assuming option is just the field name string
            value={formik.values.filterFields} // The selected field names
            onChange={(event, newValue) => {
              formik.setFieldValue('filterFields', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('ei.filterFields')}
                placeholder={t('ei.filterFields')}
                error={formik.touched.filterFields && Boolean(formik.errors.filterFields)}
                helperText={formik.touched.filterFields && formik.errors.filterFields}
                fullWidth
              />
            )}
            fullWidth
          />
          <InfoTooltip description={t('ei.filterFieldsTip')} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            {...formik.getFieldProps('monitoringFrequency')}
            label={t('ei.monitoringFrequency')}
            placeholder={t('ei.monitoringFrequency')}
            error={formik.touched.monitoringFrequency && Boolean(formik.errors.monitoringFrequency)}
            helperText={formik.touched.monitoringFrequency && formik.errors.monitoringFrequency}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreateOutlinedIcon
                    color={
                      formik.touched.monitoringFrequency &&
                      Boolean(formik.errors.monitoringFrequency)
                        ? 'error'
                        : 'primary'
                    }
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <InfoTooltip description={t('ei.monitoringFrequencyTip')} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            {...formik.getFieldProps('controlPeriod')}
            label={t('ei.controlPeriod')}
            placeholder={t('ei.controlPeriod')}
            error={formik.touched.controlPeriod && Boolean(formik.errors.controlPeriod)}
            helperText={formik.touched.controlPeriod && formik.errors.controlPeriod}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CreateOutlinedIcon
                    color={
                      formik.touched.controlPeriod && Boolean(formik.errors.controlPeriod)
                        ? 'error'
                        : 'primary'
                    }
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
          />{' '}
          <InfoTooltip description={t('ei.controlPeriodTip')} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            multiple
            id="user-selector"
            options={
              userOptions.map((user) => user.id) || [] // Ensure userOptions is always an array
            }
            getOptionLabel={(option) => userOptions.find((user) => user.id === option)?.name || ''} // Safely access 'name' and provide a fallback
            value={formik.values.connectedUsers}
            onChange={(event, newValue) => {
              formik.setFieldValue('connectedUsers', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('ei.connectedUsers')}
                placeholder={t('ei.connectedUsers')}
                error={formik.touched.connectedUsers && Boolean(formik.errors.connectedUsers)}
                helperText={formik.touched.connectedUsers && formik.errors.connectedUsers}
                fullWidth
              />
            )}
            fullWidth
          />
          <InfoTooltip description={t('ei.connectedUsersTip')} />
        </Grid>
      </Grid>
    );
  }
);

// Set the display name for the component
EditMonitorSystemForm.displayName = 'EditMonitorSystemForm';

// Define the prop types for the component
EditMonitorSystemForm.propTypes = {
  selectedSystem: PropTypes.object,
  onSubmitCallback: PropTypes.func,
};

export default EditMonitorSystemForm;
