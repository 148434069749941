import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { generateCardEndpoint } from '../../../../api/endpoints';
import { useAuthStore } from '../../../../store/auth.store';

const CopilotButton = ({
  defectDefinition,
  qualityStation,
  allCards,
  setAllCards,
  setCurrentCardIndex,
  filteredCardsByEvent = allCards,
  explanation = undefined,
  cardType = undefined,
  overwriteFunction = undefined,
  ...props
}) => {
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();

  const generateCard = async () => {
    const snackbarKey = enqueueSnackbar(t('testCardGenerationStarted'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.post(generateCardEndpoint, {
        qualityStation: qualityStation,
        defect: defectDefinition,
        creator: user.id,
        explanation: explanation,
        cardType: cardType,
      });
      closeSnackbar(snackbarKey);
      if (response.data.card) {
        setCurrentCardIndex([...filteredCardsByEvent, response.data.card].length - 1);
        setAllCards([...allCards, response.data.card]);
        enqueueSnackbar(t('testCardGeneratedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhenGeneratingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Tooltip title={t('generateTestCardTooltip')}>
      <IconButton
        className="add-button"
        variant="qualitatio"
        squared={true}
        onClick={() => (overwriteFunction ? overwriteFunction() : generateCard())}
        style={{
          backgroundColor: theme.palette.warning.main,
          width: '48px',
          height: '48px',
        }}
        {...props}
      >
        <img src="/images/copilotIcon.svg" style={{ width: '40px', height: '40px' }} />
      </IconButton>
    </Tooltip>
  );
};

CopilotButton.propTypes = {
  defectDefinition: PropTypes.object.isRequired,
  qualityStation: PropTypes.string.isRequired,
  allCards: PropTypes.arrayOf(
    PropTypes.shape({
      qualityStation: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      testObject: PropTypes.string.isRequired,
      testLocation: PropTypes.string.isRequired,
      testMethod: PropTypes.string.isRequired,
      testComment: PropTypes.string.isRequired,
      orderIdentifier: PropTypes.number.isRequired,
      positionIdentifier: PropTypes.number.isRequired,
      images: PropTypes.array.isRequired,
      explanation: PropTypes.string.isRequired,
      creator: PropTypes.shape({
        creatorId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      creationDate: PropTypes.number.isRequired,
      editors: PropTypes.arrayOf(
        PropTypes.shape({
          editorId: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      changeEvents: PropTypes.array.isRequired,
      review: PropTypes.any,
    })
  ).isRequired,
  setAllCards: PropTypes.func.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  explanation: PropTypes.string,
  cardType: PropTypes.string,
  filteredCardsByEvent: PropTypes.arrayOf(
    PropTypes.shape({
      qualityStation: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      testObject: PropTypes.string.isRequired,
      testLocation: PropTypes.string.isRequired,
      testMethod: PropTypes.string.isRequired,
      testComment: PropTypes.string.isRequired,
      orderIdentifier: PropTypes.number.isRequired,
      positionIdentifier: PropTypes.number.isRequired,
      images: PropTypes.array.isRequired,
      explanation: PropTypes.string.isRequired,
      creator: PropTypes.shape({
        creatorId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      creationDate: PropTypes.number.isRequired,
      editors: PropTypes.arrayOf(
        PropTypes.shape({
          editorId: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      changeEvents: PropTypes.array.isRequired,
      review: PropTypes.any,
    })
  ).isRequired,
  overwriteFunction: PropTypes.func,
};

export default CopilotButton;
