import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import {
  getDefectDescriptionHierarchieAtDocumentationLevelsEndpoint,
  getTestRecommendationEndpoint,
  saveTestRecommendationGroundTruthEndpoint,
} from '../../../api/endpoints';
import DefectInput from '../../../intelligent_testing/recommender/swipe/DefectInput/DefectInput';
import { useAuthStore } from '../../../store/auth.store';
import Menu from '../Menu';
import CalculateTesting from '../feedback_screens/CalculateTesting';
import './TestET.css';
import TinderCard from './TinderCard';
import IOButton from './components/IOButton';
import NIOButton from './components/NIOButton';

export default function TestET({
  setPageName,
  activeQualityStation,
  productID,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
}) {
  const { t } = useTranslation();
  setPageName(t('test'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  //global variables
  const [queryParameters] = useSearchParams();
  const currentProductID = productID || queryParameters.get('productID');
  const qualityStation = activeQualityStation || queryParameters.get('qualityStation');

  // Server respose
  const [recommendation, setRecommendation] = useState(null);
  const [getDataRequestFinished, setGetDataRequestFinished] = useState(false);

  // Defect Input
  const [defectDescriptionFields, setDefectDescriptionFields] = useState(null);
  const [defectDescriptionFieldOptions, setDefectDescriptionFieldOptions] = useState(null);
  const [defectsFound, setDefectsFound] = useState([]);
  const [defectsCurrentIndex, setDefectsCurrentIndex] = useState(0);
  const [dataIndex, setDataIndex] = useState(0);
  const [cameraState, setCameraState] = useState(false);

  // Helper variables
  const [isDefectInput, setIsDefectInput] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    getTestRecommendation();
    getDefectDescriptionHierarchieAtDocumentationLevels();
  }, []);

  const getTestRecommendation = async () => {
    try {
      const response = await axiosClient.post(getTestRecommendationEndpoint, {
        productID: currentProductID,
        qualityStation: qualityStation,
      });
      if (response.data.recommendation !== null) {
        setRecommendation(response.data.recommendation);
        setGetDataRequestFinished(true);
      } else {
        enqueueSnackbar(t('pleaseReenterQualityStation'), { variant: 'error' });
        navigate('/efficient_testing/recommender/select');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionHierarchieAtDocumentationLevels = async () => {
    if (!defectDescriptionFields || !defectDescriptionFieldOptions) {
      try {
        const response = await axiosClient.get(
          getDefectDescriptionHierarchieAtDocumentationLevelsEndpoint
        );
        setDefectDescriptionFields(
          response.data.defectDescriptionHierarchie.defectDescriptionFields
        );
        setDefectDescriptionFieldOptions(
          response.data.defectDescriptionHierarchie.defectDescriptionFieldOptions
        );
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const defectDescriptionCanceled = () => {
    setIsDefectInput(false);
    cardRef.current.restoreCard();
  };

  const defectDescriptionSaved = async () => {
    setIsDefectInput(false);
    await saveTestRecommendationGroundTruth();
  };

  const handleSwipe = (dir) => {
    if (dir === 'left') {
      defectDetected();
    } else if (dir === 'right') {
      noDefectDetected();
    }
  };

  const defectDetected = async () => {
    if (defectsFound.length === 0) {
      defectsFound.push({
        defectDetected: '',
      });
    } else {
      defectsFound[defectsCurrentIndex].defectDetected = '';
    }
    setIsDefectInput(true);
  };

  const noDefectDetected = async () => {
    if (defectsFound.length === 0) {
      defectsFound.push({
        defectDetected: 'no',
      });
    }
    await saveTestRecommendationGroundTruth();
  };

  const saveTestRecommendationGroundTruth = async () => {
    try {
      const response = await axiosClient.post(saveTestRecommendationGroundTruthEndpoint, {
        productID: currentProductID,
        qualityStation: qualityStation,
        recommendation: recommendation,
        defect: defectsFound[defectsCurrentIndex],
        userID: user.id,
      });
      if (response.data.success) {
        enqueueSnackbar(t('successfullySavedTestResult'), { variant: 'success' });
        navigate('/efficient_testing/recommender/scan?qualityStationName=' + qualityStation);
      } else {
        enqueueSnackbar(t('errorWhenSavingTestResult'), { variant: 'error' });
        cardRef.current.restoreCard();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  /**
   * Updates or creates all key-value pairs of the given object in the current defect.
   * @param {object} newValuesObj
   */
  const updateCurrentDefect = (newValuesObj) => {
    setDefectsFound((prevState) => {
      const newDefectsFound = [...prevState];
      newDefectsFound[defectsCurrentIndex] = {
        ...newDefectsFound[defectsCurrentIndex],
        ...newValuesObj,
      };
      return newDefectsFound;
    });
  };

  return (
    <>
      <DefectInput
        productID={currentProductID}
        qualityStation={qualityStation}
        dataIndex={dataIndex}
        isDefectInput={isDefectInput}
        setIsDefectInput={setIsDefectInput}
        defectsFound={defectsFound}
        setDefectsFound={setDefectsFound}
        updateCurrentDefect={updateCurrentDefect}
        defectsCurrentIndex={defectsCurrentIndex}
        cameraState={cameraState}
        setCameraState={setCameraState}
        defectDescriptionFields={defectDescriptionFields}
        defectDescriptionFieldOptions={defectDescriptionFieldOptions}
        defectDescriptionCanceled={defectDescriptionCanceled}
        defectDescriptionSaved={defectDescriptionSaved}
        recommenderMenuOpen={recommenderMenuOpen}
        setRecommenderMenuOpen={setRecommenderMenuOpen}
        data={[]}
      />
      {recommenderMenuOpen ? (
        getDataRequestFinished ? (
          <div className={isDefectInput ? 'swipeCardsLayoutNotVisible' : 'swipeCardsLayout'}>
            <div className="swipeCards">
              <div className="tinderCardWrapper">
                <TinderCard
                  ref={cardRef}
                  className="swipe"
                  key={'tinderCard'}
                  onSwipe={(dir) => {
                    handleSwipe(dir);
                  }}
                  swipeRequirementType={'position'}
                  swipeThreshold={50}
                  preventSwipe={['up', 'down']}
                  flickOnSwipe={true}
                >
                  <Box
                    className="card-light"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Box
                      className="card-content-container"
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '70%',
                      }}
                    >
                      <Box
                        className="card-description-container"
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          margin: '0 0 10% 0',
                          fontWeight: '700',
                        }}
                      >
                        {t('carryOutTest')}
                      </Box>

                      <Box
                        className="card-recommendation-container"
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: 'calc(16px + 1vw)',
                          margin: '0 0 10% 0',
                          padding: '10% 0',
                          fontWeight: '700',
                          background: recommendation ? 'green' : 'red',
                        }}
                      >
                        {recommendation ? t('yes') : t('no')}
                      </Box>

                      <Box
                        className="card-button-description-container"
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          margin: '15% 0 0 0',
                          fontWeight: '500',
                        }}
                      >
                        {t('defectDetected')}
                      </Box>
                      <Box
                        className="card-button-container"
                        sx={{
                          width: '100%',
                          margin: '5% 0 0 0',
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <IOButton label={t('yes')} onClick={() => cardRef.current.swipe('left')} />
                        <NIOButton label={t('no')} onClick={() => cardRef.current.swipe('right')} />
                      </Box>
                    </Box>
                  </Box>
                </TinderCard>
              </div>
            </div>
          </div>
        ) : (
          <CalculateTesting
            productID={currentProductID}
            qualityStation={qualityStation}
            recommenderMenuOpen={recommenderMenuOpen}
            setRecommenderMenuOpen={setRecommenderMenuOpen}
          />
        )
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}
