import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { Chip, Grid, IconButton, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTachometer } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { FaCalendarAlt, FaFileCsv, FaRegClock } from 'react-icons/fa';
import { FiArrowDown, FiArrowUp, FiCircle, FiHexagon, FiTriangle } from 'react-icons/fi';
import axiosClient from '../../api/axiosClient';
import {
  activateOptimizationDataSourceEndpoint,
  deactivateOptimizationDataSourceEndpoint,
  optimizationDataEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioProgressBar from '../../components/QualitatioProgressBar/QualitatioProgressBar';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import AIEditOptimizer from './AIEditOptimizer';
import './AIOptimizer.css';
import AIOptimizerSteps from './AIOptimizerSteps';

export default function AIOptimizer({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('aiOptimization'));

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const [optimizationData, setOptimizationData] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getOptimizationData();
  }, []);

  const getOptimizationData = async () => {
    try {
      const response = await axiosClient.get(optimizationDataEndpoint);
      setOptimizationData(response.data.optimizationData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [optimizationDataForTable, setOptimizationDataForTable] = useState([]);

  useEffect(() => {
    setOptimizationDataForTable(optimizationData);
  }, [optimizationData]);

  const deleteOptimizationDataSource = async (dataSourceID) => {
    try {
      // Show first confirmation dialog
      const shouldDeleteDataSource = await confirmDeletion(
        'Do you really want to delete the data source?',
      );

      if (shouldDeleteDataSource) {
        // Proceed with model deletion
        const response = await axiosClient.delete(optimizationDataEndpoint, {
          data: {
            dataSourceID: dataSourceID,
          },
        });
        setOptimizationData(response.data.optimizationData);
        enqueueSnackbar(t('dataSourceSuccessfullyDeleted'), { variant: 'success' });
        console.log(response.data.optimizationData);
      } else {
        // First confirmation denied
        console.log('Data Source deletion canceled.');
      }
    } catch (error) {
      enqueueSnackbar(t('dataSourceDeletionFailed'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const confirmDeletion = async (message) => {
    return new Promise((resolve) => {
      if (window.confirm(message)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const updateModelActivation = async (dataSourceID, isActive) => {
    if (isActive) {
      try {
        const response = await axiosClient.post(activateOptimizationDataSourceEndpoint, {
          dataSourceID: dataSourceID,
        });
        setOptimizationData(response.data.optimizationData);
        enqueueSnackbar(t('optimizationDataSourceSuccessfullyActivated'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('errorWhileActivatingOptimizationDataSource'), { variant: 'error' });
      }
    } else {
      try {
        const response = await axiosClient.post(deactivateOptimizationDataSourceEndpoint, {
          dataSourceID: dataSourceID,
        });
        setOptimizationData(response.data.optimizationData);
        enqueueSnackbar(t('optimizationDataSourceSuccessfullyDeactivated'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeactivatingOptimizationDataSource'), { variant: 'error' });
      }
    }
  };

  const [showDialog, setShowDialog] = useState(false);

  const [showExampleDialog, setShowExampleDialog] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState([]);

  const openEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const closeEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 120,
      flex: 1,
    },
    {
      field: 'creator',
      headerName: t('creator'),
      width: 120,
      flex: 1,
    },
    {
      field: 'created',
      headerName: t('created'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          icon={<FaCalendarAlt />}
          sx={{
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          }}
        />
      ),
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 200,
      flex: 1,
      renderCell: (params) =>
        params.value === 'csv' ? (
          <Chip
            label="CSV"
            icon={<FaFileCsv />}
            sx={{ backgroundColor: theme.palette.success.secondary }}
          />
        ) : (
          <Chip
            label={params.value}
            icon={<FaRegClock />}
            sx={{ backgroundColor: theme.palette.success.secondary }}
          />
        ),
    },
    {
      field: 'defectDescription',
      headerName: t('defectDescription'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          <Chip label={params.value.join(', ')} onClick={() => setShowDialog(true)} width="100%" />
          <QualitatioDialog
            title={t('defectDescription')}
            open={showDialog}
            onClose={() => setShowDialog(false)}
            description={params.value.join('\n')}
            actions={[
              {
                label: t('close'),
                onClick: () => setShowDialog(false),
                order: 'primary',
              },
            ]}
          />
        </>
      ),
    },
    {
      field: 'kpi',
      headerName: t('kpi'),
      width: 120,
      flex: 1,
    },
    {
      field: 'ordering',
      headerName: t('ordering'),
      width: 200,
      flex: 1,
      renderCell: (params) =>
        params.value === 'ascending' ? (
          <Chip
            label={t('ascending')}
            icon={<FiArrowUp />}
            sx={{ backgroundColor: theme.palette.success.secondary }}
          />
        ) : (
          <Chip
            label={t('descending')}
            icon={<FiArrowDown />}
            sx={{ backgroundColor: theme.palette.info.secondary }}
          />
        ),
    },
    {
      field: 'priorization',
      headerName: t('priorization'),
      width: 200,
      flex: 1,
      renderCell: (params) =>
        params.value === 'high' ? (
          <Chip
            label={t('high')}
            icon={<FiTriangle />}
            sx={{ backgroundColor: theme.palette.error.secondary }}
          />
        ) : params.value === 'medium' ? (
          <Chip
            label={t('medium')}
            icon={<FiCircle />}
            sx={{ backgroundColor: theme.palette.warning.main }}
          />
        ) : (
          <Chip
            label={t('low')}
            icon={<FiHexagon />}
            sx={{ backgroundColor: theme.palette.success.secondary }}
          />
        ),
    },
    {
      field: 'examples',
      headerName: t('examples'),
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const exampleColumns =
          params.value.length > 0
            ? Object.keys(params.value[0]).map((key, index, array) => ({
                field: key,
                headerName: key.replace('_kpi', ''),
                width: 150, // Adjust the width as needed
                flex: 1,
                headerClassName: index === array.length - 1 ? 'last-column-header' : '', // Add CSS class for last column header
              }))
            : [];

        const exampleRows = params.value.map((example, exampleIndex) => ({
          id: exampleIndex,
          ...example,
        }));

        return (
          <>
            <Chip
              label={t('examples')}
              onClick={() => setShowExampleDialog(true)}
              icon={<BsArrowRight />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
            <QualitatioDialog
              title={t('examples')}
              open={showExampleDialog}
              onClose={() => setShowExampleDialog(false)}
              children={
                <QualitatioTable
                  columns={exampleColumns}
                  rows={exampleRows}
                  width="100%"
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                />
              }
              actions={[
                {
                  label: t('close'),
                  onClick: () => setShowExampleDialog(false),
                  order: 'primary',
                },
              ]}
            />
          </>
        );
      },
    },
    {
      field: 'active',
      headerName: t('active'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Grid container alignItems="center" height="100%">
          <Switch
            variant="qualitatio"
            checked={params.value}
            onChange={(event) => updateModelActivation(params.row.id, event.target.checked)}
          />
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <div className="edit-and-delete-buttons">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => openEditOverlay(params.id)}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '30px',
              height: '30px',
            }}
          >
            <EditRoundedIcon color="primary" />
          </IconButton>
          {isEditOverlayOpen[params.id] && (
            <AIEditOptimizer
              dataSource={optimizationDataForTable.find(
                (dataSource) => dataSource.id === params.id,
              )}
              isEditOverlayOpen={isEditOverlayOpen}
              closeEditOverlay={closeEditOverlay}
              setOptimizationData={setOptimizationData}
              checkForWrongCharacter={checkForWrongCharacter}
              checkIfDataSourceNameAlreadyExists={checkIfDataSourceNameAlreadyExists}
            />
          )}
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => deleteOptimizationDataSource(params.id)}
            style={{ backgroundColor: theme.palette.error.main, width: '30px', height: '30px' }}
          >
            <DeleteForeverRoundedIcon color="white" />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = optimizationDataForTable.map((dataSource) => {
    return {
      id: dataSource.id,
      name: dataSource.name,
      creator: dataSource.creatorName,
      created: new Date(dataSource.created.split('T')[0].split('-').join('/')).toLocaleDateString(),
      type: dataSource.type,
      defectDescription: dataSource.defectDescriptionFieldNames,
      kpi: dataSource.kpi,
      ordering: dataSource.ordering,
      priorization: dataSource.priorization,
      examples: dataSource.examples,
      active: dataSource.active,
    };
  });

  const [multipleActions, setMultipleActions] = useState();

  const multipleActionsOptions = [
    { value: 'activate', label: t('activate') },
    { value: 'deactivate', label: t('deactivate') },
    { value: 'delete', label: t('delete') },
  ];

  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const handleApplyAction = async () => {
    // Make the function async
    if (multipleActions === 'activate') {
      for (const id of selectedRowIDs) {
        // Use for...of for sequential processing
        const source = optimizationDataForTable.find((source) => source.id === id);
        if (source && !source.active) {
          await updateModelActivation(id, true); // Await ensures each activation completes before the next
        }
      }
    } else if (multipleActions === 'deactivate') {
      for (const id of selectedRowIDs) {
        const source = optimizationDataForTable.find((source) => source.id === id);
        if (source && source.active) {
          await updateModelActivation(id, false); // Await ensures each deactivation completes before the next
        }
      }
    } else if (multipleActions === 'delete') {
      for (const id of selectedRowIDs) {
        await deleteOptimizationDataSource(id); // Await ensures each deletion completes before moving to the next
      }
    }
  };

  const checkForWrongCharacter = (string) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    return !regex.test(string);
  };

  const checkIfDataSourceNameAlreadyExists = (dataSourceName) => {
    let dataSourceNames = optimizationData.map((dataSource) =>
      dataSource.name.trim().toLowerCase().replace(/\s+/g, '_'),
    );
    const dataSourceNameTrimmed = dataSourceName.trim().toLowerCase().replace(/\s+/g, '_');
    return dataSourceNames.some((dataSource) => dataSource === dataSourceNameTrimmed);
  };

  return (
    <PageFrame title={t('aiOptimization')} Icon={TuneRoundedIcon}>
      <div className="aiOptimizer">
        {activeStep < 2 && (
          <div className="aiOptimizerLayout">
            <div className="aiOptimizerInputBar">
              {activeStep === 0 ? (
                <>
                  <div className="aiOptimizerMultipleActions">
                    <QualitatioSelect
                      value={multipleActions}
                      onChange={(e) => setMultipleActions(e.target.value)}
                      optionProps={multipleActionsOptions.map((option) => (
                        <option className="option" key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                      width={'30%'}
                      maxWidth={'300px'}
                      defaultText={t('selectAction')}
                    />
                    <QualitatioButton
                      text={t('apply')}
                      order="primary"
                      onClick={handleApplyAction}
                      width={'15%'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                  </div>
                  <QualitatioButton
                    text={t('addNewDataSource')}
                    order="primary"
                    onClick={() => setActiveStep(1)}
                    width={'30%'}
                    height={'5vh'}
                    fontSize={'calc(12px + 0.25vw)'}
                    startIcon={<BiTachometer />}
                  />
                </>
              ) : (
                <div className="aiOptimizerDescription">
                  {t('aiOptimizationConsistsOfTheFollowingSteps')}
                </div>
              )}
            </div>
            <div className="aiOptimizerTable">
              {activeStep === 0 && (
                <QualitatioTable
                  rows={rows}
                  columns={columns}
                  width="100%"
                  checkboxSelection={true}
                  disableRowSelectionOnClick={true}
                  onRowSelectionModelChange={(selection) => {
                    setSelectedRowIDs(selection);
                  }}
                  slots={{ toolbar: CustomToolbar }}
                />
              )}
              {activeStep === 1 && (
                <div className="aiOptimizerProgressBarContainer">
                  <QualitatioProgressBar
                    steps={[
                      t('nameDataSource'),
                      t('selectDataSource'),
                      t('selectDataSet'),
                      t('selectDescriptionFieldsAndKpis'),
                      t('mapDescriptionFieldsAndKpis'),
                      t('definePriorization'),
                      t('checkDataSourceConfiguration'),
                    ]}
                    nonLinear={false}
                    alternativeLabel={true}
                    activeStep={-1}
                    setActiveStep={setActiveStep}
                    orientation="vertical"
                  />
                  <div className="aiOptimizerProgressBarContainerButtons">
                    <QualitatioButton
                      text={t('cancel')}
                      order="secondary"
                      onClick={() => setActiveStep(0)}
                      width={'30%'}
                      max-width={'200px'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                    <QualitatioButton
                      text={t('start')}
                      order="primary"
                      onClick={() => setActiveStep(2)}
                      width={'30%'}
                      max-width={'200px'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {activeStep >= 2 && (
          <AIOptimizerSteps
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            checkForWrongCharacter={checkForWrongCharacter}
            checkIfDataSourceNameAlreadyExists={checkIfDataSourceNameAlreadyExists}
            setOptimizationData={setOptimizationData}
          />
        )}
      </div>
    </PageFrame>
  );
}
