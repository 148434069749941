import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React from 'react';
import _ from 'lodash';
import QualitatioButton from '../../../../components/QualitatioButton/QualitatioButton';
import PropTypes from 'prop-types';

const ViewRule = ({ rule, index, currentIndex, handleViewRule }) => {
  return (
    <QualitatioButton
      text={rule?.ruleName}
      fontSize={'calc(10px + 0.25vw)'}
      width={'100%'}
      onClick={() => {
        handleViewRule(index);
      }}
      endIcon={<ArrowForwardIosRoundedIcon style={{ fontSize: 'calc(10px + 0.25vw)' }} />}
      order={index === currentIndex ? 'primary' : 'secondary'}
    />
  );
};

ViewRule.propTypes = {
  rule: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  handleViewRule: PropTypes.func.isRequired,
};

export default ViewRule;
