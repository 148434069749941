import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const RuleTestCardsManipulation = ({
  ruleCards,
  isAddCardDisabled,
  handleDeleteCard,
  handleAddCard,
  handleSaveCard,
  handlePreviousCard,
  handleNextCard,
}) => {
  const theme = useTheme();
  return (
    <>
      {ruleCards.length > 0 && (
        <>
          <IconButton
            className="test-card-manipulation-element"
            variant="qualitatio"
            squared={true}
            onClick={handleDeleteCard}
            style={{ backgroundColor: theme.palette.error.main, width: '48px', height: '48px' }}
          >
            <DeleteForeverRoundedIcon color="white" fontSize="large" />
          </IconButton>
          <IconButton
            className="test-card-manipulation-element"
            variant="qualitatio"
            squared={true}
            onClick={handlePreviousCard}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <ArrowBackIosNewRoundedIcon color="primary" />
          </IconButton>
        </>
      )}
      <IconButton
        className="add-button"
        disabled={isAddCardDisabled}
        variant="qualitatio"
        squared={true}
        onClick={handleAddCard}
        style={{
          backgroundColor: theme.palette.success.secondary,
          width: '48px',
          height: '48px',
        }}
      >
        <AddRoundedIcon color="primary" fontSize="large" />
      </IconButton>
      {ruleCards.length > 0 && (
        <>
          <IconButton
            className="test-card-manipulation-element"
            variant="qualitatio"
            squared={true}
            onClick={handleNextCard}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <ArrowForwardIosRoundedIcon color="primary" />
          </IconButton>
          <IconButton
            className="test-card-manipulation-element"
            variant="qualitatio"
            squared={true}
            onClick={() => handleSaveCard()}
            style={{
              backgroundColor: theme.palette.primary.main,
              width: '48px',
              height: '48px',
            }}
          >
            <SaveIcon color="white" fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
};

RuleTestCardsManipulation.propTypes = {
  ruleCards: PropTypes.array.isRequired,
  isAddCardDisabled: PropTypes.bool.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  handleSaveCard: PropTypes.func.isRequired,
  handlePreviousCard: PropTypes.func.isRequired,
  handleNextCard: PropTypes.func.isRequired,
};

export default RuleTestCardsManipulation;
