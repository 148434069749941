import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/Save';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Avatar, Drawer, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MuiChipsInput } from 'mui-chips-input';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { filesRoot, toDoEndpoint } from '../../../api/endpoints';
import { getUsers } from '../../../api/user';
import ROLES from '../../../authentication/roleConst';
import { QualitatioDateTimePicker, QualitatioDropdown } from '../../../components';
import QualitatioAvatar from '../../../components/QualitatioAvatar/QualitatioAvatar';
import QualitatioChipDropdown from '../../../components/QualitatioChipDropdown/QualitatioChipDropdown';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import { useAuthStore } from '../../../store/auth.store';
import './ToDoDrawer.css';

export default function ToDoDrawer({
  open,
  onClose,
  cardTitle,
  creationDate,
  editors,
  creator,
  toDoEvents,
  id,
  setToDoEvents,
  orderIdentifierMapping,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers({ excludedRoles: `${ROLES.IT_USER}, ${ROLES.ET_USER}` });
      if (fetchedUsers && !fetchedUsers.error) {
        setUsers(fetchedUsers);
      } else {
        enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [editMode, setEditMode] = useState([]);

  const [toDos, setToDos] = useState([]);

  useEffect(() => {
    const newToDos = toDoEvents
      .filter((changeEvent) => changeEvent.type === 'toDo')
      .map((el) => {
        return {
          ...el,
          toDo: {
            ...el.toDo,
            priority: { label: t(el.toDo.priority), value: el.toDo.priority },
            type: { label: t(el.toDo.type), value: el.toDo.type },
            assignees: el.toDo.assignees.map((assignee) => {
              return {
                label: `${assignee.name} (${users?.find((user) => user.id === assignee.userId)?.email})`,
                value: assignee.userId,
                profileImage: assignee.profileImage,
              };
            }),
          },
        };
      });

    setToDos(newToDos);
    setEditMode(
      newToDos.map((el) => {
        return { id: el._id, editMode: false };
      })
    );
  }, [toDoEvents]);

  const editToDo = async (toDoEvent) => {
    const uniqueAssignees = Array.from(
      new Set(toDoEvent.toDo.assignees.map((assignee) => assignee.value))
    );
    console.log('uniqueAssignees: ', uniqueAssignees);
    try {
      const response = await axiosClient.patch(toDoEndpoint, {
        cardID: id,
        toDoID: toDoEvent._id,
        userID: user.id,
        toDo: {
          ...toDoEvent.toDo,
          priority: toDoEvent.toDo.priority.value,
          type: toDoEvent.toDo.type.value,
          assignees: uniqueAssignees,
        },
      });
      if (response.data.changeEvents) {
        setToDoEvents(
          response.data.changeEvents.filter((changeEvent) => changeEvent.type.startsWith('toDo'))
        );
        setEditMode(
          editMode.map((el) => {
            if (el.id === toDoEvent._id) {
              el.editMode = false;
            }
            return el;
          })
        );
        enqueueSnackbar(t('toDoEdited'), { variant: 'success' });
      }
    } catch (error) {
      console.log('Error: ', error);
      enqueueSnackbar(t('toDoEditError'), { variant: 'error' });
    }
  };

  const deleteToDo = async (changeID) => {
    try {
      const response = await axiosClient.delete(toDoEndpoint, {
        data: {
          cardID: id,
          toDoID: changeID,
          userID: user.id,
        },
      });
      if (response.data.changeEvents) {
        setToDoEvents(
          response.data.changeEvents.filter((changeEvent) => changeEvent.type.startsWith('toDo'))
        );
        enqueueSnackbar(t('toDoDeleted'), { variant: 'success' });
      }
    } catch (error) {
      console.log('Error: ', error);
      enqueueSnackbar(t('toDoDeleteError'), { variant: 'error' });
    }
  };

  const closeToDo = async (changeID) => {
    try {
      const response = await axiosClient.post(`${toDoEndpoint}/${changeID}`, {
        cardID: id,
        userID: user.id,
      });
      if (response.data.changeEvents) {
        setToDoEvents(
          response.data.changeEvents.filter((changeEvent) => changeEvent.type.startsWith('toDo'))
        );
        enqueueSnackbar(t('toDoClosed'), { variant: 'success' });
      }
    } catch (error) {
      console.log('Error: ', error);
      enqueueSnackbar(t('toDoCloseError'), { variant: 'error' });
    }
  };

  const renderToDoEvents = useMemo(() => {
    return toDoEvents.map((changeEvent) => {
      if (changeEvent.type === 'toDo') {
        return (
          <Paper
            elevation={2}
            className={`paperWithStripe${changeEvent.toDo.priority === 'high' ? '-highPriority' : changeEvent.toDo.priority === 'medium' ? '-mediumPriority' : ''}`}
            style={{
              padding: '10px',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
              maxWidth: '500px',
            }}
          >
            {!editMode.find((el) => el.id === changeEvent._id)?.editMode ? (
              <Grid
                item
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  display="flex"
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  padding=" 0 25px 25px 0"
                >
                  <Grid item display="flex" direction="column">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                    >
                      {new Date(changeEvent.created).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        padding: '10px 0 0 10px',
                        color:
                          changeEvent.toDo.priority === 'low'
                            ? theme.palette.primary.main
                            : changeEvent.toDo.priority === 'medium'
                              ? theme.palette.warning.secondary
                              : theme.palette.error.main,
                        fontWeight: 'bold',
                        textDecoration:
                          changeEvent.toDo.status === 'closed' ? 'line-through' : 'none',
                        textDecorationThickness: '2px',
                      }}
                    >
                      {changeEvent.toDo.title}
                    </Typography>
                  </Grid>
                  <QualitatioAvatar
                    name={changeEvent.creator.name}
                    size={40}
                    profileImage={changeEvent.creator.profileImage}
                    creatorId={changeEvent.creator.creatorId}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="column"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      fontStyle: 'italic',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      maxWidth: '300px',
                    }}
                  >
                    {changeEvent.toDo.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Typography variant="subtitle1" component="div" style={{ padding: '10px 0 0 0' }}>
                    {changeEvent.toDo.priority === 'low' && (
                      <Tooltip title={`${t('priority')}: ${t('low')}`}>
                        <ArrowDownwardIcon style={{ color: theme.palette.success.main }} />
                      </Tooltip>
                    )}
                    {changeEvent.toDo.priority === 'medium' && (
                      <Tooltip title={`${t('priority')}: ${t('medium')}`}>
                        <ArrowForwardIcon style={{ color: theme.palette.warning.secondary }} />
                      </Tooltip>
                    )}
                    {changeEvent.toDo.priority === 'high' && (
                      <Tooltip title={`${t('priority')}: ${t('high')}`}>
                        <ArrowUpwardIcon style={{ color: theme.palette.error.main }} />
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '10px 0 0 10px' }}
                  >
                    {changeEvent.toDo.type === 'task' && (
                      <Tooltip title={`${t('type')}: ${t('task')}`}>
                        <AssignmentIcon style={{ color: 'blue' }} />
                      </Tooltip>
                    )}
                    {changeEvent.toDo.type === 'fix' && (
                      <Tooltip title={`${t('type')}: ${t('fix')}`}>
                        <ConstructionIcon style={{ color: 'red' }} />
                      </Tooltip>
                    )}
                    {changeEvent.toDo.type === 'improvement' && (
                      <Tooltip title={`${t('type')}: ${t('improvement')}`}>
                        <ShowChartIcon style={{ color: 'green' }} />
                      </Tooltip>
                    )}
                    {changeEvent.toDo.type === 'verification' && (
                      <Tooltip title={`${t('type')}: ${t('verification')}`}>
                        <VerifiedUserIcon style={{ color: 'orange' }} />
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '10px 0 0 10px' }}
                  >
                    <Grid item display="flex" direction="row" gap="2px">
                      {changeEvent.toDo.assignees.map((assignee) => (
                        <QualitatioAvatar
                          name={assignee.name}
                          size={24}
                          profileImage={assignee.profileImage}
                          creatorId={assignee.userId}
                        />
                      ))}
                    </Grid>
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '10px 0 0 0', fontStyle: 'italic' }}
                  >
                    {`${t('deadline')}: ${new Date(changeEvent.toDo.deadline).toLocaleString()}`}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="10px"
              >
                <Grid
                  item
                  container
                  display="flex"
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  padding=" 0 25px 25px 0"
                  maxWidth="300px"
                >
                  <Grid item display="flex" direction="column">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                    >
                      {new Date(changeEvent.created).toLocaleString()}
                    </Typography>
                    <QualitatioInput
                      label={t('title')}
                      value={toDos.find((el) => el._id === changeEvent._id).toDo.title}
                      onChange={(e) => {
                        const newToDo = toDos.map((el) => {
                          if (el._id === changeEvent._id) {
                            el.toDo.title = e.target.value;
                          }
                          return el;
                        });
                        setToDos(newToDo);
                      }}
                      width="100%"
                      compact={true}
                    />
                  </Grid>
                  <QualitatioAvatar
                    name={changeEvent.creator.name}
                    size={40}
                    profileImage={changeEvent.creator.profileImage}
                    creatorId={changeEvent.creator.creatorId}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="column"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <QualitatioInput
                    label={t('description')}
                    value={toDos.find((el) => el._id === changeEvent._id).toDo.description}
                    onChange={(e) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.description = e.target.value;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    width="100%"
                    compact={true}
                    multiline={true}
                    maxRows={4}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <QualitatioDropdown
                    label={t('priority')}
                    value={toDos.find((el) => el._id === changeEvent._id).toDo.priority}
                    onChange={(e, newValue) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.priority = newValue;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    options={[
                      { label: t('low'), value: 'low' },
                      { label: t('medium'), value: 'medium' },
                      { label: t('high'), value: 'high' },
                    ]}
                    width="100%"
                    compact={true}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <QualitatioDropdown
                    label={t('type')}
                    value={toDos.find((el) => el._id === changeEvent._id).toDo.type}
                    onChange={(e, newValue) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.type = newValue;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    options={[
                      { label: t('task'), value: 'task' },
                      { label: t('fix'), value: 'fix' },
                      { label: t('improvement'), value: 'improvement' },
                      { label: t('verification'), value: 'verification' },
                    ]}
                    width="100%"
                    compact={true}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <QualitatioDateTimePicker
                    label={t('deadline')}
                    value={toDos.find((el) => el._id === changeEvent._id).toDo.deadline}
                    onChange={(value) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.deadline = value;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    width="100%"
                    compact={true}
                    onlyFuture={true}
                    noTime={true}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <QualitatioChipDropdown
                    label={t('assignees')}
                    value={toDos.find((el) => el._id === changeEvent._id)?.toDo.assignees}
                    onChange={(newValue) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.assignees = newValue;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    options={users?.map((user) => ({
                      label: `${user.name} (${user.email})`,
                      value: user.id,
                      profileImage: user.profileImage,
                    }))}
                    width="100%"
                    compact={true}
                    multiple={true}
                    rows={1}
                    maxRows={4}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  padding="0 25px 0 10px"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <MuiChipsInput
                    className="qualitatio-chips-input-compact"
                    value={toDos.find((el) => el._id === changeEvent._id).toDo.keywords}
                    onChange={(newValue) => {
                      const newToDo = toDos.map((el) => {
                        if (el._id === changeEvent._id) {
                          el.toDo.keywords = newValue;
                        }
                        return el;
                      });
                      setToDos(newToDo);
                    }}
                    multiline
                    rows={1} // Start with a single line
                    maxRows={4} // Allow it to expand to more lines as needed
                    fullWidth
                    label={t('keywords')}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              display="flex"
              direction="row"
              gap="1vw"
              justifyContent="right"
              width="100%"
              padding="10px 25px 0 0"
            >
              {changeEvent.toDo.status === 'open' &&
                changeEvent.toDo.assignees.some((assignee) => assignee.userId === user.id) && (
                  <IconButton
                    variant="qualitatio"
                    squared={true}
                    style={{ backgroundColor: theme.palette.success.main, color: 'white' }}
                    onClick={() => closeToDo(changeEvent._id)}
                  >
                    <CheckIcon />
                  </IconButton>
                )}
              {user.id === changeEvent.creator.creatorId && changeEvent.toDo.status === 'open' && (
                <IconButton
                  variant="qualitatio"
                  squared={true}
                  style={{ backgroundColor: theme.palette.error.main, color: 'white' }}
                  onClick={() => deleteToDo(changeEvent._id)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {user.id === changeEvent.creator.creatorId && changeEvent.toDo.status === 'open' && (
                <IconButton
                  variant="qualitatio"
                  squared={true}
                  style={{ backgroundColor: theme.palette.success.main, color: 'white' }}
                  onClick={() => {
                    const newEditMode = editMode.map((el) => {
                      if (el.id === changeEvent._id) {
                        el.editMode = !el.editMode;
                      }
                      return el;
                    });
                    setEditMode(newEditMode);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              )}

              {user.id === changeEvent.creator.creatorId && changeEvent.toDo.status === 'open' && (
                <IconButton
                  variant="qualitatio"
                  squared={true}
                  style={{ backgroundColor: theme.palette.success.main, color: 'white' }}
                  onClick={() => editToDo(toDos.find((el) => el._id === changeEvent._id))}
                >
                  <SaveIcon />
                </IconButton>
              )}
            </Grid>
          </Paper>
        );
      } else {
        return (
          <Paper
            elevation={2}
            className="paperWithStripe"
            style={{
              padding: '10px',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                display="flex"
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                padding=" 0 25px 25px 0"
              >
                <Grid item display="flex" direction="column">
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                  >
                    {new Date(changeEvent.created).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{
                      padding: '10px 0 0 10px',
                      color: theme.palette.primary.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {t(changeEvent.type)}
                  </Typography>
                </Grid>
                <QualitatioAvatar
                  name={changeEvent.creator.name}
                  size={40}
                  profileImage={changeEvent.creator.profileImage}
                  creatorId={changeEvent.creator.creatorId}
                />
              </Grid>
            </Grid>
          </Paper>
        );
      }
    });
  }, [toDoEvents, editMode, toDos]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid
        container
        display="flex"
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: '10px' }}
      >
        <Grid item display="flex">
          <Typography variant="h4" component="div" style={{ padding: '10px', fontWeight: 'bold' }}>
            {`${t('toDosFor')} ${cardTitle}`}
          </Typography>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
            {`${t('createdAt')} ${new Date(creationDate).toLocaleString()} ${t('by')}: `}
          </Typography>
          <Tooltip title={creator.name}>
            <Avatar
              alt={creator.name}
              src={filesRoot + '/users/profileImages/' + creator.creatorId + '/profileImage.png'}
              sx={{ width: 24, height: 24 }}
            />
          </Tooltip>
        </Grid>
        <Grid item display="flex" alignItems="center" direction="row">
          <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
            {`${t('editedBy')}: `}
          </Typography>
          {editors?.map((editor) => (
            <Typography variant="subtitle1" component="div" style={{ padding: '0 2px 0 2px' }}>
              <Tooltip title={editor.name}>
                <Avatar
                  alt={editor.name}
                  src={filesRoot + '/users/profileImages/' + editor.editorId + '/profileImage.png'}
                  sx={{ width: 24, height: 24 }}
                />
              </Tooltip>
            </Typography>
          ))}
        </Grid>
        <Grid item display="flex" direction="column" gap="1vh">
          {renderToDoEvents}
        </Grid>
      </Grid>
    </Drawer>
  );
}
