import { Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ConfettiExplosion from 'react-confetti-explosion';

export const SwipeCardHeader = ({ productID, centerText, qualityStation, isExploding }) => {
    const theme = useTheme();

    const colors = [
        '#FFC700',
        '#FF0000',
        '#2E3191',
        '#41BBC7',
        '#00A651',
        '#FF7F00',
        '#A349A4',
        '#C3C3C3',
        '#0082CA',
    ];

    return (
        <Grid item xs={12}>
            <Paper style={{
                width: '100%',
                padding: '2px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '15px 15px 5px 5px',
                boxShadow: '0px 6.63px 13.25px #1018280d',
            }}>
                <Grid item xs={4} style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    padding: '0px 4px',
                }}>
                    <Typography variant="subtitle2" color="text.secondary" style={{ textAlign: 'left' }}>
                        {productID}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                    <Typography variant="subtitle2" color="text.secondary" style={{ textAlign: 'center' }}>
                        {centerText}
                    </Typography>
                    {isExploding && (
                        <ConfettiExplosion
                            particleCount={10}
                            particleSize={10}
                            duration={1500}
                            colors={colors}
                            force={0.8}
                        />
                    )}
                </Grid>
                <Grid item xs={4} style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    padding: '0px 4px',
                }}>
                    <Typography variant="subtitle2" color="text.secondary" style={{ textAlign: 'right' }}>
                        {qualityStation}
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default SwipeCardHeader;